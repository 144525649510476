import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { fetchLeads } from '../../../operations/products';
import CalendarFilter from "../Common/calendarFilter"
import SearchBar from "../Common/searchbar"
import PageSpinner from "../Common/Pagespinner"
import downloadCsv from 'download-csv';
import downloadIcon from '../../../assets/images/adminPanel/download.svg';


const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    minHeight: "75%",
  },
});


const refreshButton=()=>{

  return(
    <button class="btn btn-1 btn-sep icon-info">Refresh</button>
  )
}

export default function LeadsTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rows, setRows] = React.useState([]);
  const [Leadsbackup, setLeadsbackup] = React.useState([]);
  const [leadscalendardate, setleadscalendardate] = React.useState(new Date());
  ///calender dates
  const [leadscalendarToggle, setleadscalendarToggle] = React.useState(false);
  const [loading,setLoading] = React.useState(false);


  useEffect(() => {
    setleadscalendarToggle(false);
    // console.log('use effect working');
    filterleadsdata(props.leadsBackup, 'date');
  }, [leadscalendardate]);

 

  const filterleadsdates = (type) => {
    setleadscalendarToggle(false);
    filterleadsdata( type);
  };

 
  const leadschangeDate = (e) => {
    // console.log('selected leadsdate =', e);
    setleadscalendardate(e);
  };


  const filterleadsdata = async ( type) => {

    let startdate = new Date();
    let enddate = new Date();


    if (type === 'date') {
      startdate = leadscalendardate;
       enddate = leadscalendardate;
    }

    if (type === 'today') {
      startdate = new Date();
       enddate = new Date();
    }

    if (type === 'week') {
       startdate = moment().subtract(7, 'days'); // 04/05/2021
       enddate = new Date();
    }

    if (type === 'month') {
       startdate = moment().subtract(1, 'months').startOf('month');
       enddate = moment().subtract(1, 'months').endOf('month');
    } 
    if (type === 'thismonth') {
      startdate = moment().startOf('month');
      enddate = moment().endOf('month');
   }

    if (type === '30days') {
       startdate = moment().subtract(30, 'days').calendar();
       enddate = moment(new Date()); 
    }
    if (type === 'quarter') {
       startdate = moment(new Date())
        .subtract(4, 'months')
        .startOf('month');
       enddate = moment(new Date()).subtract(1, 'months').endOf('month');

    }
    if (type === 'year') {
      const startdate = moment(new Date()).subtract(1, 'year').startOf('year');
      const enddate = moment(new Date()).subtract(1, 'year').endOf('year');
      }

      const start = moment(startdate).startOf('day')._d; // set to 12:00 am today
      const end = moment(enddate).endOf('day')._d;

      
    const filteredleads =
    Leadsbackup &&
    Leadsbackup.filter((user) => {
          const [Name, id, mobile, Email, creationDate] = user;
          const updateddate = new Date(moment(creationDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'));
       
          if(updateddate) return updateddate >= start && updateddate <= end;
        });
      setRows(filteredleads);
      setPage(0);
  };

  const fetchLead = async () => {
    setLoading(true);
    const arr = await fetchLeads();
    const result = await arr.map((detail) => {
      const Name = detail && detail.name ? detail.name : '';
      const id = detail && detail.id ? detail.id : '';
      const mobile = detail && detail.mobile ? detail.mobile : 0;
      const creationDate =
        detail && detail.createdDate ? detail.createdDate : 'not captured';
      const Email = detail && detail.email ? detail.email : '';

      const Country = detail && detail.country ? detail.country : 'India';
      return [Name, id, mobile, Email,Country, creationDate];
    });
    setRows(result);
    setLeadsbackup(result);
    setLoading(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchLead();
    
  }, []);

  useEffect(() => {
    setPage(0);
  }, [rows]);

const [reset,setReset] = React.useState(true);

  useEffect(()=>{
    setRows(Leadsbackup);
  },[reset])

const resetData = ()=>{
  setReset(!reset);
}

const closeCalendarfilter= ()=>{
  setleadscalendarToggle(false);
}


const searchLeads = async (text)=>{

  const searchedLeads = await Leadsbackup.filter((lead)=> {
    const [Name, id, mobile, Email] = lead;

    const namesearch = Name.toLowerCase().search(text.toLowerCase()) > -1;
    const phonesearch = mobile.toLowerCase().search(text.toLowerCase()) > -1;
    const emailsearch = Email.toLowerCase().search(text.toLowerCase()) > -1;

    if(namesearch||phonesearch||emailsearch) return true;
     
  });

  setRows(searchedLeads)
}
  
const downloadUserData = async () => {
  const date = moment().format('DD-MM-YYYY');
  const exportFileName = `Leads Data ${date}.csv`;
  // downloadCsv(data, columns, exportFileName);
  const tabledata = rows.map((Lead) => {
    
      
      const [Name, id, mobile, Email, creationDate] = Lead;
    const obj = {
      Name, id, mobile, Email, creationDate
    };
    return obj;
  });
  downloadCsv(
    tabledata,
    {
      Name: 'Name',
      phone: 'Phone',
      email: 'Email',
      creationDate : 'Creation date'
    },
    exportFileName
  );
};

  return (
    <>
      
      <div>
        
        <div className="tab">

        <Grid 
        container 
        direction="row" 
        style={{height:"7.5%"}}
        >
        <Grid
         item container         
          sm={3} md={3}          
          spacing={2}      
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item className="containerHeading">
            Leads
          </Grid>
        </Grid>
        <Grid
         item container
         spacing={2}
         sm={9} md={9}  
          direction="row"
          justify="flex-end"
          alignItems="center">
        <Grid item>
          <SearchBar search={searchLeads}  />
        </Grid>
        <Grid item>
          <Button
            className="btn1"
            startIcon={<RefreshIcon style={{ color: 'white' }} />}
            onClick={resetData}>
            REFRESH
          </Button>
              </Grid>
              <Grid item>
                <Button className="btn1 " 
                startIcon={  <img
                  src={downloadIcon}
                  alt="download"
                  style={{ marginRight: '3px' }}
                />}
                onClick={downloadUserData}>
                 DOWNLOAD
                </Button>
              </Grid>
      </Grid>
      </Grid>
          <Paper className={classes.root}>
            {leadscalendarToggle && (
              <CalendarFilter
                filterdates={filterleadsdates}
                changeDate={leadschangeDate}
                close={closeCalendarfilter}
              />
            )}
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={classes.fixedhead}>
                  <TableRow>
                    <TableCell style={{ minWidth: '170' }}>Name</TableCell>
                    <TableCell style={{ minWidth: '170' }}>Mobile.no</TableCell>
                    <TableCell style={{ minWidth: '170' }}>Email</TableCell>
                    <TableCell style={{ minWidth: '170' }}>Country</TableCell>
                    <TableCell style={{ minWidth: '170' }}>
                      <span>Updated time</span> &nbsp;
                      <span>
                        <TodayIcon
                          onClick={() => {
                            setleadscalendarToggle(!leadscalendarToggle);
                          }}></TodayIcon>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
                                  <TableRow style ={{ width:"100%",left:"50vh",height: "50vh"}}>                
                                    <PageSpinner />                                                                                
                                        </TableRow>
                                               )}

                  {rows ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((Lead) => {
                        const [Name, id, mobile, Email,Country="India", creationDate] = Lead;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key="details">
                            <TableCell key="name"> {Name} </TableCell>
                            <TableCell key="phone"> {mobile} </TableCell>
                            <TableCell key="email"> {Email} </TableCell>
                            <TableCell key="country"> {Country} </TableCell>
                            <TableCell key="address">
                              {' '}
                              {creationDate}{' '}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableCell key="address"> No Data Found </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
           
          </Paper>
        </div>

  
      </div>
    <Paper
         style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
     >
                <Grid container
                    style={{ height:"100%"}}
          direction="row"
          justify="flex-end"
          alignItems="flex-start" >
             <Grid item alignItems="flex-start">
             <TablePagination className="table-fotter"
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
             </Grid>
            
         </Grid>
         
             
     </Paper>
        
    </>
  );
}

