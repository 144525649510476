import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import SubscriptionFAQ from './subscriptionFaq';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  topSecBG:{
    padding: '100px 0',
    background: 'linear-gradient(90deg, #FED58A 0%, #FCAB52 60%, #FCAB52 100%)',
    color: '#fff',
    textAlign: 'center',

  },
  heading:{
    fontSize: 40,
    lineHeight: '56px',
    fontWeight: 'bold',
  },
  subHeading:{
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
  },
  tabs:{
    padding: '100px 0 0',
  }
}));

// const StyledTabs = withStyles({
//   indicator:{
//     backgroundColor: 'transparent'
//   }
// })(Tabs);

// const StyledTab = withStyles({
//   root:{
//     border: '1px solid #D7D7D7',
//     borderRadius: 4,
//     opacity: 0.9,
//     marginRight: 25,
//     textTransform: 'none',
//     padding: '22px 35px',
//     fontSize: 20
//   },
//   selected:{
//     background: '#FCAB52',
//     border: '1px solid #FCAB52',
//     color: '#fff'
//   }
// })(Tab);

const FAQTabs = () => {
  const classes = useStyles();

  const [selectedTab, ] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.topSecBG}>
        <Grid item xs={12} sm={12}>
          <Typography
            variant={'h2'}
            className={classes.heading}>
            Frequently Asked Questions
          </Typography>
          <Typography
            variant={'h2'}
            className={classes.subHeading}>
            How can we help you? Click one of the FAQ sections below for related questions.
          </Typography>
        </Grid>
      </Grid>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {/*<StyledTabs value={selectedTab} onChange={handleChange} className={classes.tabs} aria-label="Faq's">*/}
            {/*  <StyledTab label="Subscriptions"  />*/}
            {/*  <StyledTab label="Single Kits"  />*/}
            {/*  <StyledTab label="Snacks"  />*/}
            {/*  <StyledTab label="Orders & Account"  />*/}
            {/*  <StyledTab label="Shipping & Deliveries"  />*/}
            {/*</StyledTabs>*/}
            {selectedTab === 0 && <SubscriptionFAQ/>}
            {selectedTab === 1 && <SubscriptionFAQ/>}
            {selectedTab === 2 && <SubscriptionFAQ/>}
            {selectedTab === 3 && <SubscriptionFAQ/>}
            {selectedTab === 4 && <SubscriptionFAQ/>}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FAQTabs;
