import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '40px 0 120px',
    color: '#535B65',
  },
  sectionHeader: {
    opacity: 0.9,
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '36px',
    marginTop: 56,
  },
  sectionPara: {
    opacity: 0.8,
    fontSize: 20,
    lineHeight: '30px',
  },
  hyperlink: {
    color: '#FA4A39',
    fontWeight: 500,
  },
}));

const ReturnPolicyDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={11}>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Due to the perishable nature of most of our products, we are
              unable to accept returns under any circumstances.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              All claims for damaged, spoiled, or missing shipments must be made
              by the fifteenth day of the month following your scheduled
              delivery. Claims may be made by email to{' '}
              <a
                href="mailto:hello@Orangefigs.com"
                className={classes.hyperlink}>
                hello@Orangefigs.com
              </a>
              . For claims made by the fifteenth day of the month following your
              scheduled delivery, we will replace your damaged, spoiled or
              missing shipment with a shipment in a future month. We are unable
              to accommodate claims for damaged, spoiled or missing shipments
              received after the fifteenth day of the month following your
              scheduled delivery.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You may cancel your Subscription at any time by sending an email
              to hello@OrangeFigs.com and providing your phone number and the
              best times to call. Or you can unsubscribe from Orangefigs app.
              OrangeFigs representative will call you back to confirm your
              cancellation request. Cancellation requests received after the
              10th day of the month shall apply to the following month. Gift
              subscriptions are not eligible for cancellation.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              SHIPPING
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Products will be shipped in accordance with the shipping method
              you selected when placing the order. You acknowledge that any
              delivery dates we may provide are non-binding estimates only and
              that you have no claim against us for delays or early deliveries.
              We reserve the right to make deliveries in installments. We will
              send you an email when your order has shipped and you may review
              your order and shipping information. Currently, we ship in India
              only.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              If you order a Subscription, your product will be shipped out
              around the 15th of every month to the address you submit when
              placing your order. We reserve the right to charge additional
              shipping fees if you request that any product be re-shipped,
              whether because you originally submitted an incorrect address when
              you placed your order with us or otherwise. Accordingly, please be
              sure and review the shipping information you submit very closely
              to ensure it is accurate.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Each kit is carefully packaged to stay fresh; however, like any
              other non-perishable food item; the packages have a shelf life and
              it is your responsibility to use the products in a timely manner.
              Furthermore, you should inspect your package to ensure the
              contents arrive closed and clean. If you are not home when a
              delivery arrives, the delivery person may leave the package for
              you at your door.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              We are not responsible for:
            </Typography>

            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Items delivered to incorrect addresses supplied by the sender or
              recipient.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Delivery issues arising from the recipient not being present at
              the time of delivery at the address supplied by the sender or
              recipient.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Decreased product quality due to an incorrect delivery address
              supplied by the sender or recipient, or a re-route requested by
              the sender or recipient.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Product quality problems caused by improper handling by the
              recipient including product quality problems resulting from
              perishable items that are left unattended or unopened by the
              recipient.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Typography
        variant={'body1'}
        paragraph={true}
        className={classes.sectionHeader}></Typography>

      <Typography
        variant={'body1'}
        paragraph={true}
        className={classes.sectionPara}></Typography>
    </div>
  );
};

export default ReturnPolicyDetails;
