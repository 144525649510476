import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './colors';
import { typography } from './typography';

// const defaultTheme = createMuiTheme();

/*
  Font specs
 */

export const buttonFont = createMuiTheme({
  typography: {
    fontFamily: ['HappySchool'].join(','),
  },
  palette: {
    ...colors,
  },
});

const muiTheme = createMuiTheme({
  palette: {
    ...colors,
  },
  typography: {
    ...typography,
  },
  // Component style overrides

  overrides: {
  },
});

export default muiTheme;
