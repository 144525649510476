import React, { useEffect, useState } from 'react';
import { fetchReadyToEatSnacksDetailsBySlug } from '../../operations/readyToEatSnacks/index';
import Layout from '../../components/Layout';
import ReadyToEatDetailsHero from './hero';
import ContentInformation from './contentInfo';
import CommonCta from '../../components/Cta';
import ContactUs from '../../components/ContactUs';
import { useParams } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';

const ReadyToEatDetailsLanding = () => {
  const { slug } = useParams();

  const [snack, setSnack] = useState({});

  const getReadyToEatSnacksDetailsBySlug = async (slug) => {
    const result = await fetchReadyToEatSnacksDetailsBySlug(slug);
    setSnack(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getReadyToEatSnacksDetailsBySlug(slug);
    }
    fetchData();
  }, [slug]);


  return (
    <Layout navbar={false} whiteBG={true}>
      <ReadyToEatDetailsHero snack={snack} />
      <ContentInformation snack={snack} />

      <CommonCta
        title={'Bake often?'}
        btnText={'Subscribe & save'}
        onClick={Paths.Subscribe}
      />
      <ContactUs />
    </Layout>
  );
};

export default ReadyToEatDetailsLanding;
