import React, { useEffect } from 'react';
import UsersTable from './Users/users';
import LeadsTable from './Leads/leads';
import AddNewProduct from "./Products/AddNewProduct";
import EditNewProduct from "./Products/EditProduct";
import Products from "./Products/products";
import PurchasedUsersTable from "./Customers/purachasedUsers";
import PromocodesTable from "./Promocodes/promocodes"
import './index.css';
import { useHistory } from "react-router-dom";
import logoutICon from '../../assets/images/adminPanel/logout.svg';
import Snacks from "./Snacks/Snacks"

import EditNewSnack from "./Snacks/EditSnack";
import AddNewSnack from "./Snacks/AddNewSnack"
import { addNewSnack, editSnack } from '../../operations/products';

const AdminPanel = (props) => {

  const [Index, setIndex] = React.useState();
  const [product, setProduct] = React.useState();
  const [snack, setSnack] = React.useState();
  useEffect(() => {
    setIndex(0)
  }, []);

  const history = useHistory();



  const Logout=()=>{
    return history.push('/admin');
  }

  const addProuct = () => {
    setIndex("AddProduct");
  }

  const editProduct = async (product) => {
    await setProduct(product);
    setIndex("EditProduct");
  }

  const closeAddProducts = () => {
    setIndex(4);
  }
  const AddSnack = () => {
    setIndex("AddNewSnack");
  }


  const EditSnack = async (product) => {
 
    await setSnack(product);
    setIndex("EditNewSnack");
  }

  const closeAddSnacks = () => {
    setIndex(5);
  }

  const Navbar= ["Leads","Users","Customers","Promocodes","Products","Snacks"]

  return (
    <div className="adminPanel">
      <div className="topBar">
        <img
          className="logo"
          src={require('../../assets/images/adminPanel/logo.png')}
          alt="Easy To Do"
        />
      </div>
      <div className="sideBar">
        {
          Navbar.map((item,index)=>{

            return(

              <div className={Index==index?"Sidebar_child ":"Sidebar_child Leads sidebar-active"} onClick={()=>{setIndex(index)}}>
              {item}
              </div>
            )
          })
        }     
        {/* <span className="Sidebar_child logout sidebar-active" onClick={Logout}>
         Log out <ExitToAppIcon/>
        </span> */}
        <div className= "logoutBox">
        <span className="logout Sidebar_child sidebar-active" onClick={Logout} style = {{ top:"25%"}}>
          <img src={logoutICon}/> Log out
        </span> 
        </div>
      </div>

      {
        ///leadstable
        Index ===0 && (        
             <LeadsTable />        
        )
      }
      {    ///Users Table
        Index ===1 && (              
              <UsersTable   />            
        )
      }
      {Index ===2 && (       
           <PurchasedUsersTable/>      
      )}

      {Index ===3 && (       
           <PromocodesTable/>      
      )} 
      {Index ===4 && (       
        <Products editProduct={ editProduct} addProuct={ addProuct}/>
      )}
      {Index ===5 && (       
        <Snacks editProduct={ EditSnack } addProuct={ AddSnack }/>
      )}
       {
        Index ==="AddProduct" && (       
        <AddNewProduct close={closeAddProducts} />           
     )
      }
      {
        Index ==="EditProduct" && (       
        <EditNewProduct product={product} close={closeAddProducts}  />             
     )
      }

      {
        Index ==="AddNewSnack" && (       
        <AddNewSnack close={closeAddSnacks} />           
     )
      }
      {
        Index ==="EditNewSnack" && (       
        <EditNewSnack product={snack} close={closeAddSnacks}  />             
     )
      } 
    </div>
  );
};

export default AdminPanel;
