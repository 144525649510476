import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useEffect } from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import PromocodeToggle from "./promocodesToggle"
import Grid from '@material-ui/core/Grid';
import AddPromocode from "./addPromocode"
import { PromocodeSuccessMesage, PromocodeupdateSuccessMesage } from '../../../operations/utils';
import SearchBar from "../Common/searchbar"
import EditPromocode from "./editPromocode"
import { fetchPromocodes, updatePromocode, addPromocode, editPromocode } from '../../../operations/products';
import PromocodeStatusFilter from "./promocodeStatusFilter"
import PromocodeTypeFilter from "./promocodeTypeFilter"
import downloadCsv from 'download-csv';
import downloadIcon from '../../../assets/images/adminPanel/download.svg';
import moment from 'moment'
const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    minHeight: "75%",
  }
});

export default function PromocodesTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  // const [UserssortToggle, setUserssortToggle] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([{}]);
  const [Promocodesbackup, setPromocodesbackup] = React.useState([{}]);//setLeadsbackup



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 




  const fetchdata = async () => {
    const result = await fetchPromocodes();
    setRows(result);
    setPromocodesbackup(result);
  }

  useEffect(() => {
    fetchdata()
  }, []);




  const nodata = () => {

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key="details">
        <TableCell >
          no data Found
    </TableCell>
      </TableRow>

    )
  }

  const [reset, setReset] = React.useState(true);

  useEffect(() => {
    setRows(Promocodesbackup);
  }, [reset])

  const resetData = () => {
    setReset(!reset);
  }

  const [cleartypefilter,setCleartypefilter] = React.useState(true);
  const [ clearstatusfilter,setClearstatusfilter] = React.useState(true);;

  const statusFilterChange = (filterkeys) => {

    setCleartypefilter(!cleartypefilter);

    let fileteredPromocodes = Promocodesbackup;

    if (filterkeys.indexOf("YES") > -1 && filterkeys.indexOf("NO") === -1) {
      fileteredPromocodes = Promocodesbackup.filter((promocode) => {
        const { active } = promocode;
        return active === true
      });
    }
    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") === -1) {
      fileteredPromocodes = Promocodesbackup.filter((promocode) => {
        const { active } = promocode;
        return active === false;
      });
    }

    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") > -1) {
      fileteredPromocodes = Promocodesbackup;
    }

    setRows(fileteredPromocodes);

  }

  const TypeFilterChange = (filterkeys) => {

    setClearstatusfilter(!clearstatusfilter);

    let fileteredPromocodes = Promocodesbackup;

    if (filterkeys.indexOf("FREE") > -1 && filterkeys.indexOf("AMOUNT") === -1) {
      fileteredPromocodes = Promocodesbackup.filter((promocode) => {
        const { type } = promocode;
        return type === "free"
      });
    }
    if (filterkeys.indexOf("AMOUNT") > -1 && filterkeys.indexOf("FREE") === -1) {
      fileteredPromocodes = Promocodesbackup.filter((promocode) => {
        const { type } = promocode;
        return type !== "free"
      });
    }

    if (filterkeys.indexOf("AMOUNT") > -1 && filterkeys.indexOf("FREE") > -1) {
      fileteredPromocodes = Promocodesbackup;
    }

    setRows(fileteredPromocodes);

  }



  const searchPromos = (text) => {
    const searchedpromocodes = Promocodesbackup.filter((promocode) => {
      let { code } = promocode;
      return code.toLowerCase().search(text.toLowerCase()) > -1;
    });
    setRows(searchedpromocodes);
  }


  const handleToggle = async (id, assign) => {

    const result = toggleswitch(id, assign)
    result.then(refresh)
  }

  const addPromoCode = async (newPromocode) => {

    await addPromocode(newPromocode).then(
      refresh
      ).then(PromocodeSuccessMesage);

  }

  const toggleswitch = async (id, assign) => {
    await updatePromocode(id, !assign);
  }

  const refresh = async () => {
    const result = await fetchPromocodes();
    setRows(result);
    setPromocodesbackup(result);
  }

  const handleEdit = async (newPromocode) => {
    await editPromocode(newPromocode).then(refresh).then(PromocodeupdateSuccessMesage);
  }

  const downloadUserData = async () => {
    const date = moment().format('DD-MM-YYYY');
    const exportFileName = `Promocodes Data ${date}.csv`;
    // downloadCsv(data, columns, exportFileName);
    const tabledata = rows.map((promocode) => {
      
      const { active, amount, code, enddate, startdate, type, id } = promocode;
                        
      const Enddate = enddate.toDate()
        .toString()
        .replace('GMT+0530 (India Standard Time)', '');
        const Startdate = startdate.toDate()
        .toString()
        .replace('GMT+0530 (India Standard Time)', '');
      const obj = {
        active, amount, code, Enddate, Startdate, type,
      };
      return obj;
    });
    downloadCsv(
      tabledata,
      {
        code: 'code',
        active: 'Status',
        amount: 'Amount',
        type: 'Type',
        Enddate: 'End date',
        Startdate : 'Start date',
      },
      exportFileName
    );
  };

  return (
<>
    <div >

      <div className="tab">
      <Grid 
        container 
        direction="row" 
        style={{height:"7.5%"}}
        >
        <Grid
          item container
          style={{width:"30%"}}           
          spacing={2}      
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item className="containerHeading">
          Promocodes
          </Grid>
        </Grid>
        <Grid
         item container
         spacing={2}
         style={{width:"71%"}}  
          direction="row"
          justify="flex-end"
          alignItems="center">


            <Grid item>            
            <SearchBar search={searchPromos} style={{ margin: "10px", maxWidth: "90%" }} />        
            </Grid> 
            <Grid item>
            <AddPromocode handlePromoCode={addPromoCode} style={{ marginLeft: "1em",marginTop:".1em" }}/>                                             
            </Grid> 
            
            <Grid item >                      
               <Button className="btn1" 
               onClick={resetData}
               startIcon={ <RefreshIcon style={{ color: 'white'}} />}
               >
               REFRESH</Button>                           
            </Grid>
            <Grid item>
                <Button className="btn1 " 
                startIcon={  <img
                  src={downloadIcon}
                  alt="download"
                  style={{ marginRight: '3px' }}
                />}
                onClick={downloadUserData}>
                 DOWNLOAD
                </Button>
              </Grid>
          
     
      </Grid>
      </Grid>

        <div>

          <Paper className={classes.root} >
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Code
                </TableCell>
                <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                  Product Type
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      <PromocodeTypeFilter statusFilterChange={TypeFilterChange} heading={"Type"} cleartypefilter={cleartypefilter} />
                    </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Amount
                </TableCell>

                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Start Date
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      End Date
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      Edit
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      <PromocodeStatusFilter statusFilterChange={statusFilterChange} heading={"Active"} clearstatusfilter={clearstatusfilter}/>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows
                    && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((promocode) => {

                        const { active, amount, code, enddate, startdate, type, id, productType } = promocode;
                          const key = id;
                        
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key="details" >
                            <TableCell key={"name"+key} >
                              { code&&code.toLowerCase() }
                            </TableCell>
                            <TableCell key={"productType"+key} >
                              { productType ? productType : "-" }
                            </TableCell>
                            <TableCell key={"address"+key} style={{ maxWidth: "300px" }}> { type&&(type.charAt(0).toUpperCase() + type.toString().slice(1))}
                            </TableCell>
                            <TableCell key={"phone"+key}>
                              {amount}
                            </TableCell>
                            <TableCell key={"startdate"+key} > {startdate ? startdate.toDate()
                              .toString()
                              .replace('GMT+0530 (India Standard Time)', '')
                              .replace('00:00:00', '')                           
                              : '-'} </TableCell>
                            <TableCell key={"enddate"+key} > {enddate ? enddate.toDate()
                              .toString()
                              .replace('GMT+0530 (India Standard Time)', '')
                              .replace('00:00:00', '')   
                              : '-'} </TableCell>
                            <TableCell key={"edit"+key} ><EditPromocode handlePromoCode={handleEdit} promocode={promocode} />  </TableCell>
                            <TableCell key={"active"+key} ><PromocodeToggle check={active} handleToggle={() => { handleToggle(id, active) }} /> </TableCell>
                          </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
           
          </Paper>
        </div>
      </div>
    </div>
    <Paper
         style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
     >
                <Grid container
                    style={{ height:"100%"}}
          direction="row"
          justify="flex-end"
          alignItems="center">
             <Grid item alignItems="center">
             <TablePagination className="table-fotter"
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
             </Grid>
            
         </Grid>
         
             
     </Paper>
      
    </>
  );
}
