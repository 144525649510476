import React,{useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import "../Customers/tracking.css"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Roboto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});


const  EditButton  = withStyles(styles)((props)=> {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { children, classes, onClose, trackingID,...other } = props;
 


    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <div style={{ paddingTop: '12px',paddingLeft:"12px", marginLeft: '1em' ,marginTop:"-5px"}}>
      
       
      <MoreVertIcon  onClick={handleClick}/>
       
        
      
        <Menu
  id="simple-menu"
  anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
>
  <MenuItem onClick={() => {
                  handleClickOpen();
                  props.handleEdit();
          handleMenuClose();
        }}>EDIT PRODUCT</MenuItem>

</Menu>
    
      

      </div>
  );
});


export default  EditButton;