import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Logo from '../../assets/images/of_logo.svg';
import CartIcon from '../../assets/images/cart-inactive .svg';
import DownIcon from '../../assets/images/down.png';
import UpIcon from '../../assets/images/up.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import './navbar.css';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';
import {  useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {},
  whiteBG: {
    backgroundColor: '#fff',
    flexGrow: 1,
    padding: '50px 0',
  },
  secondaryBG: {
    backgroundColor: '#FAF7F3',
    flexGrow: 1,
    padding: '50px 0',
  },
  orangeBG: {
    background:
      'linear-gradient(90deg, #FED58A 0%, #FCAB52 60%, #FCAB52 100%)!important',
    flexGrow: 1,
    padding: '50px 0',
  },
}));

const Navbar = ({
  toggleCart,
  openCart,
  whiteBG = false,
  orangeBG = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  let { products = [], snacks = [] } = useSelector(
    (state) => state.cart
  );

  let cartItemsQty = products.length + snacks.length;

  

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      className={
        whiteBG
          ? classes.whiteBG
          : orangeBG
          ? classes.orangeBG
          : classes.secondaryBG
      }>
      <Container fixed>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={6}>
            <img
              src={Logo}
              alt="Orange Figs"
              onClick={() => history.push(Paths.Home)}
              className="pointer"
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <Grid item xs={12} sm={3}>
              <Typography variant={'h2'}>
                <Link
                  to={Paths.AboutUs}
                  variant={'h2'}
                  className="navbar-links">
                  About us
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant={'h2'}>
                <Link
                  to={Paths.Subscribe}
                  variant={'h2'}
                  className="navbar-links">
                  Subscribe
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant={'h2'}>
                <Link
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  variant={'h2'}
                  to="#"
                  className={
                    open ? 'navbar-links-active navbar-links' : 'navbar-links'
                  }>
                  Shop{' '}
                  {open ? (
                    <img src={UpIcon} alt="Up" style={{ width: '16px' }} />
                  ) : (
                    <img src={DownIcon} alt="Down" style={{ width: 16 }} />
                  )}
                </Link>
              </Typography>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className="navbar-dd-popper">
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Grid
                        container
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        className="navbar-dd-menu">
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          onClick={() => {
                            history.push(Paths.ProductListing);
                          }}
                          className="navbar-menu-item">
                          <Typography variant={'body1'}>
                            <Link variant={'body1'}>
                              <span className="onHover" />
                              Single kits
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          onClick={() => {
                            history.push(Paths.ReadyToEat);
                          }}
                          className="navbar-menu-item navbar-separator">
                          <Typography variant={'body1'}>
                            <Link variant={'body1'}>
                              Ready-to-eat snacks
                              <span className="onHover"></span>
                            </Link>
                          </Typography>
                        </Grid>
                        {/*<Grid*/}
                        {/*  item*/}
                        {/*  xs={12}*/}
                        {/*  sm={4}*/}
                        {/*  onClick={handleClose}*/}
                        {/*  className="navbar-menu-item">*/}
                        {/*  <Typography variant={'body1'}>*/}
                        {/*    <Link to={'#'} variant={'body1'}>*/}
                        {/*      <span className="onHover"></span>Specials/ Bundles*/}
                        {/*    </Link>*/}
                        {/*  </Typography>*/}
                        {/*</Grid>*/}
                      </Grid>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{ display: 'flex', alignItems: 'center' }}>
              <Badge badgeContent={cartItemsQty} color="primary">
                <img
                  src={CartIcon}
                  className={'pointer'}
                  alt="Cart"
                  onClick={(event) => toggleCart(!openCart)}
                />
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Navbar);
