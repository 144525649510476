import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
// import BackIcon from '../../assets/images/ico-back-orange.png';
// import Link from '@material-ui/core/Link';
import { updateUserData } from '../../operations/onboarding';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
  },
  icon: {
    height: 22,
    width: 'auto',
    marginRight: 10,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Roboto',
  },
  headingLeft: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 15,
  },
  headingRight: {
    color: '#535B65',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    float: 'right',
    marginBottom: 15,
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '43px 0',
  },
}));

const ContactDetailsForm = ({ userData, handleNext }) => {
  const classes = useStyles();

  const [parentName, setParentName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstKidName, setFirstKidName] = useState('');
  const [firstKidDOB, setFirstKidDOB] = useState('');

  const validate = () => {
    return (
      !parentName.length ||
      !email.length ||
      !phone.length ||
      !firstKidName.length ||
      !firstKidDOB.length
    );
  };

  const checkIfUpdated = () => {
    return (
      parentName !== userData.name ||
      email !== userData.email ||
      phone !== userData.phone ||
      firstKidName !== userData.firstKidName ||
      userData.firstKidDOB !== firstKidDOB
    );
  };

  useEffect(() => {
    if (userData) {
      setParentName(userData.name || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");
      setFirstKidName(userData.firstKidName || "");
      setFirstKidDOB(userData.firstKidDOB || "");
    }
  }, [userData]);

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingLeft}>
                Your contact details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingRight}>
                All fields are required
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Parent name"
                value={parentName}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setParentName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setEmail(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                value={phone}
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                onChange={(event) => setPhone(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={12}>
              <Typography variant={'h2'} className={classes.headingLeft}>
                Details | Child
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Kid’s name"
                variant="outlined"
                style={{ margin: '12px 0' }}
                value={firstKidName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setFirstKidName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Kid’s d.o.b."
                value={firstKidDOB}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setFirstKidDOB(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={buttonFont}>
              <Button
                fullWidth
                theme
                className={classes.btnSubmit}
                onClick={async () => {
                  if (!validate()) {
                    if (checkIfUpdated()) {
                      await updateUserData(userData.id, {
                        email,
                        phone,
                        name: parentName,
                        firstKidDOB,
                        firstKidName,
                      });
                    }
                    handleNext();
                  }
                }}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                Next
              </Button>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactDetailsForm;
