import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Paths } from '../../routes/routePaths';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
    backgroundColor: '#fff',
  },
}));

const footerLinks = [
  {
    id: 1,
    name: 'FAQ',
    url: Paths.CompleteFAQ,
  },
  {
    id: 2,
    name: 'Privacy policy',
    url: Paths.PrivacyPolicy,
  },
  {
    id: 3,
    name: 'Return policy',
    url: Paths.ReturnPolicy,
  },
  {
    id: 4,
    name: 'Terms of use',
    url: Paths.TermsOfUse,
  },
];

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography
              variant={'body1'}
              style={{
                opacity: 0.7,
                color: '#535B65',
                fontSize: 21,
                padding: '10px 0',
              }}>
              © orangefig {new Date().getFullYear()}
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={7}>
            {footerLinks.map((link) => (
              <Grid item xs={12} sm={3} key={link.id}>
                <Typography variant={'h2'} className="footer-link">
                  <Link href={link.url} onClick={() => null} color={'inherit'}>
                    {link.name}
                  </Link>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
