import React,{useState} from 'react';
import {  WrongemailMessage, WrongpasswordMessage } from '../../../operations/utils';
import "./index.css"
import { useHistory } from "react-router-dom";
import { fetchLogin } from '../../../operations/products';

const AdminLogin = () => {
    
    const [email, setEmail] = useState('');
    const [password,setpassword] = useState('');
    const history = useHistory();


    const authsucces = (check) => {
       
        if (check) return history.push({
            pathname: '/adminHome',
            isauthenticated: true,
          });
        else  return history.push('/admin')
    }


    const Authenticate = async (userid,password) => {
        const result = await fetchLogin();
       

    const arr = await result&&result.map((detail) => {
   
            if(detail.user_name===userid){
                if(detail.password===password){
                        //redirect to deatils page
                        authsucces(true)
                       

                        // return <Redirect to='/adminHome'  />
                }
                else WrongpasswordMessage();
            }
            else  WrongemailMessage();

   
  });       
      };

  return (
      <div  className= "adminlogin">
    <div className="sectionTwo">
    <div>
        <h4>Enter YOUR details below</h4>
        <div id="contact-form">
            <div className="row col-md-12 col-sm-12">
    
                <div className="col-md-9 col-sm-9">
                    <div className="form-input mt-25">

                        <div className="input-items default">
                            <input type="text" name="contact" placeholder="Email" onChange={(event) => setEmail(event.target.value)} value={email}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-sm-9">
                    <div className="form-input mt-25">

                        <div className="input-items default">
                            <input type="password" name="email" placeholder="Password"
                             onChange={(event) => { setpassword(event.target.value);
                               }
                            }   
                            value={password}  
                             />
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-sm-9">
                    <div className="loginbtn form-input mt-25">
                        <button onClick = {()=>{Authenticate(email,password)}}>LOGIN</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>   
  );
};

export default AdminLogin;
