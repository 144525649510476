import React from 'react'
import "../Promocodes/promocodes.css"

const ProductAvailabilityToggle = (props)=>{

    return(
        <div class="content">
  <label class="switch">
    <input type="checkbox"  checked= {props.check} onChange={props.handleToggle}/>
    <span class="slider round"></span>
  </label>
</div>
    )
}

export default ProductAvailabilityToggle;