import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import GlobalActions from '../../redux/reducer/globalReducer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snacks from './snacks';
import SnacksIcon from '../../assets/images/ico-snack-hero.svg';

const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(to bottom, #FAF7F3 0%, #FAF7F3 700px, #FFFFFF 700px, #FFFFFF 100%)',
  },
  placeHolderImg: {
    height: 328,
    width: 328,
    borderRadius: 208,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
});


const SnacksHero = ({ snacks }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.SNACKS }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
  };

  return (
    <div className={classes.root} style={{ padding: '120px 0' }}>
      <Container fixed>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.placeHolderImg}>
              <img style={{ height: '164px', width: '164px' }} src={SnacksIcon} alt="Snacks" />
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 40,
                lineHeight: '56px',
                fontFamily: 'HappySchool',
              }}>
              Ready-To-Eat Snacks
            </Typography>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '28px',
                marginBottom: 20,
              }}>
              Try our favorite healthy and delicious ready-to-eat snacks.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ margin: '80px 0' }}>
          {snacks && snacks.length ? snacks.map((item, idx) => (
            <Grid item xs={12} sm={4} key={idx}>
              <Snacks
                details={item}
                addToCartHandler={addToCart}
              />
            </Grid>
          )) : null}
        </Grid>
      </Container>
    </div>
  );
};

export default SnacksHero;
