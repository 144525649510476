/**
 * Validate email address
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  /*eslint no-useless-escape: */
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email) !== false;
}

/**
 * Validate mobile address
 * @param mobile
 * @returns {boolean}
 */
 export function validateMobile(mobile) {
    const reg = /^[0-9]{10}$/;
    return reg.test(mobile) !== false;
  }

export const PRODUCT_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  KIT: 'KIT',
  SNACKS: 'SNACKS',
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
