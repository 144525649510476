import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReturnPolicyDetails from './returnPolicy';
import Layout from '../Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  topSecBG:{
    padding: '100px 0',
    background: '#FAF7F3',
    color: '#535B65',
    textAlign: 'center',

  },
  heading:{
    fontSize: 40,
    lineHeight: '56px',
    fontWeight: 'bold',
  },
  subHeading:{
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    opacity: 0.7
  },
}));

const ReturnPolicy = () => {
  const classes = useStyles();

  return (
    <Layout >
      <Grid container spacing={3} className={classes.topSecBG}>
        <Grid item xs={12} sm={12}>
          <Typography
            variant={'h2'}
            className={classes.heading}>
            Return Policy
          </Typography>
          <Typography
            variant={'h2'}
            className={classes.subHeading}>
            RETURNS, DAMAGED GOODS AND CANCELLATIONS.
          </Typography>
        </Grid>
      </Grid>
      <ReturnPolicyDetails/>
    </Layout>
  );
};

export default ReturnPolicy;
