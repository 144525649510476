import React,{useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField/TextField';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import ruppees from "../../../assets/images/ruppees.png"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#c4c4c4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Roboto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  borderButton: {
    width: "100px",
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <Typography >Add the below details to create a promocode.</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const  AddPromocode  = withStyles(styles)((props)=> {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { children, classes, onClose, ...other } = props;
  const [promocode,setPromocode]= React.useState("");
  const [type,setType]= React.useState("");
  const [amount,setAmount]= React.useState();
  const [startdate,setStartdate]= React.useState();
  const [enddate, setEnddate] = React.useState();
  const [productType,setproductType]= React.useState();
  const [planID,setplanID]= React.useState();

  const handlePromoCode = ()=>{
  const startDate =  moment(startdate).toDate();
  const endDate =  moment(enddate).toDate();
    const newPromocode = {
        promocode:{
          active : true,
          amount : parseInt (amount),
          code : promocode.toLowerCase(),
          enddate : endDate,
          startdate : startDate,
        type: type,
        planID: planID ? planID : "",
        productType : productType,
        } 
      }
      handleClose();
      props.handlePromoCode(newPromocode);
      
  }



  const clearFields = ()=>{
    setPromocode("");
    setType("");
    setAmount();
    setStartdate();
    setEnddate();
    setplanID("");
  }

  const verifydetails = () => {
    return (
      !promocode ||
      !amount ||
      !startdate||
      !enddate||
      !type||
      !checkDateConstraint() ||
      !checkSubscription()
    );
  };
  
  const checkSubscription = () => {
    
    if (productType == "Monthly Plan"||productType == "Annual Plan") {
      if (planID&&planID.length >= 1) return true;
      else return false;
    }
   
      return true;   
  }

  const handleProductType = (value) => {
    if (!(value == "Monthly Plan" || value == "Annual Plan")) {
      setplanID("");
    } 

    setproductType(value);
  }
  

    const checkDateConstraint = ()=>{
      
      const difference =  moment(enddate).diff( moment(startdate), 'days');
  
      if(difference>=1) return true;
      else return false;
  
      }

  return (
    <div style={{paddingTop:"0px",marginLeft:"1em"}}>
        <Button className="btn1 topbtn" onClick={
          ()=>{
            handleClickOpen();
            clearFields();
        }}
        startIcon={<AddIcon style ={{color: 'white'}}/>}
        >
            ADD PROMOCODE
              </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{color:"#B52A65",marginLeft:"15px"}}>
         Add Promocode
        </DialogTitle>     
        <DialogContent dividers style={{ marginLeft:"0px"}}>
        <Grid container spacing={3} style={{ padding:"15px"}}>
        
            <Grid item xs={12} lg={12} md={12} >
            <div>PROMOCODE*</div>      
            <OutlinedInput
                            id="outlined-adornment-weight"
                            placeholder= "ADD PROMOCODE"
                            style={{ width: "100%" }}
                            value={promocode}
                            onChange={(event) => setPromocode(event.target.value)} InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                             aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
          </Grid>
         
            <Grid item xs={6} lg={6} md={6} >
                 <div >PRODUCT TYPE*</div> 
             
        <FormControl variant="outlined" className={classes.formControl} style={{ width:"100%",margin:"0px"}}>
        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={productType}
                  onChange={(e) => {
                    handleProductType(e.target.value)
                  }}
          // label=""
        >
        <MenuItem value={"Summer Camp"}>Summer Camp</MenuItem> 
            <MenuItem value={"Monthly Plan"}>Monthly Plan </MenuItem>
            <MenuItem value={"Annual Plan"}>Annual Plan</MenuItem> 
          <MenuItem value={"Product"}>Product</MenuItem>
          <MenuItem value={"Snacks"}>Snacks</MenuItem>  
        </Select>
              </FormControl>
              
            </Grid>
            
            <Grid item          
              xs={6} lg={6} md={6}>
               <div  style={productType==="Monthly Plan"||productType==="Annual Plan"?{ }:{opacity:"0.5"}}
             >PLAN ID</div> 
              <TextField          
                placeholder="PLAN ID"
                variant="outlined"
                disabled={productType==="Monthly Plan"||productType==="Annual Plan"?false:true}
                style={productType==="Monthly Plan"||productType==="Annual Plan"?{ margin: '0px',width:"100%" }:{margin: '0px',width:"100%" ,opacity:"0.5"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={planID}
                onChange={(event) => setplanID(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>

            
   
        
            <Grid item xs={6} lg={6} md={6} >
                 <div>TYPE*</div>                 
              <FormControl variant="outlined" className={classes.formControl} style={{ width:"100%",margin:"0px"}}>
        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={type}
          onChange={(e)=>{setType(e.target.value)}}
          // label=""
        >
        <MenuItem value={"amount"}>Amount</MenuItem> 
          <MenuItem value={"free"}>Free</MenuItem>  
        </Select>
              </FormControl>
                       
            </Grid>
            
            <Grid item xs={6} lg={6} md={6}>
               <div>AMOUNT</div> 
              <TextField             
                placeholder="AMOUNT"
                fullWidth
                variant="outlined"
                type="number"
                endAdornment={<InputAdornment position="end"              
            ><Typography>Rs</Typography></InputAdornment>}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={amount}
                onChange={(event) => setAmount(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
          
            
       
             
            <Grid item xs={6} lg={6} md={6}>
            <div>START DATE</div>
              <TextField             
                // label="AMOUNT"
                fullWidth
                variant="outlined"
                type="date"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={startdate}
                onChange={(e) => { setStartdate(e.target.value) }}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            
            </Grid>
            
            <Grid item xs={6} lg={6} md={6}>
            <div>END DATE</div>
            
            <TextField             
                // label="AMOUNT"
                fullWidth
                variant="outlined"
                type="date"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={enddate}
                onChange={(e) => { setEnddate(e.target.value) }}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
             
          <Grid item xs={12} lg={12} md={12}>
              <Typography
              style={{marginTop:"30px",color:"#B52A65"}}
              >*Note: Please make sure to add the plan Id from Razorpay for subscriptions</Typography>
            </Grid>        
            </Grid>  
        </DialogContent>
        
        <DialogActions>
          <Button autoFocus
           
            className={classes.borderButton}
            onClick={handleClose} >
            CANCEL
          </Button>
          <Button 
          disabled={verifydetails()}     
          autoFocus onClick={handlePromoCode}  
          className={classes.borderButton}
          style={  verifydetails()=== false? {background:"#B52A65",color:"white"}:{background:"#B52A65",color:"white",opacity:"0.5"}}>
            ADD 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
   
  );
});


export default  AddPromocode;