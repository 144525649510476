import { firebaseFunctions, firestore } from '../../firebase';

/**
 * Send Email
 */
const sendEmail = async (subject, text, toEmail, html) => {
  try {
    const sendMail = firebaseFunctions.httpsCallable('sendEmail');
    await sendMail({ subject, text, toEmail, html });
    return true;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Send Template Email
 */
const sendTemplateEmail = async (
  subject,
  toEmail,
  templateId,
  templateData
) => {
  try {
    const sendTemplateMail = firebaseFunctions.httpsCallable(
      'sendTemplateMail'
    );
    await sendTemplateMail({
      subject,
      toEmail,
      templateId,
      templateData,
    });
    return true;
  } catch (e) {
    console.log(e);
  }
};

/*
     Zipcode verification
*/

const ZipcodeVerification = async () => {
  try {
    const response = await firestore.collection('pincodes').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

/*
     New Zipcode & Email
*/

const AddNewZipCodeAndEmail = async (data) => {
  try {
    const response = await firestore.collection('new-pincodes').add(data);
    return response;
  } catch (e) {
    console.warn(e);
  }
};

export {
  sendEmail,
  ZipcodeVerification,
  AddNewZipCodeAndEmail,
  sendTemplateEmail,
};
