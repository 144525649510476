import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useEffect } from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import ProductAvailabilityToggle from "./ProductToggle"
import Grid from '@material-ui/core/Grid';
import { updateSuccessMesage, AddingSuccessMesage } from '../../../operations/utils';
import SearchBar from "../Common/searchbar"

import { fetchSnacks, updateSnackssAvailabilty, } from '../../../operations/products';
import PromocodeStatusFilter from "../Promocodes/promocodeStatusFilter"
import PromocodeTypeFilter from "../Promocodes/promocodeTypeFilter"
import downloadCsv from 'download-csv';
import downloadIcon from '../../../assets/images/adminPanel/download.svg';
import moment from 'moment'
import AddProducts from "./AddProduccts"
import EditButton from "./EditButton"
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    minHeight: "75%",
  }
});

let data = [];

export default function Snacks(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([{}]);
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 

  const fetchdata = async () => {
    data = await fetchSnacks();
    setRows(data);
  }

  useEffect(() => {
    fetchdata()
  }, []);




  const nodata = () => {

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key="details">
        <TableCell >
          no data Found
    </TableCell>
      </TableRow>

    )
  }

  const [reset, setReset] = React.useState(true);

  useEffect(() => {
    setRows(data);
  }, [reset])

  const resetData = () => {
    setReset(!reset);
  }

  const [cleartypefilter,setCleartypefilter] = React.useState(true);
  const [ clearstatusfilter,setClearstatusfilter] = React.useState(true);;

  const statusFilterChange = (filterkeys) => {

    setCleartypefilter(!cleartypefilter);

    let fileteredPromocodes = data;

    if (filterkeys.indexOf("YES") > -1 && filterkeys.indexOf("NO") === -1) {
      fileteredPromocodes = data.filter((promocode) => {
        const { active } = promocode;
        return active === true
      });
    }
    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") === -1) {
      fileteredPromocodes = data.filter((promocode) => {
        const { active } = promocode;
        return active === false;
      });
    }

    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") > -1) {
      fileteredPromocodes = data;
    }

    setRows(fileteredPromocodes);

  }

  const TypeFilterChange = (filterkeys) => {

    setClearstatusfilter(!clearstatusfilter);

    let fileteredPromocodes = data;

    if (filterkeys.indexOf("FREE") > -1 && filterkeys.indexOf("AMOUNT") === -1) {
      fileteredPromocodes = data.filter((promocode) => {
        const { type } = promocode;
        return type === "free"
      });
    }
    if (filterkeys.indexOf("AMOUNT") > -1 && filterkeys.indexOf("FREE") === -1) {
      fileteredPromocodes = data.filter((promocode) => {
        const { type } = promocode;
        return type !== "free"
      });
    }

    if (filterkeys.indexOf("AMOUNT") > -1 && filterkeys.indexOf("FREE") > -1) {
      fileteredPromocodes = data;
    }

    setRows(fileteredPromocodes);

  }



  const searchProducts = (text) => {
    const searchedproducts = data.filter((product) => {
      let { name,description } = product;
      return name.toLowerCase().search(text.toLowerCase()) > -1 ||
             description.toLowerCase().search(text.toLowerCase()) > -1
    });
    setRows(searchedproducts);
  }


  const handleToggle = async (id, availability) => {
    let assign = false;
    assign = availability === "true" && true;
    const result = toggleswitch(id, assign)
    result.then(refresh)
  }

 

  const toggleswitch = async (id, assign) => {
    await updateSnackssAvailabilty(id, !assign);
  }

  const refresh = async () => {
    data = await fetchSnacks();
    setRows(data);
  }



  const downloadUserData = async () => {
    const date = moment().format('DD-MM-YYYY');
    const exportFileName = `Snacks Data ${date}.csv`;
    // downloadCsv(data, columns, exportFileName);
    const tabledata = rows.map((product) => {
      
      const { 
        availability,
        name,
        price,       
        quantity,
       shelfLife,
        description,   } = product;
                        
     
      const obj = {
        availability,
        name,
        price,       
        quantity,
       shelfLife,
        description, 
      };
      return obj;
    });
    downloadCsv(
      tabledata,
      {      
        availability:"Availability",
        name:"Name",
        price:"Price",       
        quantity:"Quantity",
       shelfLife:"Shelf Life",
        description:"Description", 
      },
      exportFileName
    );
  };

  return (
<>
    <div >

      <div className="tab">
      <Grid 
        container 
        direction="row" 
        style={{height:"7.5%"}}
        >
        <Grid
          item container
          style={{width:"30%"}}           
          spacing={2}      
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item className="containerHeading">
          Snacks
          </Grid>
        </Grid>
        <Grid
         item container
         spacing={2}
         style={{width:"71%"}}  
          direction="row"
          justify="flex-end"
          alignItems="center">


            <Grid item>            
            <SearchBar search={searchProducts} style={{ margin: "10px", maxWidth: "90%" }} />        
            </Grid> 
            <Grid item>
            <Button className="btn1" 
               onClick={props.addProuct}
               startIcon={ <AddIcon style={{ color: 'white'}} />}
               >
               ADD SNACK</Button>
           
            </Grid>
            
            <Grid item >                      
               <Button className="btn1" 
               onClick={resetData}
               startIcon={ <RefreshIcon style={{ color: 'white'}} />}
               >
               REFRESH</Button>                           
            </Grid>
            <Grid item>
                <Button className="btn1 " 
                startIcon={  <img
                  src={downloadIcon}
                  alt="download"
                  style={{ marginRight: '3px' }}
                />}
                onClick={downloadUserData}>
                 DOWNLOAD
                </Button>
              </Grid>
          
     
      </Grid>
      </Grid>

        <div>

          <Paper className={classes.root} >
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Name
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                    Price
                    </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Shelf Life
                </TableCell>                
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      Quantity
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      Availability
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows
                    && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((product) => {
                                                   
                       const { 
                          availability,  name, 
                         price, id,  quantity,
                         shelfLife,
                          description, imageDetails,   } = product;
                          const key = id;

                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key="details" >
                            <TableCell key={"name" + key} >
                            <Grid container                                
                                direction="row"
                                justify="flex-start"
                                alignItems="center"                             
                                spacing={1}>
                                <Grid item
                                  alignItems="center"
                                  sm={2}>
                                <img
                                style={{ width:"34px",height:"34px",borderRadius:"50%"}}
                                src={imageDetails} alt="img" />
                               </Grid>
                                <Grid item sm={5}>
                                {name && (name.charAt(0).toUpperCase() + name.slice(1))}
                          
                                </Grid>
                              
                                </Grid>

                            </TableCell>                         
                            <TableCell key={"price"+key}>
                              {price}
                            </TableCell>
                            
                            {/* <TableCell key={"bakeTime" + key} > {bakeTime} </TableCell> */}
                            <TableCell key={"shelfLife" + key} > {shelfLife} </TableCell>
                            <TableCell key={"quantity"+key} >{quantity} </TableCell>
                            <TableCell key={"availability" + key} >
                              <Grid container>
                                <Grid item sm={6}>
                              <ProductAvailabilityToggle check={availability === "true" ? true : false} handleToggle={() => { handleToggle(id, availability) }} />
                                </Grid>
                                <Grid item sm={1}>
                                <EditButton handleEdit={() => {
                                props.editProduct(product)
                                }} />
                                </Grid>
                              
                                </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            
          </Paper>
        </div>
      </div>
    </div>
    <Paper
         style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
     >
                <Grid container
                    style={{ height:"100%"}}
          direction="row"
          justify="flex-end"
          alignItems="center">
             <Grid item alignItems="center">
             <TablePagination className="table-fotter"
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
             </Grid>
            
         </Grid>
         
             
     </Paper>
      
    </>
      
      );
}
