import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './carousel.css';

export default ({image}) => (
  <Carousel showStatus={false} autoPlay={false} showThumbs={false} >
    <img alt="Kits" src={image} />
  </Carousel>
);
