import React, { useState, useEffect, useRef } from 'react';
import filterIcon from '../../../assets/images/adminPanel/filtericon.svg';
import "../Common/subscribefilter.css"
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';


let filterListProps = [];

const GreenCheckbox = withStyles({
    root: {
      color: "#B52A65",
      '&$checked': {
        color: "#B52A65",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

function PromocodeTypeFilter(props) {
  const ref = useRef();

  useOnClickOutside(ref, () => setdropdownVisible(false));
  const filterlist = ['FREE', 'AMOUNT'];

  const [dropdownVisible, setdropdownVisible] = React.useState(false);
  const [filterKeys, setFilterkeys] = React.useState([]);

  const filterCheckboxChange = (e) => {
    let checkfilter = e.target.value;
    if (e.target.checked) {
      filterListProps.push(checkfilter);
      setFilterkeys(filterListProps);
    } else {
      let value = e.target.value;
      filterListProps = filterListProps.filter((obj) => obj !== value);
      setFilterkeys(filterListProps);
    }

    props.statusFilterChange(filterListProps);
  };

  useEffect(()=>{
    filterListProps = [];
  },[])

  useEffect(()=>{
    // console.log("clear types filters");
    filterListProps = [];
  },[props.cleartypefilter]);

  useEffect(()=>{
    setFilterkeys(filterListProps);
  },[dropdownVisible]);

  return (
    <div>
      <div
        className="dropdown  dropright text-primary"
        style={{ cursor: 'pointer' }}
        ref={ref}>
        <span>
          {filterKeys.length > 0 ? (
            <div className="headinglabel">
              {props.heading}
              <img
                src={filterIcon}
                style={{ paddingLeft: '5px' }}
                alt="filter"
                onClick={() => {
                  setdropdownVisible(!dropdownVisible);
                }}
              />
            </div>
          ) : (
            <div className="headinglabel">
              {props.heading}
              <img
                src={filterIcon}
                style={{ paddingLeft: '5px' }}
                alt="filter"
                onClick={() => {
                  setdropdownVisible(!dropdownVisible);
                }}
              />
            </div>
          )}
        </span>
        {dropdownVisible && (
          <div
            className="dropdown-menu lead-filter-dropDown db menu-top40"
            id="lead-filter-dropDown" >
            <span className="dropDownLabel headinglabel">TYPE</span>
            {filterlist.map((item) => {
              let isStatusSelected =
                filterKeys.filter((obj) => obj == item)[0] == item;
           
              return (
                <div className="headrow" key={'us'}>

<FormControlLabel
        control={<GreenCheckbox value = {item} checked={isStatusSelected} onChange={(e) => {
            filterCheckboxChange(e);
          }} name="checkedG" />}
        label={item}
      />            
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default PromocodeTypeFilter;
