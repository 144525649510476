import React from "react"
import search from '../../../assets/images/adminPanel/searchbar.svg';
import CancelIcon from '@material-ui/icons/Cancel';
const SearchBar = (props)=>{

    const [searchInput,setsearchInput] = React.useState("");

    const handleSearchInput = (e)=>{
        setsearchInput(e.target.value);
   }

   const handleKeyDown = (e)=>{
    if (e.key === 'Enter') {
      props.search(searchInput);
    }
  }

  const handleclick= (text)=>{
    props.search(searchInput);
  }


  const handleClear = ()=>{
    setsearchInput("");
    props.search("");
  }
    return (
   
     
        <div className="search-box">
          <input type="text" className="search-input" placeholder="Search.." onChange={ (e)=>{handleSearchInput(e)} } onKeyDown={handleKeyDown} value= {searchInput}/>
         {  searchInput.length>0&&<CancelIcon className="cancelSearch" onClick={handleClear}/>}
            <button className="search-button" onClick={handleclick}>
              <img src={search} style ={{marginLeft:"-2px",marginTop:"-5px"}}/>
            </button>
   </div>
       
      
    )
}

export default SearchBar;