import { Container } from '@material-ui/core';
import React from 'react';
import Layout from '../../components/Layout';
import TeamGroup from './components/TeamGroup';
import HeroAboutUs from './hero';

/**
 * Import Images here
 */

import Gopi from '../../assets/images/team/Gopi.png';
import BinduNaidu from '../../assets/images/team/BinduNaidu.png';
import MarkSamuel from '../../assets/images/team/MarkSamuel.png';
import RiteshDogra from '../../assets/images/team/RiteshDogra.png';
import SarojiniNaidu from '../../assets/images/team/SarojiniNaidu.png';
import ShailajaRao from '../../assets/images/team/ShailajaRao.png';
import SunnyNarang from '../../assets/images/team/SunnyNarang.png';
import TarunDhacha from '../../assets/images/team/TarunDhacha.png';
import Sangeetha from '../../assets/images/team/Sangeetha.png';

const orangeFigsTeam = [
  {
    name: 'Gopi',
    designation: 'CEO',
    image: Gopi,
  },
  {
    name: 'Chef Tarun Dacha',
    designation: 'Culinary Director',
    image: TarunDhacha,
  },
  {
    name: 'Chef Nitin Upadyay',
    designation: 'Head of Recipe Design & Development',
  },
  {
    name: 'Chef Sangeetha',
    designation: 'Product Development',
    image: Sangeetha,
  },
  {
    name: 'Ritesh Dogra',
    designation: 'Marketing Strategy',
    image: RiteshDogra,
  },
  {
    name: 'Mark Samuel',
    designation: 'Branding and Communication',
    image: MarkSamuel,
  },
  
];

const advisoryBoard = [
  {
    name: 'Shialaja Rao',
    designation: 'Founder, Future Kids School',
    image: ShailajaRao,
  },
 
  {
    name: 'Bindu G Naidu',
    designation: 'Corporate Lawyer',
    image: BinduNaidu,
  },
  {
    name: 'Dr. Sarojini Naidu',
    designation: 'Food & Nutrition Expert',
    image: SarojiniNaidu,
  },
  {
    name: 'Sunny Narang',
    designation: 'Founder, RASA Investment Group',
    image: SunnyNarang,
  },
];

const AboutUs = () => {
  return (
    <Layout whiteBG={true}>
      <HeroAboutUs />
      <Container>
        <TeamGroup
          heading="OrangeFigs Team"
          color="blue"
          team={orangeFigsTeam}
        />

        <TeamGroup heading="Advisory Board" color="pink" team={advisoryBoard} />
      </Container>
    </Layout>
  );
};

export default AboutUs;
