import React, { useEffect, useState } from 'react';
import CommonCta from '../../components/Cta';
import ContactUs from '../../components/ContactUs';
import Layout from '../../components/Layout';
import ListingHero from './hero';
import { fetchProducts } from '../../operations/products';
import { Paths } from '../../routes/routePaths';
import ZipcodeVerify from '../../components/Zipcode';

const ProductListing = () => {

  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    const result = await fetchProducts();
    setProducts(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getProducts();
    }
    fetchData();
  }, []);

  return (
    <Layout>
      <ListingHero products={products} />
      <ZipcodeVerify />
      <CommonCta
        title={'Loved our diy kits?'}
        btnText={'Subscribe now'}
        onClick={Paths.Subscribe}
      />
      <ContactUs />
    </Layout>
  );
};

export default ProductListing;
