import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccountInformation from './accountInformation';
import CartSummary from './cartSummary';

const useStyles = makeStyles((theme) => ({
  root: {},
  leftSection: {
    height: '100vh',
  },
}));

const Account = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify={'space-between'}>
        <Grid item xs={12} sm={1} />
        <AccountInformation user={user} />
        <CartSummary />
      </Grid>
    </div>
  );
};

export default Account;
