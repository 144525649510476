import React, { useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import EmailIcon from '../../assets/images/email.png';
import LocationIcon from '../../assets/images/location.png';
import PhoneIcon from '../../assets/images/phone.png';
import FacebookIcon from '../../assets/images/facebook.png';
import InstaIcon from '../../assets/images/insta.png';
import YoutubeIcon from '../../assets/images/youtube.png';
import LinkedinIcon from '../../assets/images/linkedin.png';
import CheckIcon from '../../assets/images/check-circle-orange.png';
import TextField from '@material-ui/core/TextField/TextField';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { colors } from '../../theme/colors';
import { debounce, validateEmail } from '../../utils';
import { sendEmail } from '../../operations/common';
import { GenericErrorForm, EnterValidEmail } from '../../operations/utils';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '100px 0 0',
    backgroundColor: '#FAF7F3',
  },
  iconWrap: {
    width: '24px',
    height: 'auto',
    marginRight: '25px',
  },
  iconWrapLarge: {
    width: '32px',
    height: 'auto',
    marginRight: '38px',
  },
  icon: {
    width: '100%',
  },
  iconMedium: {
    width: 40,
    marginBottom: 56,
  },
  contactForm: {
    backgroundColor: '#fff',
    borderRadius: '40px 40px 0 0',
    padding: '42px 47px!important',
    height: 486,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Roboto',
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '43px 0',
  },
}));

const details = [
  {
    id: 1,
    icon: LocationIcon,
    info: 'Orange Figs, Road No 78, Jubilee Hills, Hyderabad',
    alt: 'Location',
  },
  {
    id: 2,
    icon: EmailIcon,
    info: 'hello@orangefigs.com',
    alt: 'Email',
  },
  {
    id: 3,
    icon: PhoneIcon,
    info: '+91 900 001 0770 ',
    alt: 'Phone',
  },
];

const ContactUs = () => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const triggerEmail = async () => {
    if (name === '' || email === '' || message === '') {
      GenericErrorForm();
      return;
    } else if (!validateEmail(email)) {
      EnterValidEmail();
      return;
    }

    await sendEmail(
      `${email} - Contact Us`,
      `Email: ${email} \nName: ${name} \nMessage: ${message}`,
      'hello@orangefigs.com'
    );

    setShowSuccess(true);
  };

  const validate = () => {
    return name.length !== 0 && validateEmail(email) && message.length !== 0;
  };

  const showEmailError = debounce((email) => {
    if (email === '') {
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, 1000);

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={'h2'}
              style={{
                color: colors.info.lighter,
                fontSize: 40,
                lineHeight: '56px',
                fontFamily: 'HappySchool',
                paddingTop: 0,
                marginBottom: 36,
              }}>
              Have a question?
              <br /> Contact us
            </Typography>
            <div>
              {details.map((item) => (
                <div
                  style={{ display: 'flex', padding: '12px 0' }}
                  key={item.id}>
                  <div className={classes.iconWrap}>
                    <img
                      src={item.icon}
                      alt={item.alt}
                      className={classes.icon}
                    />
                  </div>
                  <Typography style={{ width: '259px', fontSize: 20 }}>
                    {item.info}
                  </Typography>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', marginTop: 45 }}>
              <div className={classes.iconWrapLarge}>
                <a
                  href={'https://www.facebook.com/orangefigsofficial/'}
                  onClick={() => null}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={'inherit'}>
                  <img
                    src={FacebookIcon}
                    alt={'Facebook'}
                    className={classes.icon}
                  />
                </a>
              </div>
              <div className={classes.iconWrapLarge}>
                <a
                  href={'https://www.instagram.com/orangefigsofficial/'}
                  onClick={() => null}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={'inherit'}>
                  <img
                    src={InstaIcon}
                    alt={'Instagram'}
                    className={classes.icon}
                  />
                </a>
              </div>
              <div className={classes.iconWrapLarge}>
                <a
                  href={
                    'https://www.youtube.com/channel/UCkx0Ad2VeyCjbwFX_uTEAtw'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  color={'inherit'}>
                  <img
                    src={YoutubeIcon}
                    alt={'Youtube'}
                    className={classes.icon}
                  />
                </a>
              </div>
              <div className={classes.iconWrapLarge}>
                <a
                  href={'https://www.linkedin.com/company/orange-figs/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => null}
                  color={'inherit'}>
                  <img
                    src={LinkedinIcon}
                    alt={'Linkedin'}
                    className={classes.icon}
                  />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item container xs={12} sm={6} className={classes.contactForm}>
            {!showSuccess ? (
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Grid item xs={12} lg={12} md={12}>
                  <TextField
                    fullWidth
                    label="Name *"
                    variant="outlined"
                    style={{ margin: '12px 0' }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    onChange={(event) => setName(event.target.value)}
                    InputLabelProps={{
                      style: {
                        color: '#6B6B6B',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <TextField
                    fullWidth
                    label="Email *"
                    variant="outlined"
                    style={{ margin: '12px 0' }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      showEmailError(event.target.value);
                    }}
                    error={emailError}
                    helperText={emailError && 'Please enter a valid email'}
                    InputLabelProps={{
                      style: {
                        color: '#6B6B6B',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <TextField
                    fullWidth
                    label="Message *"
                    variant="outlined"
                    style={{ margin: '12px 0' }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    onChange={(event) => setMessage(event.target.value)}
                    InputLabelProps={{
                      style: {
                        color: '#6B6B6B',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Button
                      fullWidth
                      theme
                      disabled={!validate()}
                      className={classes.btnSubmit}
                      onClick={() => triggerEmail()}
                      variant={'contained'}
                      color={'primary'}
                      disableElevation>
                      Submit
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                style={{ padding: '78px 41px' }}>
                <img
                  src={CheckIcon}
                  alt={'Thanks'}
                  className={classes.iconMedium}
                />
                <Typography style={{ fontSize: 16 }}>
                  Thank you for getting in touch with us. We will get back to
                  you soon.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactUs;
