import React,{useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import "./tracking.css"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Roboto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  
}))(MuiDialogActions);

const  EditTracking  = withStyles(styles)((props)=> {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { children, classes, onClose, trackingID,...other } = props;
  const [trackID,settrackingID]= React.useState(trackingID);


  const handleTrackingID = ()=>{
    // updateTrackingData(props.uid,)
   
    props.handletrackingID(props.userID,props.orderId,trackID)
    handleClose()
  }


 

  const verifydetails = () => {
    return (
      !trackID 
    );
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <div style={{ paddingTop: '0px', marginLeft: '1em' }}>
      <Button 
      disabled ={props.trackingID?false:true}
      aria-controls="simple-menu"
      aria-haspopup="true" 
      onClick={handleClick}>
        <MoreVertIcon/>
      </Button>
        <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  getContentAnchorEl={null}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
>
  <MenuItem onClick={() => {
          handleClickOpen();
          handleMenuClose();
        }}>EDIT TRACKING</MenuItem>

</Menu>
    
      
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ color: '#B52A65' }}>
          Edit Tracking ID
        </DialogTitle>
        <DialogContent dividers>
         
          <Grid item xs={12} lg={12} md={12} style={{ maxWidth: '88%' }}>
            <TextField
              fullWidth
              label="EDIT TRACKING"
              variant="outlined"
              style={{ margin: '12px 0' }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              value={trackID}
              onChange={(event) => settrackingID(event.target.value)}
              InputLabelProps={{
                style: {
                  color: '#6B6B6B',
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            CANCEL
          </Button>
          <Button
            disabled={verifydetails()}
            autoFocus
            onClick={handleTrackingID}
            style={
              verifydetails() === false
                ? { background: '#B52A65', color: 'white' }
                : { background: '#B52A65', color: 'white', opacity: '0.5' }
            }>
            EDIT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});


export default  EditTracking;