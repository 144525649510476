import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CalendarFilter from '../Common/calendarFilter';
import SearchBar from '../Common/searchbar';
import downloadIcon from '../../../assets/images/adminPanel/download.svg';
import { fetchUsers } from '../../../operations/products';
import downloadCsv from 'download-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
 import { updateTrackingData } from '../Tracking';
import AddTracking from "./addTracking"
import EditTracking from "./edittracking"
import UserTypeFilter from "./Usertypefilter"
import PageSpinner from "../Common/Pagespinner"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '75%',
  },
});

let data= [];

export default function PurchasedUsersTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [UserssortToggle, setUserssortToggle] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [calendardate, setcalendardate] = React.useState(new Date());
  const [loading,setLoading] = React.useState(false);
  ///calender dates
  const [calendarToggle, setcalendarToggle] = React.useState(false); //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setcalendarToggle(false);

    filterUserdata('date');
  }, [calendardate]);

  const filterdates = (type) => {
    setcalendarToggle(false);
    filterUserdata(type);
  };

  const filterUserdata = async (type) => {
    let startdate = new Date();
    let enddate = new Date();

    if (type === 'date') {
      startdate = calendardate;
      enddate = calendardate;
    }

    if (type === 'today') {
      startdate = new Date();
      enddate = new Date();
    }

    if (type === 'week') {
      startdate = moment().subtract(7, 'days'); // 04/05/2021
      enddate = new Date();
    }

    if (type === 'month') {
      startdate = moment().subtract(1, 'months').startOf('month');
      enddate = moment().subtract(1, 'months').endOf('month');
    }
    if (type === 'thismonth') {
      startdate = moment().startOf('month');
      enddate = moment().endOf('month');
    }

    if (type === '30days') {
      startdate = moment().subtract(30, 'days').calendar();
      enddate = moment(new Date());
    }
    if (type === 'quarter') {
      startdate = moment(new Date()).subtract(4, 'months').startOf('month');
      enddate = moment(new Date()).subtract(1, 'months').endOf('month');
    }
    if (type === 'year') {
      startdate = moment(new Date()).subtract(1, 'year').startOf('year');
      enddate = moment(new Date()).subtract(1, 'year').endOf('year');
    }

    const start = moment(startdate).startOf('day')._d; // set to 12:00 am today
    const end = moment(enddate).endOf('day')._d;

    const filteredUsers =
    data &&
    data.filter((user) => {
        let updateddate = null;
        const { orderDate } = user;

        if (orderDate !== '-') {
          updateddate = new Date(orderDate.toDate());
        }
        return updateddate >= start && updateddate <= end;
      });

    setPage(0);
    setRows(filteredUsers);
  };

  const changeDate = (e) => {
    setcalendardate(e);
  };

  const sortUsers = async () => {
    const sorted_arr = UserssortToggle
      ? rows.sort((user1, user2) => {
          setUserssortToggle(false);

          return (
            (user2.orderDate !== '-' && new Date(user2.orderDate.toDate())) -
            (user1.orderDate !== '-' && new Date(user1.orderDate.toDate()))
          );
        })
      : rows.sort((user1, user2) => {
          setUserssortToggle(true); //asecending
          return (
            (user1.orderDate !== '-' && new Date(user1.orderDate.toDate())) -
            (user2.orderDate !== '-' && new Date(user2.orderDate.toDate()))
          );
        });
    setRows(sorted_arr);
  };

  const fetchUser = async () => {
    data=[];
    setLoading(true);
    const result = await fetchUsers();
    const rows =  await fetchdata(result) ;
    setRows(rows);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const downloadUserData = async () => {
    const date = moment().format('DD-MM-YYYY');
    const exportFileName = `Purchase User Data ${date}.csv`;
    // downloadCsv(data, columns, exportFileName);
    const tabledata = rows.map((User) => {
      const {
        name,
        price,
        orderDate,
        enteredpromocode,
        orderId,
        phone,
        email,
        ShippingAddress,
        subscribed,       
        firstKidName,
        firstKidDOB,
        trackingOrder,
        title
      } = User;
      const orderDateNew =
        orderDate !== '-'
          ? orderDate
              .toDate()
              .toString()
              .replace('GMT+0530 (India Standard Time)', '')
          : '-';
      const AddressOne =
      ShippingAddress && ShippingAddress.addressOne
          ? ShippingAddress.addressOne
          : '';
      const AddressTwo =
      ShippingAddress && ShippingAddress.addressTwo
          ? ShippingAddress.addressTwo
          : '';
      const Zipcode =
      ShippingAddress && ShippingAddress.zipcode
          ? ShippingAddress.zipcode
          : '';
      const CityName =
      ShippingAddress && ShippingAddress.cityName
          ? ShippingAddress.cityName
          : '';
      const StateName =
      ShippingAddress && ShippingAddress.stateName
          ? ShippingAddress.stateName
          : '';
          const CountryName =
      ShippingAddress && ShippingAddress.country
          ? ShippingAddress.country
          : 'India';
      var address =
        AddressOne +
        ' | ' +
        AddressTwo +
        ' | ' +
        CityName +
        ' | ' +
        StateName +
        ' | ' +
        Zipcode+
        ' | ' +
        CountryName;
      address = address.replaceAll(',', '-');
      const obj = {
        name,
        phone,
        email,
        address,
        firstKidName,
        firstKidDOB,
        title,
        enteredpromocode,
        price,
        orderId,
        trackingOrder,
        orderDate: orderDateNew,
      };
      return obj;
    });
    downloadCsv(
      tabledata,
      {
        name: 'Name',
        price: 'Grand Total',
        orderDate: 'OrderDate',
        enteredpromocode: 'Promocode',
        orderId: 'Order ID',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        trackingOrder:"Tracking Order",
        subscribed: 'Subscribed',
        firstKidName: 'Kid Name',
        firstKidDOB: 'Kid DOB',    
        title:'Purchased item'   
      },
      exportFileName
    );
  };

  const fetchdata = async (result) => {
    
    await result.map((detail) => {
      const orderHistory = detail.orderHistory;
      if (orderHistory) {
        Object.keys(orderHistory).map(function (key) {
          const trackingOrder = orderHistory[key].tracking_id
            ? orderHistory[key].tracking_id
            : null;
          const orderId = orderHistory[key].rzpOrderId
            ? orderHistory[key].rzpOrderId
            : '-';
          const enteredpromocode = orderHistory[key].enteredpromocode
            ? orderHistory[key].enteredpromocode
            : '-';
          const orderDate = orderHistory[key].orderDate
            ? orderHistory[key].orderDate
            : '-';
          const price = orderHistory[key].grandTotal
            ? orderHistory[key].grandTotal
            : '-';
          var currency = '₹';
          
          const {
            name,
            phone,
            email,
            id,
            qty,
            shippingAddress,
            firstKidName,
            firstKidDOB,
          } = detail;

          const KidName = orderHistory[key].shippingAddress&&orderHistory[key].shippingAddress.firstKidName ? orderHistory[key].shippingAddress.firstKidName : firstKidName;
          const KidDOB = orderHistory[key].shippingAddress&&orderHistory[key].shippingAddress.firstKidDOB ? orderHistory[key].shippingAddress.firstKidDOB : firstKidDOB;

          const ShippingAddress = orderHistory[key].shippingAddress?orderHistory[key].shippingAddress:shippingAddress
         
          let type_arr = [];
          const subscribed = 'yes';
          var title = '';
          //picking products
          if (orderHistory[key].products && orderHistory[key].products.length) {
            type_arr.push('Single kits');
            title = 'Single kits';
          }
          //Subscriptions
          if (
            orderHistory[key].subscriptions &&
            orderHistory[key].subscriptions.length
          ) {
            type_arr.push(orderHistory[key].subscriptions[0].title);
            title = 'Subscription'
          }
          //Summer camps
          if (
            orderHistory[key].summerCamp &&
            orderHistory[key].summerCamp.length
          ) {
            if(orderHistory[key].summerCamp[0]?.country !== 'USA') {
              type_arr.push('Summer Camp');
              title = 'Summer Camp'
            }
            //USA item
            else {
              type_arr.push(orderHistory[key].summerCamp[0].title);
              title = orderHistory[key].summerCamp[0].title;
              currency = '$';
            }
          }

          //snacks
          // if (orderHistory[key].snacks && orderHistory[key].snacks.length) {
          //   title = 'Snacks'
          // }

          //IIS school
          if (orderHistory[key].snacks && orderHistory[key].snacks.length) {
            if(orderHistory[key].snacks[0]?.school){
              title = orderHistory[key].snacks[0].title;
            }
            else {
              title = 'Snacks'
            }
            type_arr.push('Snacks');
          }

          data.push({
                name,
                type_arr,
                title,
                trackingOrder,
                price,
                id,
                orderDate,
                enteredpromocode,
                orderId,
                phone,
                email,
                ShippingAddress,
                subscribed,
                KidName,
                KidDOB,
                currency
              });

         
        });
      }
    });

    const res = data.sort((user1, user2) => {
      setUserssortToggle(false);

      return (
        (user2.orderDate !== '-' && new Date(user2.orderDate.toDate())) -
        (user1.orderDate !== '-' && new Date(user1.orderDate.toDate()))
      );
    })

   return res;
  };

  const nodata = () => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key="details">
        <TableCell>no data Found</TableCell>
      </TableRow>
    );
  };

  const [reset, setReset] = React.useState(true);

  useEffect(() => {
    setRows(data);
  }, [reset]);

  const resetData = () => {
    setReset(!reset);
  };

  const closeCalendarfilter = () => {
    setcalendarToggle(false);
  };

  const handletrackid= async (uid,orderID,newTrackingID) => {
    await updateTrackingData  (uid, orderID,newTrackingID)
    .then(fetchUser)
    .then(console.log("update done"));   
  }


  const searchUsers = (text) => {
    const searchedUsers = data.filter((User) => {
      let { name, orderId, phone, email } = User;
      name = name.toLowerCase();
      const namesearch = name.toLowerCase().search(text.toLowerCase()) > -1;
      const orderIdsearch =
        orderId.toLowerCase().search(text.toLowerCase()) > -1;
      const phonesearch = phone.toLowerCase().search(text.toLowerCase()) > -1;
      const emailsearch = email.toLowerCase().search(text.toLowerCase()) > -1;

      if (namesearch || orderIdsearch || phonesearch || emailsearch)
        return true;
    });
    setRows(searchedUsers);
  };

const TypeFilterChange = (filterkeys)=>{
  let fileteredCustomers = data ;

  if (filterkeys.length) {
    fileteredCustomers = data.filter((User) => {
      let check= false;
      User.type_arr.map((item)=>{
        check = filterkeys.includes(item);
      });
      return check;     
    })
  }
  
  setRows(fileteredCustomers);

}  

  return (
    <>
    <div>
      <div className="tab">

      <Grid 
        container 
        direction="row" 
        style={{height:"7.5%"}}
        >
        <Grid
          item container
          style={{width:"50%"}}           
          spacing={2}      
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item className="containerHeading">
          Customers
          </Grid>
        </Grid>
        <Grid
         item container
         spacing={2}
         style={{width:"51%"}}  
          direction="row"
          justify="flex-end"
          alignItems="center">


<Grid item>
                <SearchBar
                  search={searchUsers}
                  style={{ margin: '10px', maxWidth: '90%' }}
                />
              </Grid>
              <Grid item>
                <Button className="btn1 " 
                startIcon={  <img
                  src={downloadIcon}
                  alt="download"
                  style={{ marginRight: '3px' }}
                />}
                onClick={downloadUserData}>
                 DOWNLOAD
                </Button>
              </Grid>
              <Grid item>
                <Button className="btn1 " 
                startIcon={<RefreshIcon style={{ color: 'white' }} />}
                onClick={resetData}>                                
                  REFRESH
                </Button>
              </Grid>
          
     
      </Grid>
      </Grid>
       
          {calendarToggle && (
            <CalendarFilter
              filterdates={filterdates}
              changeDate={changeDate}
              close={closeCalendarfilter}
            />
          )}


        <div>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Customer Details
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Address
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Kid Details
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Purchased item
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Promocode
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '200',
                        maxWidth: '200',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Grand total
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      <UserTypeFilter 
                       statusFilterChange={TypeFilterChange} 
                      heading={"Type"} />
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Order ID
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '200',
                        maxWidth: '200',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                      Country
                    </TableCell>
                     <TableCell
                     >
                      Tracking ID
                    </TableCell> 
                    {/* <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      {trackingOrder?trackingOrder:<Button>ADD</Button>}
                </TableCell> */}
                    <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}
                      >
                      <span>Purchased Time</span> &nbsp;
                      <span>
                        <TodayIcon
                          onClick={() => {
                            setcalendarToggle(!calendarToggle);
                          }}></TodayIcon>
                      </span>
                    </TableCell>
                     <TableCell
                      style={{
                        minWidth: '170',
                        maxWidth: '170',
                        minHeight: '20',
                        maxHeight: '20',
                      }}>
                        Action                  
                    </TableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                
                              
                  {loading && (
                    <TableRow style ={{ width:"100%",left:"50vh",height: "50vh"}}>                
                      <PageSpinner />                                                                                
                          </TableRow>
                                 )}
                                 
                  {rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((User,index) => {
                        const {
                          name,
                          type_arr,
                          title,
                          price,
                          orderDate,
                          enteredpromocode,
                          orderId,
                          phone,
                          email,
                          ShippingAddress,
                          trackingOrder,
                          
                          KidName,
                          KidDOB,
                          id,
                          currency
                        } = User;

                        let grandTotal = price;
                        const AddressOne =
                        ShippingAddress && ShippingAddress.addressOne
                            ? ShippingAddress.addressOne + ','
                            : '';
                        const AddressTwo =
                        ShippingAddress && ShippingAddress.addressTwo
                            ? ShippingAddress.addressTwo + ','
                            : '';
                        const Zipcode =
                        ShippingAddress && ShippingAddress.zipcode
                            ? ShippingAddress.zipcode + ','
                            : '';
                        const CityName =
                        ShippingAddress && ShippingAddress.cityName
                            ? ShippingAddress.cityName + ','
                            : '';
                        const StateName =
                        ShippingAddress && ShippingAddress.stateName
                            ? ShippingAddress.stateName + ','
                            : '';

                        const CountryName =
                        ShippingAddress && ShippingAddress.country
                            ? ShippingAddress.country + ''
                            : 'India';
                            
                        const grandtotal = price;

                        const address =
                          AddressOne +
                          AddressTwo +
                          CityName +
                          StateName +
                          Zipcode + CountryName;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"details"+id+orderId}>
                            <TableCell key={"name"+id+orderId}>
                              <p className="tablename">{name}</p>
                              <p>{phone}</p>
                              <p>{email}</p>
                            </TableCell>
                            <TableCell
                              key={"address"+id+orderId}
                              style={{ maxWidth: '300px' }}>
                              {' '}
                              {address ? address : '-'}
                            </TableCell>
                            <TableCell key={"firstKidName"+id+orderId}>
                              <p>{KidName + ','}</p>
                              <p>{KidDOB}</p>  
                
                            </TableCell>
                            <TableCell key={"title"+id+orderId}>
                              {' '}
                              {title}{' '}
                            </TableCell>
                            <TableCell key={"enteredpromocode"+id+orderId}>
                              {' '}
                              {enteredpromocode}{' '}
                            </TableCell>
                            <TableCell key={"grandtotal"+id+orderId}>
                              {' '}
                              {currency}{grandtotal}{' '}
                            </TableCell>
                            <TableCell key={"type"+id+orderId}>
                              {' '}
                              {type_arr.toString()}{' '}
                            </TableCell> 

                            <TableCell key={"orderId"+id+orderId}> {orderId} </TableCell> 
                            <TableCell key={"country"+id+orderId}> {CountryName} </TableCell> 
                            <TableCell
                              style={{ maxWidth:"200px"}}
                              key={"trackingOrder" + id + orderId}
                            > {trackingOrder === null ? (<AddTracking orderId={orderId} userID={id} handletrackingID={handletrackid}></AddTracking>) :
                              <Typography
                              style={{ wordWrap: "break-word" }}
                              noWrap={true}    
                              >
                              {trackingOrder}     
                                </Typography>
                              } </TableCell>
                            <TableCell key={"updatedtime"+id+orderId}>
                              {' '}
                              {orderDate !== '-'
                                ? orderDate
                                    .toDate()
                                    .toString()
                                    .replace(
                                      'GMT+0530 (India Standard Time)',
                                      ''
                                    )
                                : '-'}{' '}
                                </TableCell>
                             <TableCell key={"Action"+orderId}>  <EditTracking orderId={orderId} userID={id} handletrackingID={handletrackid} trackingID={trackingOrder}></EditTracking> 
                           </TableCell> 
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
           
          </Paper>
        </div>
      </div>
    </div>
     <Paper
     style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
 >
            <Grid container
                style={{ height:"100%"}}
      direction="row"
      justify="flex-end"
      alignItems="center">
         <Grid item alignItems="center">
         <TablePagination className="table-fotter"
    rowsPerPageOptions={[10, 20, 50, 100]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
  />
         </Grid>
        
     </Grid>
     
         
 </Paper>
  </>
  );
}
