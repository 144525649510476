import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import SnacksHero from './hero';
import CommonCta from '../../components/Cta';
import ContactUs from '../../components/ContactUs';
import { fetchReadyToEatSnacks } from '../../operations/readyToEatSnacks';
import { Paths } from '../../routes/routePaths';
import ZipcodeVerify from '../../components/Zipcode';

const ReadyToEat = () => {

  const [snacks, setSnacks] = useState([]);

  const getSnacks = async () => {
    const result = await fetchReadyToEatSnacks();
    setSnacks(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getSnacks();
    }
    fetchData();
  }, []);

  return (
    <Layout>
      <SnacksHero snacks={snacks}/>
      <ZipcodeVerify/>
      <CommonCta
        title={'Loved our diy kits?'}
        btnText={'Subscribe now'}
        onClick={Paths.Subscribe}
      />
      <ContactUs />
    </Layout>
  );
};

export default ReadyToEat;