import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import CakeIcon from '../../assets/images/temp-cake.jpg';
import { useSelector } from 'react-redux';
import { months } from '../../utils';

const useStyles = makeStyles((theme) => ({
  rightSection: {
    backgroundColor: 'rgba(250, 247, 243, 0.7)',
    padding: '0!important',
    display: 'block',
  },
  rightHeading: {
    fontSize: 24,
    lineHeight: '36px',
    padding: '80px 0 56px',
    textAlign: 'center',
    color: '#535B65',
    borderBottom: '1px solid #D8D8D8',
  },
  productSection: {
    padding: '70px 40px 40px 48px',
  },
  productImage: {
    width: 140,
    height: 100,
    objectFit: 'cover',
    borderRadius: 6,
  },
  productName: {
    fontSize: 20,
    lineHeight: '24px',
    color: '#535B65',
  },
  subTotal: {
    padding: '40px 48px',
    float: 'right',
  },
  totalPrice: {
    float: 'right',
  },
  shipping: {
    color: '#FCAB53',
    float: 'right',
  },
  bottomText: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    opacity: 0.8,
    fontWeight: 400,
  },
  divider: {
    border: '1px solid #D8D8D8',
    margin: '0 40px',
  },
}));

const CartSummary = () => {
  const classes = useStyles();

  let {
    subscriptions = [],
    products = [],
    snacks = [],
    grandTotal = 0,
    shippingTotal = 0,
    tax = 0,
    subTotal = 0,
  } = useSelector((state) => state.cart);

  return (
    <Grid container item xs={12} sm={5} className={classes.rightSection}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body2" className={classes.rightHeading}>
          Your Order Summary
        </Typography>
      </Grid>
      {subscriptions.map((item, index) => (
        <Item item={item} key={`subs${index}`} />
      ))}

      {products.map((item, index) => (
        <Item item={item} key={`prods${index}`} />
      ))}

      {snacks.map((item, index) => (
        <Item item={item} key={`snacks${index}`} />
      ))}
      <hr className={classes.divider} />

      <Grid container item xs={12} sm={12} className={classes.subTotal}>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Sub total
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            ₹ {subTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Shipping
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography
            variant={'h2'}
            color={'primary'}
            className={classes.shipping}>
            ₹ {shippingTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Tax (IGST 18%)
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            ₹ {tax.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid container item xs={12} sm={12} className={classes.subTotal}>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            ₹ {grandTotal.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Item = ({ item }) => {
  const classes = useStyles();

  const { month } = item;

  const isPreorder = month ? !(month === months[new Date().getMonth()]) : false;

  return (
    <Grid
      container
      spacing={2}
      item
      xs={12}
      sm={12}
      className={classes.productSection}>
      <Grid item xs={4} sm={4}>
        <Badge badgeContent={item.qty} color="primary">
          <img
            src={item.image || CakeIcon}
            alt="Item"
            className={classes.productImage}
          />
        </Badge>
      </Grid>
      <Grid container item xs={5} sm={5}>
        <Typography variant={'h2'} className={classes.productName}>
          {item.name}
        </Typography>
        {isPreorder && (
          <Typography
            style={{
              fontSize: 14,
              color: '#faab53',
            }}>{`Note: Item will be shipped on 30th ${
            months[new Date().getMonth()]
          } `}</Typography>
        )}
      </Grid>
      <Grid item xs={3} sm={3}>
        <Typography variant={'h2'} className={classes.totalPrice}>
          ₹ {(item.price - (item.discount_price ? item.discount_price : 0)) * item.qty}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CartSummary;
