import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import Logo from '../../assets/images/of_logo.svg';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';
import GlobalActions from '../../redux/reducer/globalReducer';
import { useDispatch } from 'react-redux';
import HeartBox from '../../assets/images/heart_box.png';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '204px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '60px',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '24px',
    },
  },
  logo: {
    height: '95px',
    width: '171px',
    cursor: 'pointer',
    marginTop: '80px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '32px',
    },
  },
  paymentInfoContainer: {
    marginTop: '80px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '48px',
    },
  },
  icon: {
    fontSize: '56px',
    color: '#4370be',
  },
  heading: {
    fontSize: '56px',
    fontWeight: 800,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '40px',
    fontSize: '16px',
    textTransform: 'none',
    color: theme.palette.common.white,
    fontFamily: 'HappySchool',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px'
    },
  },
  sub: {
    marginTop: '40px',
    fontSize: '20px',
    [theme.breakpoints.up('md')]: {
      width: '660px',
    },
  },
  heartBox: {
    height: '155px',
    width: '150px',
    objectFit: 'contain'
  },
  buttonContainer: {
    marginTop: '40px',
    marginBottom: '88px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '48px',
    },
  },
  rightButton: {
    marginLeft: '40px',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '12px'
    },
  }
}));

const OrderSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  return (
    <div className={classes.container}>
      <img
        onClick={() => {
          history.push(Paths.Home);
        }}
        src={Logo}
        alt="Orange Figs"
        className={classes.logo}
      />

      <div className={classes.paymentInfoContainer}>
        <Grid spacing={1} container direction="column" >
          <Grid item xs={1}>
            <img className={classes.heartBox} src={HeartBox} alt="Heart Box" />
          </Grid>
          <Grid style={{ marginTop: '56px' }} container alignItems="center" justify="flex-start" item xs={11}>
            <Typography className={classes.heading}>Thank you !</Typography>
          </Grid>
        </Grid>

        <Typography className={classes.sub}>
          {` Your order with order id ${orderId}  has been placed successfully! Please check your email for confirmation & invoice`}
        </Typography>

        <Typography className={classes.sub}>
          Before you go, did you check out our susbcription boxes? You get a lot more & save that much more while you’re at it.
        </Typography>
      </div>

      <div className={classes.buttonContainer}>
        {/* <Button
          onClick={() => {
            history.push(Paths.Subscribe);
            dispatch({
              type: GlobalActions.setCartStatus.SUCCESS,
              payload: false,
            });
          }}
          variant="contained"
          disableElevation
          className={classes.button}>
          SHOW ME THE PLANS
      </Button> */}
        <Button
          onClick={() => {
            history.push(Paths.Home);
            dispatch({
              type: GlobalActions.setCartStatus.SUCCESS,
              payload: false,
            });
          }}
          variant="contained"
          disableElevation
          className={`${classes.button} ${classes.rightButton}`}>
          LATER, TAKE ME HOME FOR NOW
      </Button>
      </div>

    </div>
  );
};

export default OrderSuccess;
