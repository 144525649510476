import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Paths } from '../../routes/routePaths';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 72,
    width: 'auto',
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 36px',
    fontSize: '1rem',
  },
}));

const Hero = () => {
  const classes = useStyles();

  return (
    <div className="hero-bg-right custom-center">
      <div className="hero-bg-left">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <Typography
                variant={'h2'}
                style={{
                  color: '#FAAB53',
                  fontSize: 56,
                  lineHeight: '72px',
                  fontFamily: 'HappySchool',
                  paddingTop: 0,
                }}>
                Realising Potential <br />
                Not Dreams
              </Typography>
              <Typography variant={'h2'} style={{ fontWeight: 400 }}>
                Don’t realise your children’s dreams for them, instead, help
                them realise their potential, and they will surely realise their
                dreams.
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Link to={Paths.ProductListing} variant={'h2'}>
                      <Button
                        theme
                        className={classes.btnSubmit}
                        onClick={() => console.log('clicked')}
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Begin the fun with one
                      </Button>
                    </Link>
                  </MuiThemeProvider>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Link to={Paths.Subscribe} variant={'h2'}>
                      <Button
                        theme
                        className={classes.btnSubmit}
                        onClick={() => console.log('clicked')}
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Join the Orange Gang Today
                      </Button>
                    </Link>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
