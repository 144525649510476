import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '../../components/Alert';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import BackIcon from '../../assets/images/ico-back-orange.png';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { fetchUserDataById, updateUserData } from '../../operations/onboarding';
import {
  paymentHandler,
  subscriptionPaymentHandler,
} from '../../operations/payment';
import { ZipcodeVerification } from '../../operations/common';

import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
  },
  icon: {
    height: 22,
    width: 'auto',
    marginRight: 10,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Andes',
  },
  headingLeft: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 15,
  },
  headingRight: {
    color: '#535B65',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    float: 'right',
    marginBottom: 15,
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '43px 0',
  },
  alertArea: {
    minHeight: '75px',
    height: 'auto',
  },
}));

const ShippingDetails = ({ user, history, handleBack }) => {
  const classes = useStyles();
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeServiceable, setZipcodeServiceable] = useState([]);
  const [userData, setUserData] = useState({});
  const [alert, setAlert] = useState(false);

  const dispatch = useDispatch();

  let { shippingTotal = 0, tax = 0 } = useSelector((state) => state.cart);

  const getUserData = async (user) => {
    const userData = await fetchUserDataById(user.uid);
    setUserData(userData);
    if (userData.cart) {
      dispatch(CartActions.setCart({ cart: userData.cart }));
    }
  };

  const getZipcode = async () => {
    const result = await ZipcodeVerification();
    setZipcodeServiceable(result);
  };

  useEffect(() => {
    async function fetchData(user) {
      await getUserData(user);
      await getZipcode();
    }
    if (user) {
      fetchData(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const findZipcode = (value) => {
    return zipcodeServiceable.findIndex((zip) => {
      return zip.Pincode === value;
    });
  };

  const validate = () => {
    return (
      !addressOne.length ||
      !addressTwo.length ||
      !cityName.length ||
      !stateName.length ||
      !zipcode.length ||
      findZipcode(zipcode) === -1
    );
  };

  const checkIfUpdated = () => {
    const shipping = userData.shippingAddress;
    if (shipping) {
      return (
        addressOne !== shipping.addressOne ||
        addressTwo !== shipping.addressTwo ||
        cityName !== shipping.cityName ||
        stateName !== shipping.stateName ||
        shipping.zipcode !== zipcode
      );
    } else {
      return true;
    }
  };

  const verifyZipcode = (value) => {
    if (findZipcode(value) === -1) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  };

  useEffect(() => {
    if (userData.shippingAddress) {
      const shipping = userData.shippingAddress;
      setAddressOne(shipping.addressOne);
      setAddressTwo(shipping.addressTwo);
      setCityName(shipping.cityName);
      setStateName(shipping.stateName);
      setZipcode(shipping.zipcode);
    }
  }, [userData]);

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingLeft}>
                Shipping details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingRight}>
                All fields are required
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Address line 1"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={addressOne}
                onChange={(event) => setAddressOne(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Address line 2"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={addressTwo}
                onChange={(event) => setAddressTwo(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                value={cityName}
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setCityName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="State"
                value={stateName}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setStateName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Zip code"
                type="number"
                value={zipcode}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => {
                  let value = event.target.value;

                  if (value.toString().length > 6) return;
                  setZipcode(event.target.value);

                  if (value.toString().length === 6) {
                    verifyZipcode(event.target.value);
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            {alert && (
              <Grid className={classes.alertArea} item xs={12}>
                <Alert severity="error">
                  Thank you for showing interest. Unfortunately, we don’t
                  currently deliver at this zipcode. We will make sure to update
                  you at the email/phone number provided.
                </Alert>
              </Grid>
            )}
            {/* <Grid item xs={12} lg={12} md={12} style={{ marginTop: 20 }}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" />}
                    label="Billing address is same as shipping address"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid> */}
          </Grid>

          <Grid item xs={12} lg={12} md={12}>
            <MuiThemeProvider theme={buttonFont}>
              <Button
                disabled={validate()}
                fullWidth
                theme
                className={classes.btnSubmit}
                onClick={async () => {
                  if (!validate()) {
                    if (checkIfUpdated()) {
                      await updateUserData(user.uid, {
                        shippingAddress: {
                          addressOne,
                          addressTwo,
                          cityName,
                          stateName,
                          zipcode,
                        },
                      });
                    }
                    if (
                      userData.cart.subscriptions &&
                      userData.cart.subscriptions.length
                    ) {
                      await subscriptionPaymentHandler(
                        user,
                        userData,
                        dispatch,
                        history,
                        {
                          addressOne,
                          addressTwo,
                          cityName,
                          stateName,
                          zipcode,
                        },
                        tax,
                        shippingTotal
                      );
                    } else {
                      await paymentHandler(
                        userData.cart.grandTotal,
                        user,
                        userData,
                        dispatch,
                        history,
                        {
                          addressOne,
                          addressTwo,
                          cityName,
                          stateName,
                          zipcode,
                        },
                        tax,
                        shippingTotal
                      );
                    }
                  }
                }}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                Confirm & Pay
              </Button>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h2'}
              style={{ alignItems: 'center', display: 'flex' }}>
              <img src={BackIcon} alt="Back" className={classes.icon} />
              <Link
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleBack();
                }}>
                Return to User details
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(ShippingDetails);
