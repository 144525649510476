import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import muiTheme from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Routes from './routes';
import { auth } from './firebase';
import Loader from './components/Loader';
import configureStore from './redux/store/createStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import ScrollToTop from './utils/ScrollToTop';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  async function onAuthStateChanged(user) {
    setUser(user);
    if (loading) setLoading(false);
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    onAuthStateChanged();
    return subscriber;

  }, []);

  if (loading) return <Loader />;

  const history = createBrowserHistory();

  const { store, persist } = configureStore(history);

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <MuiThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persist}>
              <Routes user={user} />
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default App;
