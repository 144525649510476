import { put, takeLatest, select } from 'redux-saga/effects';
import CartActions from '../reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import { updateUserData } from '../../operations/onboarding';
import { Paths } from '../../routes/routePaths';
import { currentUser } from '../../operations/utils';

/*
Selectors
 */
const cart = (state) => state.cart;

function* addToCart({ payload: item }) {
  try {
    let { subscriptions = [], products = [], snacks = [] } = yield select(cart);

    if (item.type === PRODUCT_TYPE.SUBSCRIPTION) {

      const index = subscriptions.findIndex(
        (subscription) => subscription.id === item.id
      );

      if(index === -1) {
        subscriptions = [{ ...item, qty: 1 }];
      }

      const user = currentUser();
      if (user) {
        yield updateUserData(user.uid, {
          cart: {
            subscriptions,
            products: [],
            snacks: [],
            grandTotal: item.price,
          },
        });
      }
    } else if (item.type === PRODUCT_TYPE.KIT) {
      subscriptions = [];
      const index = products.findIndex((product) => product.id === item.id);
      if (index !== -1) {
        yield put(CartActions.updateCart({ item, operation: 'ADD' }));
      } else {
        products = [...products, { ...item, qty: 1 }];
      }
    } else if (item.type === PRODUCT_TYPE.SNACKS) {
      subscriptions = [];
      const index = snacks.findIndex((snack) => snack.id === item.id);
      if (index !== -1) {
        yield put(CartActions.updateCart({ item, operation: 'ADD' }));
      } else {
        snacks = [...snacks, { ...item, qty: 1 }];
      }
    }

    yield put(
      CartActions.addToCart.success({ subscriptions, products, snacks })
    );

    if (item.type === PRODUCT_TYPE.SUBSCRIPTION && item.redirect) {
      window.location.href = Paths.Account;
    }
  } catch (err) {
    yield put(CartActions.addToCart.failure());
  }
}

function* removeFromCart({ payload: item }) {
  try {
    let { subscriptions = [], products = [], snacks = [] } = yield select(cart);

    if (item.type === PRODUCT_TYPE.SUBSCRIPTION) {
      subscriptions = subscriptions.filter(
        (subscription) => subscription.id !== item.id
      );
    } else if (item.type === PRODUCT_TYPE.KIT) {
      products = products.filter((product) => product.id !== item.id);
    } else if (item.type === PRODUCT_TYPE.SNACKS) {
      snacks = snacks.filter((snack) => snack.id !== item.id);
    }

    yield put(
      CartActions.removeFromCart.success({ subscriptions, products, snacks })
    );
  } catch (err) {
    yield put(CartActions.removeFromCart.failure());
  }
}

function* updateCart({ payload }) {
  try {
    const { item, operation } = payload;

    let { subscriptions = [], products = [], snacks = [] } = yield select(cart);

    if (item.qty <= 1 && operation === 'SUBTRACT') {
      yield put(CartActions.removeFromCart(item));
    } else {
      if (item.type === PRODUCT_TYPE.SUBSCRIPTION) {
        const index = subscriptions.findIndex(
          (subscription) => subscription.id === item.id
        );
        if(index === -1) {
          yield put(CartActions.addToCart({...item}));
          subscriptions = [{...item}];
        }
        else{
          subscriptions[index]['qty'] =
            operation === 'ADD'
              ? subscriptions[index]['qty'] + 1
              : subscriptions[index]['qty'] - 1;
        }
      } else if (item.type === PRODUCT_TYPE.KIT) {
        const index = products.findIndex((product) => product.id === item.id);
        products[index]['qty'] =
          operation === 'ADD'
            ? products[index]['qty'] + 1
            : products[index]['qty'] - 1;
      } else if (item.type === PRODUCT_TYPE.SNACKS) {
        const index = snacks.findIndex((snack) => snack.id === item.id);
        snacks[index]['qty'] =
          operation === 'ADD'
            ? snacks[index]['qty'] + 1
            : snacks[index]['qty'] - 1;
      }
      yield put(
        CartActions.updateCart.success({ subscriptions, products, snacks })
      );
    }
  } catch (err) {
    console.warn("SS", err);
    yield put(CartActions.updateCart.failure());
  }
}

function* setCart({ payload }) {
  try {
    let { subscriptions = [], products = [], snacks = [] } = payload.cart;
    yield put(CartActions.setCart.success({ subscriptions, products, snacks }));
  } catch (err) {
    yield put(CartActions.setCart.failure());
  }
}

export default [
  takeLatest(CartActions.addToCart.REQUEST, addToCart),
  takeLatest(CartActions.removeFromCart.REQUEST, removeFromCart),
  takeLatest(CartActions.updateCart.REQUEST, updateCart),
  takeLatest(CartActions.setCart.REQUEST, setCart),
];
