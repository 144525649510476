import firebase from 'firebase/app';
import Message from '../components/Message';

export const currentServerTime = firebase.firestore.FieldValue.serverTimestamp();

export const timestampFromDate = inputDate =>
  firebase.firestore.Timestamp.fromDate(inputDate);

export const currentUser = () => firebase.auth().currentUser;

/*
  MESSAGES
 */

 /**
 * Promocode Success Mesage
 */
export const PromocodeSuccessMesage = () =>
Message.success('Promocode has been added successfully.'); 

/**
 *  edit Success Mesage
 */
 export const updateSuccessMesage = (item) =>
   Message.success(item+' has been updated successfully.');
 

 /**
 *  Success Mesage
 */
  export const AddingSuccessMesage = (item) =>
  Message.success(item+' has been added successfully.');

/**
 * Promocode edit Success Mesage
 */
 export const PromocodeupdateSuccessMesage = () =>
 Message.success('Promocode has been updated successfully.'); 

 

/**
 * Generic error - form validation
 */
export const GenericErrorForm = () =>
  Message.error('Fields marked as * are required.');

/**
 * Generic error - form validation
 */
export const EnterValidEmail = () =>
  Message.error('Please enter a valid email address.');

/**
 * Generic error - form validation
 */
export const EnterValidMobile = () =>
Message.error('Please enter a valid mobile number.');

/**
 * Generic success - Update
 */
export const GenericSuccessUpdate = () =>
  Message.success('Data updated successfully.');

/**
 * Generic error - Update
 */
export const UnableToUpdate = () =>
  Message.error(
    'Unable to update data at this time. Please try again later.'
  );
/**
 * Generic error - Create
 */
export const UnableToCreate = () =>
  Message.error(
    'Unable to create data at this time. Please try again later.'
  );

/**
 * Generic create
 */
export const CreatedSuccessFully = () =>
  Message.success('Created successfully.');

/**
 * Lead successfully
 */
 export const LeadCreatedSuccessFully = () =>
 Message.success('Thanks for reaching out to us our team will connect with you soon...');



/**
 * payment successfully
 */
 export const PaymentSuccessMessage = () =>
 Message.success('Thank you for your payment. Your tax invoice should reach your e-mail, shortly!');


//  login admin messages
 export const WrongemailMessage = () =>
  Message.error('Please check the email you have entered');

  export const WrongpasswordMessage = () =>
  Message.error('Please check your password');