import { firebaseFunctions } from '../../firebase';
import Message from '../../components/Message';
import { updateUserData } from '../onboarding';
import { currentServerTime, PaymentSuccessMessage } from '../utils';
import CartActions from '../../redux/reducer/cartReducer';
import { sendEmail, sendTemplateEmail } from '../common';

export const paymentHandler = async (
  amount,
  user,
  userData,
  dispatch,
  history,
  address,
  tax,
  shippingTotal
) => {
  try {

    const { addressOne, addressTwo, cityName, stateName, zipcode } = address;
    const placeOrder = firebaseFunctions.httpsCallable('placeOrder');
    const res = await placeOrder({ amount });
    const options = {
      key: 'rzp_test_UpO0r5X8C6TINb',
      name: 'Orange Figs',
      description: 'Description here',
      order_id: res.data.id,
      amount: Math.ceil(amount * 100),
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.phone || '',
      },
      theme: {
        color: '#cca711',
      },
      handler: async (response) => {
        await updateOrderData(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          user,
          userData,
          dispatch,
          history
        );

        // to admin
        sendEmail(
          `New order - ${response.razorpay_order_id}`,
          `New order from ${userData.email}\nOrder id: ${response.razorpay_order_id}\nPayment_id: ${response.razorpay_payment_id}`,
          'hello@orangefigs.com'
        );

        const products = userData.cart.products.map((el) => {
          return {
            name: el.name,
            price: el.price,
            url: el.image,
            quantity: el.qty,
          };
        });

        const snacks = userData.cart.snacks.map((el) => {
          return {
            name: el.name,
            price: el.price,
            url: el.image,
            quantity: el.qty,
          };
        });

        const cartDataTemplate = [...products, ...snacks];

        const subtotal = cartDataTemplate.reduce(
          (a, val) => a + Number(val.price),
          0
        );

        /*
            data for sendgrid order confirmation template
        */
        const templateData = {
          userName: userData.name,
          orderId: response.razorpay_order_id,
          addressLine1: addressOne,
          addressLine2: addressTwo,
          city: cityName,
          state: stateName,
          zip: zipcode,
          items: cartDataTemplate,
          subtotal: subtotal,
          tax: tax,
          shipping: shippingTotal,
          total: subtotal + tax + shippingTotal,
        };
        PaymentSuccessMessage();
        // to customer
        sendTemplateEmail(
          `Order received - ${response.razorpay_order_id}`,
          userData.email,
          'd-fe08682717294c0db5f1c95abda1d3ca',
          templateData
        );
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      Message.error('FAILED');
    });

    rzp1.open();
  } catch (e) {
    console.warn('ERR', e);
    return false;
  }
};

export const subscriptionPaymentHandler = async (
  user,
  userData,
  dispatch,
  history,
  address,
  tax,
  shippingTotal
) => {
  try {
    const { addressOne, addressTwo, cityName, stateName, zipcode } = address;
    const addSubscription = firebaseFunctions.httpsCallable('addSubscription');
    const subscription = userData.cart.subscriptions[0];
    const res = await addSubscription({
      plan_id: subscription.plan_id,
      qty: subscription.qty,
    });
    const options = {
      key: 'rzp_test_8qi94RvGtVG61h',
      name: 'Orange Figs',
      description: subscription.name,
      subscription_id: res.data.id,
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.phone || '',
      },
      theme: {
        color: '#cca711',
      },
      handler: async (response) => {
        await updateOrderData(
          response.razorpay_subscription_id,
          response.razorpay_payment_id,
          user,
          userData,
          dispatch,
          history,
          subscription
        );

        const cartDataTemplate = userData.cart.subscriptions.map((el) => {
          return {
            name: el.name,
            price: el.price,
            url: el.image,
            quantity: el.qty,
          };
        });
        const subtotal = cartDataTemplate.reduce(
          (a, val) => a + Number(val.price),
          0
        );

        /*
            data for sendgrid subscription confirmation template
        */
        const templateData = {
          userName: userData.name,
          orderId: response.razorpay_subscription_id,
          addressLine1: addressOne,
          addressLine2: addressTwo,
          city: cityName,
          state: stateName,
          zip: zipcode,
          items: cartDataTemplate,
          subtotal: subtotal,
          tax: tax,
          shipping: shippingTotal,
          total: subtotal + tax + shippingTotal,
        };

        // to admin
        sendEmail(
          `New Subscription order - ${response.razorpay_subscription_id}`,
          `New subscription order from ${userData.email}\nSubscription id: ${response.razorpay_subscription_id}\nPayment_id: ${response.razorpay_payment_id}`,
          'hello@orangefigs.com'
        );
        PaymentSuccessMessage();
        // to customer
        sendTemplateEmail(
          `Order received - ${response.razorpay_subscription_id}`,
          userData.email,
          'd-fe08682717294c0db5f1c95abda1d3ca',
          templateData
        );
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      Message.error('FAILED');
    });

    rzp1.open();
  } catch (e) {
    console.warn('ERR', e);
    return false;
  }
};

const updateOrderData = async (
  orderId,
  paymentId,
  user,
  userData,
  dispatch,
  history,
  subscription = null
) => {
  await updateUserData(user.uid, {
    cart: {},
    ...(subscription
      ? { subscription, subscription_start_date: currentServerTime }
      : {}),
    orderHistory: {
      ...(userData.orderHistory ? userData.orderHistory : {}),
      [orderId]: {
        ...userData.cart,
        orderDate: currentServerTime,
        rzpOrderId: orderId,
        rzpPaymentId: paymentId,
      },
    },
  });


  dispatch(CartActions.resetCart());
  history.push(`orderSuccess/${orderId}`);
};
