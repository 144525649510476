import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '../../assets/images/ico-right.png';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { style } from 'redux-logger/src/diff';

const useStyles = makeStyles((theme) => ({
  kitImage: {
    height: '100%',
    width: '100%',
    borderRadius: "24px 24px 0 0"
  },
  main: {
    backgroundColor: 'rgba(250, 26, 85, 0.3)',
    padding: '80px 0 140px',
    textAlign: 'center',
  },
  heading: {
    color: colors.common.white,
    fontSize: 40,
    lineHeight: '56px',
    fontFamily: 'HappySchool',
    marginBottom: 36,
    textAlign: 'center',
  },
  cards: {
    textAlign: 'center',
    padding: '0 0 50px!important',
    borderRadius: '24px',
    backgroundColor: '#fff',

    '&:hover :nth-child(2)': {
      display: 'none',
    },

    '&:hover :last-child': {
      display: 'flex',
    },
  },
  desc: {
    color: colors.common.gray,
    fontSize: 28,
    lineHeight: '40px',
    margin: '45px 47px 0',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    fontSize: 24,
    lineHeight: '36px',
    height: 80,
    width: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '45px 47px 0',
    color: '#535B65',
    textDecoration: 'none',
  },
  icon: {
    height: 64,
    width: 64,
  },
}));

const ThisMonthsKits = ({ products }) => {
  const classes = useStyles();

  const currentDate = moment().format('D');
  const prevMonth = moment().subtract(1, 'months').format('MMMM');
  const currentMonth = moment().format('MMMM');

  const currentMonthKits = products.filter(
    (kit) =>
      (kit.month === currentMonth && currentDate < 25) ||
      (kit.month === prevMonth && currentDate > 24)
  );

  return (
    <>
      {currentMonthKits && currentMonthKits.length
        ? (
          <div className={classes.main}>
            <Container fixed>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} sm={12}>
                  <Typography variant={'h2'} className={classes.heading}>
                    This month’s kits
                </Typography>
                </Grid>

                {currentMonthKits && currentMonthKits.length
                  ? currentMonthKits.map((item, index) => (
                    <Grid item xs={12} sm={5} key={index}>
                      <div className={classes.cards}>
                        <img
                          src={item.image}
                          alt={'Kit'}
                          className={classes.kitImage}
                        />
                        <Typography variant={'body1'} className={classes.desc}>
                          {item.name}
                        </Typography>
                        <Typography variant={'body1'}>
                          <Link
                            to={`baking-kits/products/${item.slug}`}
                            className={classes.link}>
                            Visit the kit details page
                          <img
                              src={LinkIcon}
                              alt="Kit"
                              className={classes.icon}
                            />
                          </Link>
                        </Typography>
                      </div>
                    </Grid>
                  ))
                  : null}
              </Grid>
            </Container>
          </div>
        ) : null}
    </>
  );
};

export default ThisMonthsKits;
