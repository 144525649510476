import { firestore } from '../../firebase';

const fetchReadyToEatSnacks = async () => {
  try {
    const response = await firestore.collection('snacks').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

const fetchReadyToEatSnacksDetails = async (id) => {
  try {
    const response = await firestore.collection('snacks').doc(id).get();
    return {
      ...response.data(),
      id: response.id,
    };
  } catch (e) {
    console.warn(e);
  }
};


const fetchReadyToEatSnacksDetailsBySlug = async (slug) => {

  try {
    const response = await firestore.collection('snacks').where('slug', '==', slug).get();
    let result;
    response.forEach((el) => { result = { ...el.data(), id: el.id } })

    return result
  } catch (e) {
    console.warn(e);
  }
};






export { fetchReadyToEatSnacks, fetchReadyToEatSnacksDetails, fetchReadyToEatSnacksDetailsBySlug };
