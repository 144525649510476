import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import firebase from 'firebase/app';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import minusIcon from '../../../assets/images/minusIcon.png';
import { FormatListNumberedTwoTone } from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { editSnack } from '../../../operations/products';
import { months } from '../../../utils/index';
import addIcon from '../../../assets/images/addicon.png';
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '75%',
  },
});

let data = [];

export default function EditNewSnack(props) {
  const classes = useStyles();

  const [name, setName] = React.useState(props.product.name);
  const [price, setPrice] = React.useState(props.product.price);
  const [month, setMonth] = React.useState(props.product.month);
  const [quantity, setquantity] = React.useState(props.product.quantity);
  const [bakeTime, setBakeTime] = React.useState(props.product.bakeTime);
  const [trailerVideo, settrailerVideo] = React.useState(
    props.product.trailerVideo
  );
  const [serves, setServes] = React.useState(props.product.serves);
  const [recipeVideoUrl, setrecipeVideoUrl] = React.useState(
    props.product.recipeVideoUrl
  );
  const [description, setDescription] = React.useState(
    props.product.description
  );
  const [imageDetails, setimageDetails] = React.useState(
    props.product.imageDetails
  );
  const [shelfLife, setshelfLife] = React.useState(props.product.shelfLife);
  const [counter, setCounter] = React.useState(0);
  const [uid, setUid] = React.useState(props.product.id);
  const [Ingredients_arr, setInIngredients_arr] = React.useState(props.product.ingredients);
  const [imgPreview, setimgPreview] = React.useState(props.product.imageDetails);
  const [img, setImg] = React.useState(props.product.imageDetails);
  const [imgChanged, setImageChanged] = React.useState(false);
  const [error, SetError] = React.useState(false);
  const [Nutritions, setNutritions] = React.useState([
    {
      name: '',
      quantity: '',
    },
  ]);

  useEffect(() => {
    if (props.product) setInitialState();
  }, [props.product]);

  const setInitialState = () => {
    let nutritionarr = Object.entries(props.product.nutrition);
   

    let nutritions = nutritionarr.map((item) => {
      return { name: item[0], quantity: item[1] };
    });

   

    setNutritions(nutritions);
  
    setCounter(counter + 1);
  };


  const validation = async () => {
    let result = await verifydetails();
    console.log("result---", result);
    if (!result) {
      SetError(false);

      const result = await uploadPicture();
      AddProduct(result);       
       
    }
    else SetError(true);
  };

  const Verifyingredients = (item) => {

    return item.length;

  };

  const VerifyNutirions = (Nutritions) => {
    return Nutritions.name.length && Nutritions.quantity
  };


const verifydetails = () => {
  return (
  !name ||
  !price|| 
  !serves||
  !description ||
  !imgPreview||
  !shelfLife||              
      !Nutritions.every(VerifyNutirions) ||        
      !Ingredients_arr.every(Verifyingredients) 

      // !IngredientsNotIncluded[0].Measurement.length ||
  //     ! Ingredients_arr[0].Ingredient.length ||
  // ! Ingredients_arr[0].quantity.length ||
  // ! Ingredients_arr[0].Measurement.length 
            
)

}


  const addIgredient = () => {
    Ingredients_arr.push("");

    setCounter(counter + 1);
  };

  const addNutrition = () => {
    Nutritions.push({
      name: '',
      quantity: '',
      measurement: '',
    });
    setCounter(counter + 1);
  };

  const updateNutritionChange = (name, index) => (event) => {
    let newArr = Nutritions.map((item, i) => {
      if (index == i) {
        return { ...item, [name]: event.target.value };
      } else {
        return item;
      }
    });

    setNutritions(newArr);
    setCounter(counter + 1);
  };

  const deleteNutrition = (index) => {
    let newArr = Nutritions.filter((item, i) => {
      if (index == i) {
        return false;
      } else {
        return true;
      }
    });

    setNutritions(newArr);
    setCounter(counter + 1);
  };

  const updateIngredientsChange = ( index) => (event) => {
    let newArr = Ingredients_arr.map((item, i) => {
        if (index == i) {
            return event.target.value ;
        } else {
            return item;
        }
    });

    setInIngredients_arr(newArr);
    setCounter(counter + 1);
};

  const deleteIngredients = (index) => {
    let newArr = Ingredients_arr.filter((item, i) => {
      if (index == i) {
        return false;
      } else {
        return true;
      }
    });

    setInIngredients_arr(newArr);
    setCounter(counter + 1);
  };

  const uploadPicture = async () => {

    if(imgChanged){
    
    let fileUpload = img;
    console.log("fileUpload----", fileUpload);
    
    let storageRef = firebase.storage().ref('/kit_images/' + fileUpload.name.toString())

    // upload the first file only
    let uploadTask = await storageRef.put(fileUpload);
    const downloadURL = await uploadTask.ref.getDownloadURL();
    console.log("downloadURL----", downloadURL);
    setimageDetails({downloadURL});

      return downloadURL;
    } else {
      return imageDetails;
    }
   
}




const AddProduct= async (imgUrl) => {
    
                            
    let nutrition = {};
    let ingredients = Ingredients_arr;
    let notIncluded = {};
    
    Nutritions.map((item) => {
        nutrition[item.name] = item.quantity;
    });
   
    
    let slug = name.replaceAll(" ", "-");
    let imageDetails = imgUrl;
    let web_image = imgUrl;
    let image = imgUrl;
    let obj = {
        availability :"true" ,
        name,
        quantity,
        price, 
        serves, 
        description, 
        imageDetails,          
        slug,
        shelfLife,
        web_image,
        image,
        imageDetails,
         nutrition ,
     ingredients ,
        
    }

    await editSnack(uid,obj).then(props.close());
    

}


  const onFileChange = async (event) => {

    try {

        //

   
        if (event.target.files && event.target.files[0]) {
          setImg(event.target.files[0]);
          setImageChanged(true);
              let reader = new FileReader();
              reader.onload = (e) => {
                  
                  setimgPreview(e.target.result);
              };
              reader.readAsDataURL(event.target.files[0]);
            }
     

        // Update the state

       

    } catch (error) {
        console.log("err--", error)

    }

};

  return (
    <>
    <div className="tab">
        <Grid
                spacing={3}
                container
            alignItems="center"
            style={{ margin: "3px 3px", maxWidth: "100%", height: "7.5%" }}>
            <Grid item
                alignItems="center"
                className="containerHeading">
        Edit Snack
      </Grid>
                </Grid>
        <Paper style={{ maxHeight: "85%", overflow: 'auto' }}>
            <Grid
                spacing={3}
                container
                style={{ margin: "20px 30px", maxWidth: "70%" }}>

                <Grid item xs={6} lg={6} md={6} >
                    <div style={{ padding: "6px" }}>NAME</div>
                    <TextField
                        label="Name"
                        variant="outlined"
                        type="text"
                        style={{ height: "80%", width: "100%" }}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                                input: classes.input,
                            },
                        }}
                            value={name}
                            error={error&&!name}
                            helperText={error&&!name&&"Please Enter the Field"}
                       
                        onChange={(event) => setName(event.target.value)}
                        InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                    />
                </Grid>
               
                    <Grid item xs={2} lg={2} md={2}>
                    
                        <div style={{marginTop:"12px"}} >
                        <img
                            style={{minWidth:"100px",minHeight:"100px",maxHeight:"100px",borderRadius:"5px"}} 
                            
                            src={imgPreview} alt="preview"/>

                    </div>
                        
  
                </Grid>
            
                 <Grid item xs={3} lg={3} md={3}>
                    <div style={{ padding: "6px" }}> IMAGE</div>
                    
                    <div style={{ height:"60%"}}>
  <input
    accept="image/*"
    onChange={onFileChange} 
    id="contained-button-file"
    multiple
                            type="file"
                            style={{ display:"none"}}
  />
                        <label htmlFor="contained-button-file"
                      style={{ height:"100%"}} >
    <Button variant="raised" component="span" className="btn1"  style={{ height: "100%", padding: "0px 30px" }}>
      Upload Image
                                </Button>
                                <Typography
                                style={{ color:"#424547",opacity:"0.5",fontSize:"12px",fontWeight:"400"}}
                                >Max. file size 500 kb</Typography>
  </label>
</div>
                        {/* <Button className="btn1"
                        style={{ height: "60%", padding: "0px 30px" }}
                        variant="contained" color="primary" component="span"
                    >
                        Upload Image</Button>   */}
  
                </Grid>
               
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <div style={{ padding: "6px" }}> DESCRIPTION </div>
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                                input: classes.input,
                            },
                        }}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        error={error&&!description}
                        helperText={error&&!description&&"Please Enter the Field"}                                                  
                            InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                    <div style={{ padding: "6px" }}>PRICE</div>
                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                        style={{ width: "100%" }}
                            value={price}
                            error={error&&!price}
                            helperText={error&&!price&&"Please Enter the Field"}
                           
                        onChange={(event) => setPrice(event.target.value)}
                        InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                        endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"

                        labelWidth={0}
                    />

                    </Grid>
                    
                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                    <div style={{ padding: "6px" }}>Quantity</div>
                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                        style={{ width: "100%" }}
                            value={quantity}
                            error={error&&!quantity}
                            helperText={error&&!quantity&&"Please Enter the Field"}
                           
                        onChange={(event) => setquantity(event.target.value)}
                        InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                        // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"

                        labelWidth={0}
                    />

                    </Grid>
                    
                 
                    
                <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                    <div style={{ padding: "6px" }}>SERVES</div>
                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                        placeholder={"No. of People"}
                        style={{ width: "100%" }}
                            value={serves}
                            error={error&&!serves}
                            helperText={error&&!serves&&"Please Enter the Field"}
                           
                        onChange={(event) => setServes(event.target.value)}
                        InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                        // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"

                        labelWidth={0}
                    />

                </Grid>
                <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                    <div style={{ padding: "6px" }}>SHELF LIFE</div>
                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                        placeholder="Shelf Life"
                        style={{ width: "100%" }}
                            value={shelfLife}
                            error={error&&!shelfLife}
                            helperText={error&&!shelfLife&&"Please Enter the Field"}
                           
                        onChange={(event) => setshelfLife(event.target.value)}
                        InputLabelProps={{
                            style: {
                                color: '#6B6B6B',
                            },
                        }}
                         aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                    />

                </Grid>
             
                <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ background: "#F6EFE6" }}
                        >
                            <Typography >Ingredients (Included)</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            
                            <Grid container fixed spacing={3} style={{ width: "100%" }}>
                                {
                                    Ingredients_arr.map((item, index) => {
                                        
                                        return (                                             
                                            <>
                                                <Grid item xs={11}  style={{ width: "50%" }}>
                                                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                                                        placeholder={"Ingredient"}
                                                        style={{ width: "100%" }}
                                                        value={item}
                                                        error={error&&!item}
                                                        helperText={error&&!item&&"Please Enter the Field"}
                                                        onChange={updateIngredientsChange(index)}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#6B6B6B',
                                                            },
                                                        }}
                                                        // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"

                                                        labelWidth={0}
                                                    />
                                                </Grid>
                                              
                                                <Grid item xs={1} lg={1} md={1} style={{ width: "100%" }}>
                                                    <img style={{ marginTop: "25%" }}
                                                        onClick={() => { deleteIngredients(index) }}
                                
                                                        src={minusIcon}
                              alt="minus"></img>
         </Grid>
                                            </>

                                        )
                                    })
                                    }
                                    <Grid item sm={12}>
                                <Button
                                        onClick={addIgredient}
                                        style={{ marginLeft:"12px"}}
                                            startIcon={<img
                                                style={{ height:"24px",width:"24px"}}
                                                src={addIcon} alt="addicon" />}
                                        ><Typography
                                        style={{ textTransform:"capitalize",color:"#B52A65"}}
                                            >Add Ingredient</Typography></Button>
                                        </Grid>
                            </Grid>
                        </AccordionDetails>

                    </Accordion>
                </Grid>
               
           
                
                <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ background: "#F6EFE6" }}
                        >
                            <Typography > Nutrition </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {
                                    Nutritions.map((item, index) => {
                                        return (
                                            <>
                                                <Grid item xs={5} lg={5} md={5} style={{ width: "100%" }}>
                                                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                                                        placeholder={"Ingredient"}
                                                        style={{ width: "100%" }}
                                                        value={item.name}
                                                        error={error&&!item.name}
                                                        helperText={error&&!item.name&&"Please Enter the Field"}
                                                        onChange={updateNutritionChange("name", index)}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#6B6B6B',
                                                            },
                                                        }}
                                                        // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"

                                                        labelWidth={0}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6} md={6} style={{ width: "50%" }}>
                                                    <TextField
                            id="outlined-helperText"
                            variant="outlined"
                                                        placeholder={"Quantity"}
                                                        style={{ width: "100%" }}
                                                        value={item.quantity}
                                                        error={error&&!item.quantity}
                                                        helperText={error&&!item.quantity&&"Please Enter the Field"}
                                                       
                                                        onChange={updateNutritionChange("quantity", index)}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#6B6B6B',
                                                            },
                                                        }}
                                                        // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"

                                                        labelWidth={0}
                                                    />
                                                </Grid>
                                               
                                                <Grid item xs={1} lg={1} md={1} style={{ width: "100%" }} >
                                                    <img style={{ marginTop: "25%" }}
                                                            onClick={()=>{deleteNutrition(index)}}
                                                        src={minusIcon} alt="minus"></img>
                                                </Grid>

                                            </>
                                        )
                                    })
                                }
                               
                                    <Button
                                        style={{ marginLeft:"12px"}}
                                        onClick={addNutrition}
                                        startIcon={<img
                                            style={{ height:"24px",width:"24px"}}
                                            src={addIcon} alt="addicon" />}
                                    ><Typography
                                    style={{ textTransform:"capitalize",color:"#B52A65"}}
                                        >Add Nutrition</Typography></Button>
                          


                            </Grid>




                        </AccordionDetails>
                    </Accordion>
                </Grid>

              
              
                

            </Grid>
        </Paper>
       

    </div>
     <Paper
     style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
 >
            <Grid container
                style={{ height:"100%"}}
      direction="row"
      justify="flex-end"
      alignItems="center">
         <Grid item alignItems="center">
         <Button
              style={{ background:"#b52a65",color:"white"}}
            //   disabled = {verifydetails()}
             onClick={validation}>
          SAVE SNACK</Button>
         </Grid>
         <Grid item alignItems="center">
         <Button
                style={{ background:"white",color:"black"}}                                
             onClick={props.close}>
             CANCEL</Button>
         </Grid>

     </Grid>
     
         
 </Paper>
    </>
  );
}
