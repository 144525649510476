import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
    marginTop: "50px",
  },
  center:{
    marginTop: "50%",
    marginLeft:"250%",
  }
}));

export default function PageSpinner(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const timerRef = React.useRef();

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  useEffect(()=>{
    setLoading(props.Loading);
  },[props.Loading])

  return (
    <div className={classes.center}>
    <CircularProgress color="secondary" />
    </div>
  );
}
