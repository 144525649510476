import React from 'react'
import "./promocodes.css"

const PromocodeToggle = (props)=>{

    return(
        <div class="content">
  <label class="switch">
    <input type="checkbox"  checked= {props.check} onChange={props.handleToggle}/>
    <span class="slider round"></span>
  </label>
</div>
    )
}

export default PromocodeToggle;