import { firestore } from '../../firebase';

const fetchSubscriptions = async () => {
  try {
    const response = await firestore
      .collection('subscriptions')
      .orderBy('period', 'asc')
      .get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

export { fetchSubscriptions };
