import { set } from "date-fns/esm";
import React, { useState, useEffect, useRef } from "react";
import filterIcon from "../../../assets/images/adminPanel/filtericon.svg"
import "../Common/subscribefilter.css"
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// Usage
let filterListProps = [];

const GreenCheckbox = withStyles({
  root: {
    color: "#B52A65",
    '&$checked': {
      color: "#B52A65",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function UserTypeFilter(props) {
  const ref = useRef();
  useOnClickOutside(ref, () => setdropdownVisible(false));
  const filterlist = [
    "Single kits",
    "Snacks",
    "Summer Camp",
    "Monthly Plan",
    "Annual Plan"
  ];

  const [dropdownVisible, setdropdownVisible] = React.useState(false);
  const [filterKeys, setFilterkeys] = React.useState([]);

  const filterCheckboxChange = (e) => {
    let checkfilter = e.target.value;
    if (e.target.checked) {
      filterListProps.push(checkfilter)
      setFilterkeys(filterListProps);
    }
    else {
      let value = e.target.value
      filterListProps = filterListProps.filter(obj => obj !== value);
      setFilterkeys(filterListProps);
    }

    props.statusFilterChange(filterListProps);

  }

  useEffect(() => {
    filterListProps = [];
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (


    <div>

      <span>Type</span> <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img src={filterIcon}></img>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {
          filterlist.map((item, index) => {
            let isStatusSelected = filterKeys.filter(obj => obj== item)[0]  == item;

            return (

              <MenuItem >
                <FormControlLabel
                  control={<GreenCheckbox value={item} checked={isStatusSelected} onChange={(e) => {
                    filterCheckboxChange(e);
                  }} name="checkedG" />}
                  label={item}
                />
              </MenuItem>

            )
          })
        }


      </Menu>
    </div>
  );
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default UserTypeFilter;