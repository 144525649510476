import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Cart from '../Cart';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

const Layout = ({ children, whiteBG, orangeBG, navbar = true }) => {
  const dispatch = useDispatch();

  const setOpenCart = (val) => {
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: val });
  };

  let { cartStatus } = useSelector((state) => state.global);

  return (
    <div>
      {
        navbar && (
          <Navbar
            openCart={cartStatus}
            toggleCart={setOpenCart}
            whiteBG={whiteBG}
            orangeBG={orangeBG}
          />
        )
      }

      <Cart openCart={cartStatus} toggleCart={setOpenCart} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
