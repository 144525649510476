import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IngredientIcon from '../../assets/images/item-ingredients.png';
import NutritionIcon from '../../assets/images/item-nutrition.png';
import AccordionDetails from '@material-ui/core/AccordionDetails/AccordionDetails';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#FAF7F3',
    padding: '120px 0',
  },
  icon: {
    width: 'auto',
    height: 64,
    marginRight: 16,
  },
  heading: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#535B65',
    padding: '28px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    borderTop: '1px solid #D7D7D7',
    paddingLeft: 56,
    flexDirection: 'column',
  },
  listItem: {
    paddingLeft: '10px',
    fontSize: '20px',
    opacity: '0.8',
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#535B65',
    textTransform: 'uppercase',
    padding: '40px 20px 15px',
  },
  para: {
    fontSize: 20,
    fontWeight: 400,
    color: '#535B65',
  },
}));

const units = {
  calories: 'kcal',
  fat: 'gms',
  carbs: 'gms',
  protein: 'gms',
  sugars: 'gms',
  sodium: 'mg'
};

const Accordion = withStyles({
  root: {
    border: '1px solid #D7D7D7',
    boxShadow: 'none',
    marginBottom: 56,
    borderRadius: '20px 8px 20px 0!important',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: '56px!important',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    padding: '0px 64px',
  },

  expanded: {},
})(MuiAccordionSummary);

const ContentInfo = ({ product }) => {
  const classes = useStyles();
  const { ingredients, nutrition, serves } = product;

  return (
    <div className={classes.main}>
      <Container fixed>
        <Grid item xs={12} sm={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ingredients-content"
              id="ingredients">
              <Typography variant={'body2'} className={classes.heading}>
                <img
                  src={IngredientIcon}
                  alt="Ingredients"
                  className={classes.icon}
                />
                Ingredients
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.list}>
              <ul style={{ listStyleType: 'circle' }}>
                {ingredients
                  ? Object.keys(ingredients).map((item, idx) => (
                    <li key={idx} className={classes.listItem}>
                      <Typography
                        variant={'body2'}
                        className={classes.para}
                        key={idx}>
                        {item}
                      </Typography>
                    </li>
                  ))
                  : null}
              </ul>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="nutrition-content"
              id="nutrition">
              <Typography variant={'body2'} className={classes.heading}>
                <img
                  src={NutritionIcon}
                  alt="Nutrition"
                  className={classes.icon}
                />
                Nutritional Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.list}>
              <Typography variant={'body2'} className={classes.title}>
                Serving size : {serves} | Values below are per serving
              </Typography>
              <ul style={{ listStyleType: 'circle' }}>
                {nutrition
                  ? Object.keys(nutrition).map((item, idx) =>
                    item.length ? (
                      <li className={classes.listItem} key={idx}>
                        <Typography
                          variant={'body2'}
                          className={classes.para}>
                          {`${item} ....................... ${nutrition[item]} ${units[item]}`}
                        </Typography>
                      </li>
                    ) : null
                  )
                  : null}
              </ul>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Container>
    </div>
  );
};

export default ContentInfo;
