import React, { useState } from 'react';
import PlanCard from './planCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import AlertDialog from '../../components/AlertDialog';


const HeroSubscribe = ({ subscriptions }) => {
  const dispatch = useDispatch();
  let cart = useSelector((state) => state.cart);

  const [alert, setAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [receivedItem, setReceivedItem] = useState('');
  const [receivedOperation, setReceivedOperation] = useState('');

  const addToCart = (item) => {
    dispatch(
      CartActions.addToCart({
        ...item,
        type: PRODUCT_TYPE.SUBSCRIPTION,
        redirect: true,
      })
    );
  };

  const dialogHandler = (item) => {
    const { products, snacks } = cart;
    if (products.length > 0 || snacks.length > 0) {
      setReceivedItem(item);
      setAlert(true);
    } else {
      addToCart(item);
    }
  };


  const updateCart = (item, operation) => {
    dispatch(
      CartActions.updateCart({
        item: { ...item, type: PRODUCT_TYPE.SUBSCRIPTION },
        operation,
      })
    );
  };

  const updateDialogHandler = (item, operation) => {
    const { products, snacks } = cart;
    if (products.length > 0 || snacks.length > 0) {
      setReceivedItem(item);
      setReceivedOperation(operation)
      setUpdateAlert(true);
    } else {
      updateCart(item, operation);
    }
  }



  return (
    <div style={{ padding: '80px 0' }}>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 40,
                lineHeight: '56px',
                fontFamily: 'HappySchool',
              }}>
              Join the Orange Gang. Now!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '28px',
                marginBottom: 20,
              }}>
              Select a membership and get started. Expect fun & learning with
              our baking subscription kits every week.
            </Typography>
          </Grid>
          {subscriptions && subscriptions.length
            ? subscriptions.map((item, index) => (
              <PlanCard
                key={index}
                details={item}
                addToCartHandler={dialogHandler}
                updateCart={updateDialogHandler}
              />
            ))
            : null}

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={8}>
              <Typography
                variant={'h2'}
                style={{
                  color: '#535B65',
                  fontSize: 20,
                  fontWeight: 400,
                  lineHeight: '28px',
                  textAlign: 'center',
                  marginTop: 80,
                }}>
                Shipping and taxes as applicable, will be calculated at
                checkout. Subscriptions renew automatically as per the billing
                cycle and can be cancelled anytime.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AlertDialog
        open={alert}
        title="Items in cart."
        content="Your cart contains items which will be replaced when you add a subscription. Do you wish to reset your cart before proceeding to subscribe?"
        handleClose={() => setAlert(false)}
        handleAgree={() => addToCart(receivedItem)}
      />

      <AlertDialog
        open={updateAlert}
        title="Items in cart."
        content="Your cart contains items which will be replaced when you add a subscription. Do you wish to reset your cart before proceeding to subscribe?"
        handleClose={() => setUpdateAlert(false)}
        handleAgree={() => { dispatch(CartActions.resetCart()); updateCart(receivedItem, receivedOperation) }}
      />
    </div>
  );
};

export default HeroSubscribe;
