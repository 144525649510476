import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    borderRadius: '40px',
    padding: '12px 47px',
    fontSize: '20px',
    color: colors.primary.main,
    backgroundColor: '#fff!important',
    border: '1px solid' + colors.info.lighter,
  },
}));

const CommonCta = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundColor: colors.info.lighter,
        textAlign: 'center',
        padding: '70px 0',
      }}>
      <Container fixed>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Typography
              variant={'h2'}
              style={{
                color: '#fff',
                fontSize: 40,
                lineHeight: '56px',
                fontFamily: 'HappySchool',
              }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
            <MuiThemeProvider theme={buttonFont}>
              <Link to={props.onClick} variant={'h2'}>
                <Button
                  theme
                  className={classes.btnSubmit}
                  variant={'outlined'}>
                  {props.btnText}
                </Button>
              </Link>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CommonCta;
