import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../theme/colors';
import EatingIcon from '../../assets/images/responsible-eating.png';
import CreativityIcon from '../../assets/images/nurture-creativity.svg';
import ChefIcon from '../../assets/images/ico-chef.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 72,
    width: 'auto',
  },
  heading: {
    color: colors.common.white,
    fontSize: 56,
    lineHeight: '72px',
    fontFamily: 'HappySchool',
    marginBottom: 36,
    textAlign: 'center',
  },
  title: {
    color: colors.common.white,
    fontSize: 24,
    lineHeight: '28px',
    fontFamily: 'HappySchool',
    marginBottom: 10,
    textAlign: 'center',
  },
  desc: {
    color: colors.common.white,
    fontSize: 20,
    lineHeight: '28px',
    marginBottom: 10,
    textAlign: 'center',
    padding: '0 50px',
  },
}));

const Benefits = () => {
  const classes = useStyles();

  return (
    <div className="benefits-bg">
      <Container fixed>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography variant={'h2'} className={classes.heading}>
              Bring home the fun & learning
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img
              src={EatingIcon}
              alt={'Responsible Eating'}
              className={classes.icon}
            />
            <Typography variant={'h2'} className={classes.title}>
              Encourage Responsible Eating
            </Typography>
            <Typography variant={'body1'} className={classes.desc}>
              Kids learn to be nutritionally aware, develop healthy eating
              habits, practice kitchen hygiene, be conscious of the food source
              and mindful of the wastage.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img
              src={ChefIcon}
              alt={'Recipes'}
              className={classes.icon}
            />
            <Typography variant={'h2'} className={classes.title}>
              Chef Designed Recipes
            </Typography>
            <Typography variant={'body1'} className={classes.desc}>
              Perfectly portioned and suitably designed to make it easy & fun
              for both kids & adults. Let your kid focus on learning the
              technique, the rest is taken care of.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img
              src={CreativityIcon}
              alt={'Creativity'}
              className={classes.icon}
            />
            <Typography variant={'h2'} className={classes.title}>
              Nurture Your Kid’s Creativity
            </Typography>
            <Typography variant={'body1'} className={classes.desc}>
              A delicious way to explore your kid’s untapped potential and
              unlock their creativity while helping them learn how to better
              communicate & work as a team.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Benefits;
