import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FAF7F3',
    textAlign: 'center',
    borderRadius: '20px 8px 8px 8px',
    margin: '20px 0',
    maxWidth: 328,

    '&:hover': {
      boxShadow: '0 0 50px 0 rgba(0,0,0,0.1)',
    },

    '&:hover :last-child': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  label: {
    backgroundColor: '#FF8256',
    padding: '12px 30px',
    position: 'absolute',
    borderRadius: '20px 0 20px 0',
    color: '#fff',
    fontWeight: 500,
    fontSize: 16,
    margin: 8,
  },
  kitImg: {
    height: 219,
    width: '100%',
    borderRadius: '20px 8px 0 0',
  },
  kitName: {
    fontSize: 28,
    lineHeight: '36px',
    color: '#535B65',
    opacity: 0.9,
    height: 72,
    padding: '30px 20px 10px',
  },
  price: {
    fontSize: 24,
    lineHeight: '36px',
    color: '#535B65',
    fontWeight: 500,
    padding: 24,
  },
  serving: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#535B65',
  },
  btnOnHover: {
    position: 'absolute',
    maxWidth: 328,
    maxHeight: 442,
    height: '219px',
    alignItems: 'center!important',
    justifyContent: 'center',
    background: 'rgba(250, 247, 243, 0.8)',
    padding: '0 41px',
    borderRadius: '20px 8px 0 0',
    display: 'none',
  },
  btnAddToCart: {
    borderRadius: '24px',
    padding: '12px 78px',
    fontSize: '16px',
    color: '#ffffff',
  },
  bgDisabled: {
    backgroundColor: '#FA4A38!important',
    opacity: 0.6,
    borderRadius: '24px',
    padding: '12px 78px',
    fontSize: '16px',
    color: '#ffffff!important',
  },
});

const Kit = ({ details, addToCartHandler }) => {
  const classes = useStyles();


  const dispatch = useDispatch()
  return (
    <div className={classes.root}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        direction="row"
        justify="center"
        alignItems="center">
        <div>
          {/*{a ? (*/}
          {/*  details.label === 'New kit' ? (*/}
          {/*    <Typography variant="body2" className={classes.label}>*/}
          {/*      New*/}
          {/*    </Typography>*/}
          {/*  ) : (*/}
          {/*    <Typography*/}
          {/*      variant="body2"*/}
          {/*      className={classes.label}*/}
          {/*      style={{ backgroundColor: '#FCAB52' }}>*/}
          {/*      sold out*/}
          {/*    </Typography>*/}
          {/*  )*/}
          {/*) : null}*/}
          <img src={details.image} alt="Kit" className={classes.kitImg} />
          <Link
            to={`baking-kits/products/${details.slug}`}
            onClick={() => {
              dispatch({ type: GlobalActions.setCurrentKit.SUCCESS, payload: details })
            }}
            style={{
              color: '#535B65',
              textDecoration: 'none',
              display: 'block',
            }}>
            <Typography variant="h2" className={classes.kitName}>
              {details.name}
            </Typography>
            <Typography variant="body2" className={classes.serving}>
              Serves {details.serves} | {details.nutrition.calories}{' '}
              kcal/serving
            </Typography>
            <Typography variant="body2" className={classes.price}>
              ₹ {details.price}
              {/*<span style={{ fontSize: 14 }}> + taxes</span>*/}
            </Typography>
          </Link>
        </div>
        <div className={classes.btnOnHover}>
          {details.label === 'Sold out' ? (
            <MuiThemeProvider theme={buttonFont}>
              <Button
                theme
                color={'primary'}
                disableElevation
                fullWidth
                disabled
                className={classes.bgDisabled}
                variant={'contained'}>
                Add to cart
              </Button>
            </MuiThemeProvider>
          ) : (
              <MuiThemeProvider theme={buttonFont}>
                <Button
                  theme
                  color={'primary'}
                  disableElevation
                  fullWidth
                  className={classes.btnAddToCart}
                  onClick={() => addToCartHandler(details)}
                  variant={'contained'}>
                  Add to cart
              </Button>
              </MuiThemeProvider>
            )}
        </div>
      </Grid>
    </div>
  );
};

export default Kit;
