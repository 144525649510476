import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const Alert = ({ severity, children, className }) => {
  const classes = useStyles();

  return (
    <Grid className={className} container>
      <Grid container justify="center" item xs={1}>
        {severity === 'success' && (
          <CheckCircleOutlineIcon className={classes.success} />
        )}
        {severity === 'error' && <ErrorOutlineIcon className={classes.error} />}
      </Grid>
      <Grid item xs={11}>
        <Typography>{children}</Typography>
      </Grid>
    </Grid>
  );
};

export default Alert;
