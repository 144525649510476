import { createAction } from '../../redux/actionSaga';

export const CartActionTypes = {
  ADD_TO_CART: 'CART/ADD',
  REMOVE_FROM_CART: 'CART/REMOVE',
  UPDATE_CART: 'CART/UPDATE',
  RESET_CART: 'CART/RESET',
  SET_CART: 'CART/SET',
};

const CartActions = {
  updateCart: createAction(CartActionTypes.UPDATE_CART),
  addToCart: createAction(CartActionTypes.ADD_TO_CART),
  removeFromCart: createAction(CartActionTypes.REMOVE_FROM_CART),
  resetCart: createAction(CartActionTypes.RESET_CART),
  setCart: createAction(CartActionTypes.SET_CART),
};

const initialState = {
  loading: false,
  products: [],
  subscriptions: [],
  snacks: [],
  grandTotal: 0,
  shippingTotal: 0,
  tax: 0,
  subTotal: 0,
};

export const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CartActions.addToCart.REQUEST:
    case CartActions.removeFromCart.REQUEST:
    case CartActions.updateCart.REQUEST:
    case CartActions.setCart.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CartActions.addToCart.SUCCESS:
    case CartActions.removeFromCart.SUCCESS:
    case CartActions.setCart.SUCCESS:
    case CartActions.updateCart.SUCCESS: {
      const { subscriptions = [], products = [], snacks = [] } = action.payload;

      const subscriptionTotal = subscriptions.length
        ? subscriptions.reduce((a, b) => {
            return a + (b.price - (b.discount_price ? b.discount_price : 0)) * b.qty;
          }, 0)
        : 0;
      const productsTotal = products.length
        ? products.reduce((a, b) => {
            return a + b.price * b.qty;
          }, 0)
        : 0;
      const snacksTotal = snacks.length
        ? snacks.reduce((a, b) => {
            return a + b.price * b.qty;
          }, 0)
        : 0;

      // Shipping
      const kitsShippingTotal = products.length
        ? products.reduce((a, b) => {
            // return a * b.qty;
            return 200;
          }, 38)
        : 0;
      const snacksShippingTotal = snacks.length
        ? snacks.reduce((a, b) => {
            // return a * b.qty;
            return 200;
          }, 38)
        : 0;
      const subscriptionShippingTotal = subscriptions.length
        ? subscriptions.reduce((a, b) => {
            // return a * b.qty * b.period;
            return 200;
          }, 62)
        : 0;

      const tax = (subscriptionTotal +
        productsTotal) * 0.18;

      return {
        ...state,
        subscriptions,
        products,
        snacks,
        shippingTotal: kitsShippingTotal+snacksShippingTotal+subscriptionShippingTotal,
        subTotal: subscriptionTotal +
          productsTotal +
          snacksTotal,
        grandTotal:
          subscriptionTotal +
          productsTotal +
          snacksTotal +
          kitsShippingTotal +
          snacksShippingTotal +
          subscriptionShippingTotal + tax,
        tax,
        loading: false,
      };
    }
    case CartActions.addToCart.FAILURE:
    case CartActions.removeFromCart.FAILURE:
    case CartActions.updateCart.FAILURE:
    case CartActions.setCart.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CartActions.resetCart.REQUEST:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
};

export default CartActions;
