import React,{useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField/TextField';
import moment from 'moment';
import firebase from 'firebase/app'
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Andes',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let ingredients = [];
let Recipes = [];
let NotIncluded = [];

const  AddProducts  = withStyles(styles)((props)=> {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    const { children, classes, onClose, ...other } = props;
    
  const [name,setName]= React.useState("");
  const [price,setPrice]= React.useState();
  const [createdDate,setCreatedDate]= React.useState();
  const [slug, setSlug] = React.useState();
  const [month,setMonth]= React.useState("");
  const [bakeTime,setBakeTime]= React.useState("");
  const [trailerVideo,settrailerVideo]= React.useState();
  const [serves, setServes] = React.useState();
  const [recipeVideoUrl, setrecipeVideoUrl] = React.useState();
  const [description, setDescription] = React.useState(); 
  const [imageDetails, setimageDetails] = React.useState();
  const [storage, setStorage] = React.useState();
  const [shelfLife, setshelfLife] = React.useState();
  const [Qty,setQty]= React.useState("")
  const [Ingredients_arr, setInIngredients_arr] = React.useState(1);
  const [Recipes_arr,setRecipes_arr] = React.useState(1);




  const handleProduct = ()=>{

    const newProduct= {   
          availability:"true",
          name:name||" ",
          price:price||" ",
            slug:slug||" ",
            month:month||" ",
            bakeTime:bakeTime||" ",
            trailerVideo:trailerVideo||" ",
            serves:serves||" ",
            description:description||" ",
            imageDetails:imageDetails||" ",
            recipeVideoUrl:recipeVideoUrl||" ",
          storage:storage||" ",
          shelfLife:shelfLife||" ",
      }
      handleClose();
       props.handleProduct(newProduct);
      
  }



  const clearFields = ()=>{
   
  }

  const verifydetails = () => {
    return true;
  };
  
  const addReceipeItems = (row,event,item) => {
    Recipes[row][item] = event.target.value;
    console.log(Recipes);
  }

    const checkDateConstraint = ()=>{
      
 
  
    }
  
  const onFileChange = async (event )=> {
    
    try {

       // Update the state
    
      let fileUpload = event.target.files[0];
      console.log("fileUpload----", fileUpload);
      let storageRef = firebase.storage().ref('/kit_images/'+fileUpload.name.toString())

   // upload the first file only
      let uploadTask = await storageRef.put(fileUpload);
      const downloadURL = await uploadTask.ref.getDownloadURL();
      console.log("downloadURL----", downloadURL);

      
    } catch (error) {
      console.log("err--",error)
      
    }
   
  
  };
  
    

  return (
    <div style={{paddingTop:"0px",marginLeft:"1em"}}>
        <Button className="btn1 topbtn" onClick={
          ()=>{
            handleClickOpen();
            clearFields();
        }}
        startIcon={<AddIcon style ={{color: 'white'}}/>}
        >
            ADD PRODUCTS 
              </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{color:"#B52A65"}}>
         Add PRODUCT
        </DialogTitle>     
        <DialogContent dividers>
        Name*
        <Grid item xs={12} lg={12} md={12} style={{maxWidth:"88%"}}>
              
            <input type="file" onChange={onFileChange} />
              </Grid>
             
          Ingredients*  <Button onClick={()=>{setRecipes_arr(Recipes_arr+1)}}>add extra row</Button>
     
            {
             [...Array(Recipes_arr)] .map((item,idx) => {
               return (
                 <>
                   <div className="row" style={{ marginLeft: "10px" }}>
          
                      <Grid item xs={6} lg={6} md={6}>
               <div>Item</div> 
              <TextField            
                label="Item"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={price}
                onChange={(event) => setPrice(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
                      <Grid item xs={6} lg={6} md={6}>
               <div>Qty</div> 
              <TextField 
             
                label="Qty"
                variant="outlined"
                type="number"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={Qty}
                onChange={(event) => setQty(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
                  </>
                )
              })
          }

      
          
        <Grid item xs={12} lg={12} md={12} style={{maxWidth:"88%"}}>
              <TextField
                fullWidth
                label="Recepies"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={name}
                onChange={(event) => setName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
    
          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>price</div> 
              <TextField 
             
                label="price"
                variant="outlined"
                type="number"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={price}
                onChange={(event) => setPrice(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>createdDate</div> 
              <TextField 
             
                label="createdDate"
                variant="outlined"
                type="date"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={createdDate}
                onChange={(event) => setCreatedDate(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>month</div> 
              <TextField 
             
                label="month"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={month}
                onChange={(event) => setMonth(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>slug</div> 
              <TextField 
             
                label="slug"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={slug}
                onChange={(event) => setSlug(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
    
          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>bakeTime</div> 
              <TextField 
             
                label="bakeTime"
                variant="outlined"
                type="number"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={bakeTime}
                onChange={(event) => setBakeTime(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>trailerVideo</div> 
              <TextField 
             
                label="trailerVideo"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={trailerVideo}
                onChange={(event) => settrailerVideo(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>

          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>serves</div> 
              <TextField 
             
                label="serves"
                variant="outlined"
                type="number"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={serves}
                onChange={(event) => setServes(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>description</div> 
              <TextField 
             
                label="description"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
        
          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>imageDetails</div> 
              <TextField 
             
                label="imageDetails"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={imageDetails}
                onChange={(event) => setimageDetails(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>recipeVideoUrl</div> 
              <TextField 
             
                label="Recipe Video URL"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={recipeVideoUrl}
                onChange={(event) => setrecipeVideoUrl(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
          
          <div className="row" style={{ marginLeft: "10px" }}>
          
          <Grid item xs={6} lg={6} md={6}>
               <div>storage</div> 
              <TextField 
             
                label="storage"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={storage}
                onChange={(event) => setStorage(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
            <Grid item xs={6} lg={6} md={6}>
               <div>shelfLife</div> 
              <TextField 
             
                label="shelf Life"
                variant="outlined"
                type="text"
                style={{ margin: '12px 0' ,height:"80%",width:"70%"}}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={shelfLife}
                onChange={(event) => setshelfLife(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
                       
            </Grid>
         
          </div>
       
       
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} >
            CANCEL
          </Button>
          <Button     
          autoFocus onClick={handleProduct}  
         
          style={  verifydetails()=== false? {background:"#B52A65",color:"white"}:{background:"#B52A65",color:"white",opacity:"0.5"}}>
            ADD 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});


export default  AddProducts;