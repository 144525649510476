import React, { useEffect, useState } from 'react';
import CommonCta from '../../components/Cta';
import ContactUs from '../../components/ContactUs';
import Layout from '../../components/Layout';
import KitContents from '../Subscribe/kitContents';
import ContentInfo from './contentInfo';
import ProductDetailsHero from './hero';
import { fetchProductDetailsBySlug } from '../../operations/products';
import { useParams } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';

const ProductDetails = () => {
  const { slug } = useParams();

  const [product, setProduct] = useState({});

  const getProductDetails = async (slug) => {
    const result = await fetchProductDetailsBySlug(slug);
    setProduct(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getProductDetails(slug);
    }
    fetchData();
  }, [slug]);


  return (
    <Layout navbar={true} whiteBG={true}>
      <ProductDetailsHero product={product} />
      <ContentInfo product={product} />
      <KitContents />
      <CommonCta
        title={'Bake often?'}
        btnText={'Subscribe & save'}
        onClick={Paths.Subscribe}
      />
      <ContactUs />
    </Layout>
  );
};

export default ProductDetails;
