import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails/AccordionDetails';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#fff',
    padding: '80px 0',
    borderRadius: '100px',
  },
  heading: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#535B65',
  },
  para: {
    fontSize: 20,
    fontWeight: 400,
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid #D7D7D7',
    boxShadow: 'none',
    padding: 20,
    marginBottom: 16,
    borderRadius: '8px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: 16,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
  },

  expanded: {},
})(MuiAccordionSummary);

const faqs = [
  {
    id: 'que1',
    question: 'So, how does the subscription work?',
    answer:
      'It’s simple, you Sign up for an Orange Figs subscription plan, and you’ll get all 4 baking kits every month in one go,  popping  with fun recipes for tasty treats, recipe cards, fun baking tools, aprons, and even cool cute stickers to stick around: all in  your easy-peasy baking kits, delivered right to your home. .\n' +
      'Finally, we have something fun, some learning, and lots of memories in our boxes made just for kids, perfect for our little bakers, dessert -lovers, and great family time.',
  },
  {
    id: 'que2',
    question: 'What is the age group Orange Figs Baking Kits are meant for?',
    answer:
      'We say you can’t ever be too young to get in the kitchen but 5+ under parental guidance is recommended, or at least until you parents have you some master chefs who’ll be teaching you how to bake.',
  },
  {
    id: 'que3',
    question:
      'Are the kits the same every month? Can we change the kits we want?',
    answer:
      'Of course not!! You’ll get super different yet so very super tasty recipes to bake every month!!! You can get in touch with us if you want a custom set of recipes sent over to you, but once we ship them to you, return will not happen, unless there’s some other problem.',
  },
  {
    id: 'que4',
    question: 'Can I order my favourite kit separately?',
    answer:
      'Yes you can, just go shop  one time baking kits and order as many as you like. ',
  },
  {
    id: 'que5',
    question: 'The ingredients have gotten hard, is that normal?',
    answer:
      'Totally! And don’t worry about nothing as all ingredients are vacuum packed and sealed, which can make some ingredients feel a little hard. Just open the pack and shake it up, your ingredients will be fresh and fun in a jiffy.',
  },
  {
    id: 'que6',
    question:
      'Will the kids need any additional ingredients apart from those in the kit?',
    answer:
      'All non perishable goods will be supplied. Ingredients like eggs or milk (perishable) will need to be gotten from your kitchen, and an oven to bake ( sorry we can’t send this ☺)',
  },
  {
    id: 'que7',
    question: 'Are there any vegan or gluten free options?',
    answer:
      'We’re really sorry for this but for now, we don’t have these options as of now but we’re working on it, and we promise we’ll have something for you in no time.',
  },
];

const SubscriptionFAQ = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Container fixed>
        {faqs.map((item) => (
          <Accordion key={item.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.id}-content`}
              id={item.id}>
              <Typography variant={'body2'} className={classes.heading}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={'body2'} className={classes.para}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </div>
  );
};

export default SubscriptionFAQ;
