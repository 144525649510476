
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import firebase from 'firebase/app'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import minusIcon from "../../../assets/images/minusIcon.png"
import addIcon from "../../../assets/images/addicon.png"
import { months } from '../../../utils/index';
// import { months } from '../../../';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { addNewProduct } from "../../../operations/products"
import preview from "../../../assets/images/photoPreview.png"


const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        minHeight: "75%",
    
    
    }
});

let data = [];

export default function AddNewProduct(props) {
    const classes = useStyles();


    const [name, setName] = React.useState("");
    const [price, setPrice] = React.useState();
    const [month, setMonth] = React.useState("January");
    const [bakeTime, setBakeTime] = React.useState("");
    const [trailerVideo, settrailerVideo] = React.useState();
    const [serves, setServes] = React.useState();
    const [recipeVideoUrl, setrecipeVideoUrl] = React.useState();
    const [description, setDescription] = React.useState();
    const [imageDetails, setimageDetails] = React.useState();
    const [shelfLife, setshelfLife] = React.useState();
    const [counter, setCounter] = React.useState(0);
    const [Ingredients_arr, setInIngredients_arr] = React.useState([
        {
            Ingredient: "",
            quantity: "",
            Measurement: "",
        }
    ]);
    const [Recipes_arr, setRecipes_arr] = React.useState([
        {
            available: "",
            step: "",
            video: "",
        }
    ]);
    const [IngredientsNotIncluded, setIngredientsNotIncluded] = React.useState([
        {
            Ingredient: "",
            quantity: "",
            Measurement: "",
        }
    ]);
    const [Nutritions, setNutritions] = React.useState([
        {
            name: "",
            quantity: "",
        }
    ]);
    const [error, SetError] = React.useState(false);
    const [imgPreview, setimgPreview] = React.useState(preview);
    const [img, setImg] = React.useState();

    const validation = async () => {
        let result = await verifydetails();
        console.log("result---", result);
        if (!result) {
            SetError(false);
            const result = await uploadPicture();
            AddProduct(result);
            
        }
        else SetError(true);
    }

    const verifydetails = () => {
        // console.log("Nutritions--",    Nutritions[0].name.length ,Nutritions[0].quantity.length );
        return (
            !name ||
            !price ||
            !trailerVideo ||
            !recipeVideoUrl ||
            !description ||
            !Nutritions.length ||
            !Recipes_arr.length ||
            !IngredientsNotIncluded.length ||
            !Ingredients_arr.length ||
            !Nutritions.every(VerifyNutirions) ||
            !Recipes_arr.every(VerifyRecipes) ||
            !Ingredients_arr.every(Verifyingredients) ||
            !IngredientsNotIncluded.every(Verifyingredients)
        )

    }

    const Verifyingredients = (item) => {

        return item.Ingredient.length && item.quantity && item.Measurement.length

    }
    const VerifyRecipes = (Recipe) => {
        return Recipe.step.length

    }
    const VerifyNutirions = (Nutritions) => {
        return Nutritions.name.length && Nutritions.quantity
    }

    const addIgredient = () => {

        Ingredients_arr.push({
            Ingredient: "",
            quantity: "",
            Measurement: "",
        });

        setCounter(counter + 1);
    };

    const addNutrition = () => {
        Nutritions.push({
            name: "",
            quantity: "",
            measurement: "",
        });
        setCounter(counter + 1);
    };
    const addIgredientNotincluded = () => {

        IngredientsNotIncluded.push({
            Ingredient: "",
            quantity: "",
            Measurement: "",
        });

        setCounter(counter + 1);
    };

    const addRecipes = () => {

        Recipes_arr.push({
            Available: "",
            description: "",
            StepVideo: "",
        });

        setCounter(counter + 1);
    };

    const updateNutritionChange = (name, index) => (event) => {
        let newArr = Nutritions.map((item, i) => {
            if (index == i) {
                return { ...item, [name]: event.target.value };
            } else {
                return item;
            }
        });

        setNutritions(newArr);
        setCounter(counter + 1);
    };

    const deleteNutrition = (index) => {
        let newArr = Nutritions.filter((item, i) => {
            if (index == i) {
                return false;
            } else {
                return true;
            }
        });

        setNutritions(newArr);
        setCounter(counter + 1);
    };

    const updateIngredientsChange = (name, index) => (event) => {
        let newArr = Ingredients_arr.map((item, i) => {
            if (index == i) {
                return { ...item, [name]: event.target.value };
            } else {
                return item;
            }
        });

        setInIngredients_arr(newArr);
        setCounter(counter + 1);
    };

    const deleteIngredients = (index) => {
        let newArr = Ingredients_arr.filter((item, i) => {
            if (index == i) {
                return false;
            } else {
                return true;
            }
        });

        setInIngredients_arr(newArr);
        setCounter(counter + 1);

    };

    const updateRecipesChange = (name, index) => (event) => {
        let newArr = Recipes_arr.map((item, i) => {
            if (index == i) {
                return { ...item, [name]: event.target.value };
            } else {
                return item;
            }
        });

        setRecipes_arr(newArr);
        setCounter(counter + 1);

    };

    const deleteRecipes = (index) => {
        let newArr = Recipes_arr.filter((item, i) => {
            if (index == i) {
                return false;
            } else {
                return true;
            }
        });

        setRecipes_arr(newArr);
        setCounter(counter + 1);

    };

    const updateNotIncludedIngredients = (name, index) => (event) => {
        let newArr = IngredientsNotIncluded.map((item, i) => {
            if (index == i) {
                return { ...item, [name]: event.target.value };
            } else {
                return item;
            }
        });

        setIngredientsNotIncluded(newArr);
        setCounter(counter + 1);

    };

    const deleteIngredientsNotIncluded = (index) => {
        let newArr = IngredientsNotIncluded.filter((item, i) => {
            if (index == i) {
                return false;
            } else {
                return true;
            }
        });

        setIngredientsNotIncluded(newArr);
        setCounter(counter + 1);

    };

    const AddProduct = async (imgURL) => {


        let nutrition = {};
        let ingredients = {};
        let notIncluded = {};

        Nutritions.map((item) => {
            nutrition[item.name] = item.quantity;
        });

        Ingredients_arr.map((item) => {
            ingredients[item.Ingredient] = item.quantity + " " + item.Measurement;
        });

        IngredientsNotIncluded.map((item) => {
            notIncluded[item.Ingredient] = item.quantity + " " + item.Measurement;
        });
        let slug = name.replaceAll(" ", "-");
        let imageDetails = imgURL;
        let web_image = imgURL;
        let image = imgURL;
        let obj = {
            availability: "true",
            name,
            price,
            bakeTime,
            trailerVideo,
            trailerVideoUrl: `https://youtu.be/${trailerVideo}`,
            serves,
            recipeVideoUrl: `https://youtu.be/${recipeVideoUrl}`,
            recipeVideo: recipeVideoUrl, 
            description,
            imageDetails,
            recipe: Recipes_arr,
            slug,
            shelfLife,
            web_image,
            image,
            imageDetails,
            nutrition,
            ingredients,
            notIncluded,
            month: months[month]
        }

        await addNewProduct(obj).then(props.close());


    }

    const uploadPicture = async () => {
    
        let fileUpload = img;
        console.log("fileUpload----", fileUpload);
        
        let storageRef = firebase.storage().ref('/kit_images/' + fileUpload.name.toString())

        // upload the first file only
        let uploadTask = await storageRef.put(fileUpload);
        const downloadURL = await uploadTask.ref.getDownloadURL();
        console.log("downloadURL----", downloadURL);
        setimageDetails({downloadURL});
    
        return downloadURL;
       
}



    const onFileChange = async (event) => {

        try {
    
            //
    
       
            if (event.target.files && event.target.files[0]) {
              setImg(event.target.files[0]);
            //   setImageChanged(true);
                  let reader = new FileReader();
                  reader.onload = (e) => {
                      
                      setimgPreview(e.target.result);
                  };
                  reader.readAsDataURL(event.target.files[0]);
                }
         
    
            // Update the state
    
           
    
        } catch (error) {
            console.log("err--", error)
    
        }
    
    };



    return (

        <>
        <div className="tab">
            <Grid
                    spacing={3}
                    container
                alignItems="center"
                style={{ margin: "3px 3px", maxWidth: "100%", height: "7.5%" }}>
                <Grid item
                    alignItems="center"
                    className="containerHeading">
            Add Product
          </Grid>
                    </Grid>
            <Paper style={{ maxHeight: "85%", overflow: 'auto' }}>
                <Grid
                    spacing={3}
                    container
                    style={{ margin: "20px 30px", maxWidth: "70%" }}>

                    <Grid item xs={6} lg={6} md={6} >
                        <div style={{ padding: "6px",fontSize: "1rem",
                                        fontFamily: "Roboto",
                                fontWeight: "500",
                            color:"#424547"}}>NAME</div>
                        <TextField

                            label="Name"
                            variant="outlined"
                            type="text"
                            style={{ height: "80%", width: "100%" }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                },
                            }}
                            value={name}
                                onChange={(event) => setName(event.target.value)}
                                error={error&&!name}
                                helperText={error&&!name&&"Please Enter the Field"}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                        />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                      
                            <div style={{marginTop:"12px"}} >
                            <img
                                style={{minWidth:"100px",minHeight:"100px",maxHeight:"100px",borderRadius:"5px"}} 
                            
                                src={imgPreview} alt="preview"/>

                        </div>
                            
      
                    </Grid>

                    <Grid item xs={3} lg={3} md={3}>
                        <div style={{ padding: "6px",fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547" }}> IMAGE</div>
                        
                        <div style={{ height:"60%"}}>
      <input                                 
        accept="image/png, image/gif, image/jpeg"
        onChange={onFileChange} 
        id="contained-button-file"
        multiple
                                type="file"
                                style={{ display:"none"}}
      />
                            <label htmlFor="contained-button-file"
                          style={{ height:"100%"}} >
        <Button variant="raised" component="span" className="btn1"  style={{ height: "100%", padding: "0px 30px" }}>
          Upload Image
                                    </Button>
                                    <Typography
                                    style={{ color:"#424547",opacity:"0.5",fontSize:"12px",fontWeight:"400"}}
                                    >Max. file size 500 kb</Typography>
      </label>
    </div>
                            {/* <Button className="btn1"
                            style={{ height: "60%", padding: "0px 30px" }}
                            variant="contained" color="primary" component="span"
                        >
                            Upload Image</Button> */}
      
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <div style={{ padding: "6px" ,fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547"}}> DESCRIPTION </div>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            style={{ width: "100%" }}
                                variant="outlined"
                                
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                },
                            }}
                                value={description}
                                error={error&&!description}
                                helperText={error&&!description&&"Please Enter the Field"}
                            onChange={(event) => setDescription(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px" ,fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547"}}>PRICE</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                style={{ width: "100%" }}
                                required
                                value={price}
                                error={error&&!price}
                                helperText={error&&!price&&"Please Enter the Field"}
                                className={classes.end}
                            onChange={(event) => setPrice(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                    borderRight:"1px solid #555"
                                },
                               
                            }}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                        <div >Rs</div>
                                  </InputAdornment>
                                ),
                              }}
                                endAdornment={<InputAdornment position="end">
                                {/* // style={{ background:"blue",color:"red"}} */}
                                Rs       
                                </InputAdornment>}
                         
                        />

                    </Grid>
                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px",fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547" }}>BAKE TIME</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                style={{ width: "100%" }}
                                className={classes.end}
                            placeholder="Bake Time"
                                value={bakeTime}                                                  
                            onChange={(event) => setBakeTime(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                        <div >Minutes</div>
                                  </InputAdornment>
                                ),
                              }}
                            endAdornment={<InputAdornment position="end"
                                style={{
                                fontFamily:"Roboto",
                            }}
                            >Minutes</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"

                            labelWidth={0}
                        />

                    </Grid>
                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px",fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547" }}>SERVES</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                            placeholder={"No. of People"}
                            style={{ width: "100%" }}
                                // value={serves}
                                // error={error&&!serves}
                                // helperText="Please Enter the Field"
                           
                            onChange={(event) => setServes(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"

                            labelWidth={0}
                        />

                    </Grid>
                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px" ,fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547"}}>SHELF LIFE</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                            placeholder="SHELF LIFE"
                            style={{ width: "100%" }}
                                value={shelfLife}
                               
                            onChange={(event) => setshelfLife(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                             aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />

                        </Grid>
                        <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px",fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547" }}>MONTH</div>
                            <FormControl variant="outlined"
                                style={{width:"100%"}}
                                className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel> */}
        <Select
          labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                      }}
                                    value={month}
                                    error={error&&!month}
                                    helperText={error&&!month&&"Please Enter the Field"}
                               
                                    style={{width:"100%"}}
          onChange={(event) => setMonth(event.target.value)}
        //   label="Age"
        >
          <MenuItem value="">
            <em>None</em>
                                    </MenuItem>
                                    {
[
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
                                        ].map((item, index1) => {
                                            return(
                                            <MenuItem value={index1}>{item}</MenuItem>
 )})
                                    }
          

        </Select>
      </FormControl>

                    </Grid>
                   
                        <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ background: "#F6EFE6" }}
                            >
                                <Typography >Ingredients (Included)</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                
                                <Grid container fixed spacing={3} style={{ width: "100%" }}>
                                    {
                                        Ingredients_arr.map((item, index) => {
                                            
                                            return (
                                                

                                                <>
                                                    <Grid item xs={5} lg={5} md={5} style={{ width: "100%" }}>
                                                        <TextField
                                                            id="outlined-helperText"
                                                            variant="outlined"
                                                            placeholder={"Ingredient"}
                                                            style={{ width: "100%" }}
                                                            value={item.Ingredient}
                                                            error={error&&!item.Ingredient}
                                                            helperText={error&&!item.Ingredient&&"Please Enter the Field"}
                                                       
                                                            onChange={updateIngredientsChange("Ingredient", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3} style={{ width: "100%" }}>
                                                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                            placeholder={"Quantity"}
                                                            style={{ width: "100%" }}
                                                            value={item.quantity}
                                                            error={error&&!item.quantity}
                                                            helperText={error&&!item.quantity&&"Please Enter the Field"}
                                                       
                                                            onChange={updateIngredientsChange("quantity", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3} style={{ width: "100%" }}>
                                                  
                                                        <FormControl variant="outlined"
                                style={{width:"100%"}}
                                className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel> */}
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
                                                                value={item.Measurement}
                                                                error={error&&!item.Measurement}
                                                            helperText={error&&!item.Measurement&&"Please Enter the Field"}
                                                       
          onChange={updateIngredientsChange("Measurement", index)}
        style={{width:"100%"}} 
        placeholder="Measurement"
        >
          <MenuItem value="">
            <em>None</em>
            </MenuItem>
            <MenuItem value={"gms"}>gms</MenuItem>
          <MenuItem value={"ml"}>Ml</MenuItem>
          <MenuItem value={"Units"}>Units</MenuItem>                                
        </Select>
      </FormControl>

</Grid>
<Grid item xs={1} lg={1} md={1} style={{ width: "100%" }}>
                                                        <img
                                                            style={{ marginTop: "10%", height: "50%" }}
                                                            onClick={() => { deleteIngredients(index) }}                                 
                                                            src={minusIcon}
                                                            alt="minus">                                                           
                                                            </img>
             </Grid>
                                                </>

                                            )
                                        })
                                    }
                                    <Button
                                            onClick={addIgredient}
                                            style={{ marginLeft:"12px"}}
                                            startIcon={<img
                                                style={{ height:"24px",width:"24px"}}
                                                src={addIcon} alt="addicon" />}
                                        ><Typography
                                        style={{ textTransform:"capitalize",color:"#B52A65"}}
                                            >Add Ingredient</Typography></Button>
                                </Grid>
                            </AccordionDetails>

                        </Accordion>
                    </Grid>
                   
                    <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ background: "#F6EFE6" }}
                            >
                                <Typography >Ingredients ( Not Included)</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                
                                <Grid container fixed spacing={3} style={{ width: "100%" }}>
                                    {
                                        IngredientsNotIncluded.map((item, index) => {
                                            
                                            return (
                                                

                                                <>
                                                    <Grid item xs={5} lg={5} md={5} style={{ width: "100%" }}>
                                                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                            placeholder={"Ingredient"}
                                                            style={{ width: "100%" }}
                                                            value={item.Ingredient}
                                                            error={error&&!item.Ingredient}
                                                            helperText={error&&!item.Ingredient&&"Please Enter the Field"}
                                                       
                                                            onChange={updateNotIncludedIngredients("Ingredient", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3} style={{ width: "100%" }}>
                                                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                            placeholder={"Quantity"}
                                                            style={{ width: "100%" }}
                                                            value={item.quantity}
                                                            error={error&&!item.quantity}
                                                            helperText={error&&!item.quantity&&"Please Enter the Field"}
                                                       
                                                            onChange={updateNotIncludedIngredients("quantity", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"
                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} lg={3} md={3} style={{ width: "100%" }}>
                                                    
                                                      
                                                        <FormControl variant="outlined"
                                style={{width:"100%"}}
                                className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel> */}
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
                                                                value={item.Measurement}
                                                                error={error&&!item.Measurement}
                                                                helperText={error&&!item.Measurement&&"Please Enter the Field"}
                                                           
          onChange={updateNotIncludedIngredients("Measurement", index)}
         
        style={{width:"100%"}} 
        placeholder="Measurement"
        >
          <MenuItem value="">
            <em>None</em>
            </MenuItem>
            <MenuItem value={"gms"}>gms</MenuItem>
          <MenuItem value={"ml"}>Ml</MenuItem>
          <MenuItem value={"Units"}>Units</MenuItem>                                
        </Select>
      </FormControl>    
                                                 </Grid>
                                                    <Grid item xs={1} lg={1} md={1} style={{ width: "100%" }}>
                                                        <img style={{ marginTop: "10%" ,height:"50%"}}
                                                            onClick={() => { deleteIngredientsNotIncluded(index) }}
                                                            src={minusIcon} alt="minus"></img>
                                                    </Grid>
                                                </>

                                            )
                                        })
                                    }
                                   
                                         <Button
                                            onClick={addIgredientNotincluded}
                                            style={{ marginLeft:"12px"}}
                                           
                                            startIcon={<img src={addIcon}
                                            style={{ height:"24px",width:"24px"}}
                                                alt="addicon" />}
                                        ><Typography
                                        style={{ textTransform:"capitalize",color:"#B52A65"}}
                                            >Add Ingredient</Typography></Button>
                              
                                </Grid>
                            </AccordionDetails>

                        </Accordion>
                    </Grid>
                   
                    
                    <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ background: "#F6EFE6" }}
                            >
                                <Typography > Nutrition </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    {
                                        Nutritions.map((item, index) => {
                                            return (
                                                <>
                                                    <Grid item xs={5} lg={5} md={5} style={{ width: "100%" }}>
                                                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                            placeholder={"Ingredient"}
                                                            style={{ width: "100%" }}
                                                            value={item.name}
                                                            error={error&&!item.name}
                                                            helperText={error&&!item.name&&"Please Enter the Field"}
                                                       
                                                            onChange={updateNutritionChange("name", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} lg={6} md={6} style={{ width: "50%" }}>
                                                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                            placeholder={"Quantity"}
                                                            style={{ width: "100%" }}
                                                            value={item.quantity}
                                                            error={error&&!item.quantity}
                                                            helperText={error&&!item.quantity&&"Please Enter the Field"}
                                                       
                                                            onChange={updateNutritionChange("quantity", index)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#6B6B6B',
                                                                },
                                                            }}
                                                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                            labelWidth={0}
                                                        />
                                                    </Grid>
                                                   
                                                    <Grid item xs={1} lg={1} md={1} style={{ width: "100%" }}
                                                
                                                    >
                                                        <img style={{ marginTop: "10%" ,height:"50%"}}
                                                                onClick={()=>{deleteNutrition(index)}}
                                                            src={minusIcon} alt="minus"></img>
                                                    </Grid>

                                                </>
                                            )
                                        })
                                    }
                                   
                                        <Button
                                             style={{ marginLeft:"12px"}}
                               
                                            onClick={addNutrition}
                                            startIcon={
                                              <img  style={{ height:"24px",width:"24px"}}
                                                src={addIcon} alt="addicon" />}
                                        ><Typography
                                        style={{ textTransform:"capitalize",color:"#B52A65"}}
                                            >Add Nutrition</Typography></Button>
                              
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px" ,fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547"}}>TRAILER VIDEO ID</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                            placeholder={"Enter Trailer Video Id"}
                            style={{ width: "100%" }}
                                value={trailerVideo}
                                error={error&&!trailerVideo}
                                     helperText={error&&!trailerVideo&&"Please Enter the Field"}
                                                       
                            onChange={(event) => settrailerVideo(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                            // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"

                            labelWidth={0}
                        />

                    </Grid>
                    <Grid item xs={6} lg={6} md={6} style={{ width: "100%" }}>
                        <div style={{ padding: "6px" ,fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500",color:"#424547"}}> FULL RECIPE VIDEO ID</div>
                        <TextField
                                id="outlined-helperText"
                                variant="outlined"
                            placeholder="Enter Full Recipe Video Id"
                            style={{ width: "100%" }}
                                value={recipeVideoUrl}
                                error={error&&!recipeVideoUrl}
                                helperText={error&&!recipeVideoUrl&&"Please Enter the Field"}
                                  
                            onChange={(event) => setrecipeVideoUrl(event.target.value)}
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                            // endAdornment={<InputAdornment position="end">Minutes</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"

                            labelWidth={0}
                        />

                    </Grid>

                    <Grid item xs={12} lg={12} md={12} style={{ width: "100%" }}>
                       
                            <Grid >
                                <Typography
                                    style={{padding:"6px",
                                        fontSize: "1rem",
                                        fontFamily: "Roboto",
                                        fontWeight:"500"
                                }}
                                
                                >RECIPE</Typography>
                                  <Grid container> 
                                            {
                                                Recipes_arr.map((item, index) => {
                                                    return (
                                                       
                                                        <>
                                                            <Accordion
                                                                defaultExpanded={true}
                                                                style={{ width: "100%", margin: 0 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{ background: "#F6EFE6" }}
                                    >
                                                                    <Typography >Step {index + 1}</Typography>
                                                                   
                                        {/* <Button onClick={addRecipes}></Button> */}
                                                                </AccordionSummary>
                                                                <AccordionDetails >
                                                     <Grid container spacing={3}>
                                                                        <Grid item container alignItems="flex-start" row xs={12} lg={12} md={12} style={{ width: "100%" }}>
                                                                            <Grid
                                                                                
                                                                                item xs={2} lg={2} md={2}
                                                                            >
                                                                                <Typography>Available :</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={2} lg={2} md={2}>
                                                                             <FormControl variant="outlined"
                                style={{width:"100%"}}
                                className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel> */}
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={item.available}
          onChange={updateRecipesChange("available", index)}
          error={error&&!item.available}
          helperText={error&&!item.available&&"Please Enter the Field"}
            
        style={{width:"100%"}} 
        placeholder="Measurement"
        >
          <MenuItem value="">
            <em>None</em>
            </MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>                            
        </Select>
      </FormControl>   
                                                                            </Grid>
                                                                            <Grid item justify="flex-end" xs={1} lg={1} md={1} style={{ width: "100%",marginLeft:"50%" }}>
                                                                <img style={{ height:"50%",width:"50%" ,marginTop:"25%"}}
                                                                    onClick={()=>{deleteRecipes(index)}}
                                                                    src={minusIcon} alt="minus"></img>
                                                            </Grid>
                                                                    </Grid>
                                                                        <Grid item xs={12} lg={12 } md={12}  style={{ width: "100%" }}>
                                                                        <Grid item container row xs={12} lg={12} md={12} style={{ width: "100%" }}>
                                                                            <Grid item xs={2} lg={2} md={2}>
                                                                                <Typography>Description :</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} lg={6} md={6}>
                                                                            <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            style={{ width: "100%" }}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                },
                            }}
                                                                                        value={item.step}
                                                                                        error={error&&!item.step}
                                                                                        helperText={error&&!item.step&&"Please Enter the Field"}
                                                                                   
                            onChange={updateRecipesChange("step", index)} 
                           
                            InputLabelProps={{
                                style: {
                                    color: '#6B6B6B',
                                },
                            }}
                        />
                                                                    </Grid>
                                                                    </Grid>
                                                                            
                                                                    
                                                            </Grid>
                                                     <Grid xs={12} lg={12} md={12}  style={{ width: "100%",padding:"12px" }}>
                                                     <Grid item container row xs={12} lg={12} md={12} style={{ width: "100%" }}>
                                                                            <Grid item xs={2} lg={2} md={2}>
                                                                                <Typography>Step Video :</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} lg={6} md={6}>
                                                                            <TextField
                                id="outlined-helperText"
                                variant="outlined"
                                                                    placeholder={"Step Video"}
                                                                    style={{ width: "100%" }}
                                                                    value={item.video}
                                                                    error={error&&!item.video}
                                                                    helperText={error&&!item.video&&"Please Enter the Field"}
                                                               
                                                                    onChange={updateRecipesChange("video", index)}                      
                                                                                
                                                                                InputLabelProps={{
                                                                        style: {
                                                                            color: '#6B6B6B',
                                                                        },
                                                                    }}
                                                                    // endAdornment={<InputAdornment position="end">Rs</InputAdornment>}
                                                                    aria-describedby="outlined-weight-helper-text"

                                                                    labelWidth={0}
                                                                                />
                                                                    </Grid>
                                                                    </Grid>
                                                                
                                                    </Grid>
                                                            
                                                            </Grid>
                                                     </AccordionDetails>
                                                            </Accordion>
                                                           
                                         
                                                        </>
                                                    )
                                                })
                                            }
                                    <Button
                                             style={{ marginLeft:"12px"}}
                                           
                                            onClick={addRecipes}
                                            startIcon={<img
                                                style={{ height:"24px",width:"24px"}}
                                                src={addIcon} alt="addicon" />}
                                    ><Typography
                                    style={{ textTransform:"capitalize",color:"#B52A65"}}
                                        >Add Recipe</Typography></Button>
                              
                                        </Grid>   
                                        
                                        </Grid>                
                             
                         
                    </Grid>

                    

                </Grid>
            </Paper>
           

        </div>
         <Paper
         style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
     >
                <Grid container
                    style={{ height:"-webkit-fill-available"}}
                    direction="row"
                    spacing={3}
                    justify="flex-end"
                    alignItems="center">
             <Grid item alignItems="center">
             
                            <Button
                    style={{ background:"white",color:"black"}}                                
                 onClick={props.close}>
                 CANCEL</Button>
             </Grid>
             <Grid item alignItems="center" style={{ paddingRight:"6%"}}>
             <Button                      
                    style={ {background:"#B52A65",color:"white"}}
                //   disabled = {verifydetails()}
                    onClick={validation}
                 >
                            SAVE PRODUCT</Button>
             </Grid>
         </Grid>         
           
     </Paper>
        </>

    );
}
