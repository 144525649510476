import React,{useEffect} from 'react';
import AdminPanel from '../../components/AdminPanel';
import { useHistory } from "react-router-dom";


const AdminPanelHome = (props) => {

  const history = useHistory();

  useEffect(()=>{
   
    if(!props.location.isauthenticated)
       return history.push('/admin')
  },[])
  return (
      <div>
      <AdminPanel/>
      </div>
  );
};

export default AdminPanelHome;