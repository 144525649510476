import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IngredientIcon from '../../assets/images/ingredients.png';
import RecipeCardIcon from '../../assets/images/recipe-card.png';
import SurpriseIcon from '../../assets/images/surprise.png';
import TriviaIcon from '../../assets/images/trivia.png';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Paths } from '../../routes/routePaths';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 96,
    width: 96,
  },
  main: {
    backgroundColor: '#F7EFE5',
    padding: '80px 0',
    textAlign: 'center',
  },
  heading: {
    color: colors.common.gray,
    fontSize: 40,
    lineHeight: '56px',
    fontFamily: 'HappySchool',
    marginBottom: 36,
    textAlign: 'center',
  },
  cards: {
    textAlign: 'center',
    padding: '50px 0!important',
    border: '1px solid #979797',
    borderRadius: '16px',
  },
  desc: {
    color: colors.common.gray,
    fontSize: 28,
    lineHeight: '40px',
    margin: '45px 47px 0',
    textAlign: 'center',
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '70px 0 40px',
  },
}));

const contents = [
  {
    id: 1,
    icon: RecipeCardIcon,
    alt: 'Recipe card',
    text: 'Easy-to-follow Recipe Cards',
  },
  {
    id: 2,
    icon: IngredientIcon,
    alt: 'Ingredients',
    text: 'Non-perishable Ingredients',
  },
  {
    id: 3,
    icon: TriviaIcon,
    alt: 'Trivia Cards',
    text: 'Educational Trivia Cards',
  },
  {
    id: 4,
    icon: SurpriseIcon,
    alt: 'Kit',
    text: 'A Surprise A Kit!',
  },
];

const KitContents = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Container fixed>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography variant={'h2'} className={classes.heading}>
              Inside The Kit
            </Typography>
          </Grid>

          {contents.map((item) => (
            <Grid item xs={12} sm={3} key={item.id}>
              <div className={classes.cards}>
                <img src={item.icon} alt={item.alt} className={classes.icon} />
                <Typography variant={'body1'} className={classes.desc}>
                  {item.text}
                </Typography>
              </div>
            </Grid>
          ))}

          <Grid item xs={12} lg={12} md={12}>
            <MuiThemeProvider theme={buttonFont}>
              <Link
                to={Paths.Subscribe}
                variant={'h2'}
              >
              <Button
                theme
                className={classes.btnSubmit}
                onClick={() => console.log('clicked')}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                Browse Subscription Plans
              </Button>
              </Link>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default KitContents;
