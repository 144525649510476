import { firestore } from '../../../firebase';


const updateTrackingData = async (uid, orderID,newTrackingID) => {
    try {
  
      const response = await firestore.collection('users').doc(uid).get();       
       const    data = {...response.data()}
        //   console.log(data.orderHistory[orderID]);
         
      await firestore
        .collection('users')
        .doc(uid)
        .update({ 

          orderHistory: {  
              ...data.orderHistory,   
            [orderID]: {
                ...data.orderHistory[orderID],
                tracking_id:newTrackingID,           
            },
          }
        });
    } catch (e) {
        console.log("user update fail---")
      
    }
  };

  export  {updateTrackingData};