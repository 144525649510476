import {firestore } from '../../firebase';
import firebase from 'firebase/app'
import {
  currentServerTime,
} from '../utils';
import { updateSuccessMesage, AddingSuccessMesage } from '../utils';


const fetchProducts = async () => {
  try {
    const response = await firestore.collection('products').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

const fetchSnacks = async () => {
  try {
    const response = await firestore.collection('snacks').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

const fetchLeads = async () =>{

try {
  const response = await firestore.collection('leads').orderBy('createdDate','desc').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  
} catch (error) {
  console.warn(error)
  
}

}




const fetchLogin = async () =>{
  try {
    const response = await firestore.collection('user-logins').get();
      return response.docs.map((item) => {
        return { ...item.data(), id: item.id };
      });
    
  } catch (error) {
    console.warn(error)
  }
  
  }

const fetchUsers = async () =>{

  try {
    const response = await firestore.collection('users').orderBy('updatedTime','desc').get();
      return response.docs.map((item) => {
        return { ...item.data(), id: item.id };
      });
    
  } catch (error) {
    console.log("users list-",error);
    console.warn(error)
  }
  
  }



const fetchPromocodes = async () =>{

  try {
    const response = await firestore.collection('promocodes').orderBy('startdate','desc').get();
      return response.docs.map((item) => {
        return { ...item.data(), id: item.id };
      });
    
  } catch (error) {
    console.warn(error)
  }
  
}

const updateSnacksAvailabilty = async (uid, assign) => {
  const value = assign === true ? "true" : "false";
  try {
    await firestore
      .collection('snacks')
      .doc(uid)
      .update({ availability:value });
  } catch (e) {
      console.log("promocodes update fail---",e)
  }
};

const updateSnackssAvailabilty = async (uid, assign) => {
  const value = assign === true ? "true" : "false";
  try {
    await firestore
      .collection('snacks')
      .doc(uid)
      .update({ availability: value });
    
    updateSuccessMesage("snacks");
  } catch (e) {
    console.log(" snacks update fail---", e);
  }
};
  
const updateProductsAvailabilty = async (uid, assign) => {
  const value = assign === true ? "true" : "false";
  try {
    await firestore
      .collection('products')
      .doc(uid)
      .update({ availability: value });
      updateSuccessMesage("products");
  } catch (e) {
      console.log("promocodes update fail---",e)
  }
};

const addNewProduct = async (ProductDetails) => {


  try {
   console.log(ProductDetails);

   await firestore.collection('products').add({
     ...ProductDetails,
     createdDate: currentServerTime,
     updatedTime: currentServerTime,
   });
    
    AddingSuccessMesage("Products");

  } catch (e) {
    console.log(e);
   return e;
 }
};

const addNewSnack = async (ProductDetails) => {


  try {
   console.log(ProductDetails);

   await firestore.collection('snacks').add({
     ...ProductDetails,
     createdDate: currentServerTime,
     updatedTime: currentServerTime,
   });
   AddingSuccessMesage("Snacks");

  } catch (e) {
    console.log(e);
   return e;
 }
};

const editProduct = async (uid,ProductDetails) => {


  try {
   console.log(ProductDetails);

    await firestore.collection('products')
      .doc(uid)
      .update({
     ...ProductDetails,    
     updatedTime: currentServerTime,
   });
   updateSuccessMesage("products");
  } catch (e) {
    console.log(e);
   return e;
 }
};

const editSnack = async (uid,ProductDetails) => {


  try {
   console.log(ProductDetails);

    await firestore.collection('snacks')
      .doc(uid)
      .update({
     ...ProductDetails,    
     updatedTime: currentServerTime,
   });
   updateSuccessMesage("Snacks");
  } catch (e) {
    console.log(e);
   return e;
 }
};


  const updatePromocode  = async (uid,assign) => {
    try {
      await firestore
        .collection('promocodes')
        .doc(uid)
        .update({ active:assign });
    } catch (e) {
        console.log("promocodes update fail---",e)
    }
  };

  const addPromocode = async (createPromocode) => {


     const firebaseStartDate = firebase.firestore.Timestamp.fromDate(createPromocode.promocode.startdate);
     const firebaseEndDate = firebase.firestore.Timestamp.fromDate(createPromocode.promocode.enddate);

    try {

      await firestore.collection('promocodes').add({
        active: createPromocode.promocode.active || '',
        amount: createPromocode.promocode.amount || '',
        code: createPromocode.promocode.code ? createPromocode.promocode.code: '',
        enddate: firebaseEndDate,
        startdate: firebaseStartDate,
        type: createPromocode.promocode.type,
        planID: createPromocode.promocode.planID,
        productType : createPromocode.promocode.productType,
        
      });

    } catch (e) {
      return e;
    }
  };

  const editPromocode = async (createPromocode) => {

     const firebaseStartDate = firebase.firestore.Timestamp.fromDate(createPromocode.promocode.startdate);
     const firebaseEndDate = firebase.firestore.Timestamp.fromDate(createPromocode.promocode.enddate);
    
    try {

      await firestore.collection('promocodes').doc(createPromocode.promocode.id).set({
        active: createPromocode.promocode.active || '',
        amount: createPromocode.promocode.amount || '',
        code: createPromocode.promocode.code ? createPromocode.promocode.code: '',
        enddate: firebaseEndDate,
        startdate: firebaseStartDate,
        type: createPromocode.promocode.type,
        planID: createPromocode.promocode.planID,
        productType : createPromocode.promocode.productType,
      });

    } catch (e) {
      return e;
    }
  };
  

const fetchProductDetails = async (id) => {
  try {
    const response = await firestore.collection('products').doc(id).get();
    return {
      ...response.data(),
      id: response.id,
    };
  } catch (e) {
    console.warn(e);
  }
};

const fetchProductDetailsBySlug = async (slug) => {

  try {
    const response = await firestore.collection('products').where('slug', '==', slug).get();
    let result;
    response.forEach((el) => { result = { ...el.data(), id: el.id } })

    return result
  } catch (e) {
    console.warn(e);
  }
};

const fetchSummerCamps = async () => {
    try {
      const response = await firestore.collection('summercamp').get();
      return response.docs.map((item) => {
        return { ...item.data(), id: item.id };
      });
    } catch (e) {
      console.warn(e);
    }
  };


 

export {editSnack, addNewSnack,updateSnackssAvailabilty,editProduct,fetchSnacks,addNewProduct,updateProductsAvailabilty, editPromocode,addPromocode,updatePromocode,fetchPromocodes,fetchLogin,fetchUsers,fetchLeads,fetchProducts, fetchProductDetails, fetchProductDetailsBySlug, fetchSummerCamps };
