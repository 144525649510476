import { createAction as createReduxAction } from 'redux-actions';
import { take, takeEvery, race, put, call, all } from 'redux-saga/effects';

const identity = i => i;
const PROMISE = '@@redux-action/PROMISE';
const status = ['REQUEST', 'SUCCESS', 'FAILURE'];

function createFormAction(requestAction, types, payloadCreator) {
  const actionMethods = {};
  const formAction = payload => ({ type: PROMISE, payload });
  let creator = payloadCreator || identity;

  // Allow a type prefix to be passed in
  if (typeof requestAction === 'string') {
    requestAction = status.map(s => {
      const a = `${requestAction}_${s}`;
      const subAction = payload => ({
        type: a,
        payload: creator(payload)
      });

      // translate specific actionType to generic actionType
      actionMethods[s] = a;
      actionMethods[s.toLowerCase()] = subAction;

      return subAction;
    })[0];

    if (types) {
      creator = types;
    }

    types = [actionMethods.SUCCESS, actionMethods.FAILURE];
  }

  if (types.length !== 2) {
    throw new Error('Must include two action types: [ SUCCESS, FAILURE ]');
  }

  return Object.assign((data, dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        formAction({
          request: requestAction(data),
          defer: { resolve, reject },
          types
        })
      );
    });
  }, actionMethods);
}

const isFunction = value => typeof value === 'function';

const getCreatorForType = (type, creator) => {
  if (!creator) {
    return creator;
  }
  if (isFunction(creator[type])) {
    return creator[type];
  }
  if (isFunction(creator[type.toLowerCase()])) {
    return creator[type.toLowerCase()];
  }
  if (isFunction(creator)) {
    return creator;
  }
  return undefined;
};

function createAction(typePrefix, payloadCreator, metaCreator) {
  const createActionCreator = type =>
    createReduxAction(
      `${typePrefix}/${type}`,
      getCreatorForType(type, payloadCreator),
      getCreatorForType(type, metaCreator)
    );

  return status.reduce((result, stage) => {
    const actionCreator = createActionCreator(stage);
    return Object.assign(result, {
      [stage.toLowerCase()]: actionCreator,
      [stage.toUpperCase()]: actionCreator.toString()
    });
  }, createActionCreator(status[0]));
}

function* handlePromiseSaga(action) {
  const { request, defer, types } = action.payload;
  const { resolve, reject } = defer;
  const [SUCCESS, FAIL] = types;

  const [winner] = yield all([
    race({
      success: take(SUCCESS),
      fail: take(FAIL)
    }),
    put(request)
  ]);

  if (winner.success) {
    yield call(
      resolve,
      winner.success && winner.success.payload
        ? winner.success.payload
        : winner.success
    );
  } else {
    yield call(
      reject,
      winner.fail && winner.fail.payload ? winner.fail.payload : winner.fail
    );
  }
}

export { PROMISE, createAction, createFormAction, handlePromiseSaga };

export default [takeEvery(PROMISE, handlePromiseSaga)];
