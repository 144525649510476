import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../containers/Home';
import AdminPanel from "../containers/AdminPanelHome"
import Subscribe from '../containers/Subscribe';
import Account from '../containers/Account';
import ProductListing from '../containers/ProductListing';
import ProductDetails from '../containers/ProductDetails';
import ReadyToEat from '../containers/ReadyToEat';
import { Paths } from './routePaths';


import ReadyToEatDetails from '../containers/ReadyToEatDetails';
import CompleteFAQ from '../components/FAQ';
import PrivacyPolicy from '../components/PrivacyPolicy';
import AboutUs from '../containers/AboutUs';
import TermsOfUse from '../components/TermsOfUse';
import ReturnPolicy from '../components/ReturnPolicy';
import OrderSuccess from '../containers/OrderSuccess';
import ProductDetailsLanding from '../containers/ProductDetailsLanding';
import ReadyToEatDetailsLanding from '../containers/ReadyToEatDetailsLanding';
import SummerCampHome from '../containers/SummerCampHome';
import Adminlogin from '../containers/AdminLogin';
import AdminPanelHome from '../containers/AdminPanelHome';

/**
 * Import Containers here
 */

const Routes = ({ user }) => {

  return (
    <Switch>
      <Route exact={true} path={Paths.Home} component={Adminlogin} />
      <Route exact={true} path={Paths.Admin} component={Adminlogin} />
      <Route exact={true} path={Paths.Adminhome} component={AdminPanel} />
      <Route exact={true} path={Paths.SummerCamp} component={SummerCampHome} />
      <Route exact={true} path={Paths.Subscribe} component={Subscribe} />
      <Route
        exact={true}
        path={Paths.Account}
        component={() => <Account user={user} />}
      />
      <Route
        exact={true}
        path={Paths.ProductListing}
        component={ProductListing}
      />
      <Route
        exact={true}
        path={Paths.ProductDetails}
        component={ProductDetails}
      />
      <Route
        exact={true}
        path={Paths.ProductDetailsLanding}
        component={ProductDetailsLanding}
      />
      <Route exact={true} path={Paths.ReadyToEat} component={ReadyToEat} />
      <Route
        exact={true}
        path={Paths.ReadyToEatDetails}
        component={ReadyToEatDetails}
      />

      <Route
        exact={true}
        path={Paths.ReadyToEatDetailsLanding}
        component={ReadyToEatDetailsLanding}
      />

      <Route exact={true} path={Paths.CompleteFAQ} component={CompleteFAQ} />
      <Route
        exact={true}
        path={Paths.PrivacyPolicy}
        component={PrivacyPolicy}
      />
      <Route exact={true} path={Paths.AboutUs} component={AboutUs} />
      <Route exact={true} path={Paths.TermsOfUse} component={TermsOfUse} />
      <Route exact={true} path={Paths.ReturnPolicy} component={ReturnPolicy} />
      <Route exact={true} path={Paths.OrderSuccess} component={OrderSuccess} />
    </Switch>
  );
};

export default Routes;
