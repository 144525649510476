import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '40px 0 120px',
    color: '#535B65',
  },
  sectionHeader: {
    opacity: 0.9,
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '36px',
    marginTop: 56,
  },
  sectionPara: {
    opacity: 0.8,
    fontSize: 20,
    lineHeight: '30px',
  },
  hyperlink:{
    color: '#FA4A39',
    fontWeight: 500
  }
}));

const PolicyDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={11}>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY SUBSCRIBING,
              ACCESSING, OR USING OUR WEBSITE OR SERVICE, YOU ACKNOWLEDGE THAT
              YOU HAVE READ, UNDERSTAND, AGREE AND ACCEPT ALL THE TERMS OF THIS
              PRIVACY POLICY AND OUR WEBSITE TERMS AND CONDITIONS.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              INDIVIDUAL REQUESTS FOR WAIVERS OF THE PRIVACY POLICY WILL NOT BE
              ACCEPTED. THIS PRIVACY POLICY IS NON-NEGOTIABLE.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              BY USING THE SERVICE, YOU SIGNIFY YOUR ACCEPTANCE OF THIS POLICY.
              IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT USE OUR SERVICE.
              YOUR CONTINUED USE OF THE SERVICE FOLLOWING THE POSTING OF CHANGES
              TO THIS POLICY WILL BE DEEMED YOUR ACCEPTANCE OF THOSE CHANGES.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              This Privacy Policy governs the manner in which Orangefigs
              (hereafter also referred to as “Orangefigs,” “we” and “us”)
              collects, uses, maintains and discloses information collected from
              users (each, a “User” or “you”) of the Orangefigs mobile
              application (hereinafter “app” or “application”), the Orangefigs
              website at{' '}
              <a
                href="https://www.Orangefigs.com"
                className={classes.hyperlink}>
                https://www.Orangefigs.com
              </a>{' '}
              and{' '}
              <a
                href="https://www.Orangefigs.com"
                className={classes.hyperlink}>
                https://www.Orangefigs.com
              </a>{' '}
              (hereinafter “site” or “website”), and any other affiliate
              websites or applications related to Orangefigs (together,
              collectively the “Service”). By accessing the Service, you
              acknowledge and agree to this Privacy Policy and also agree to the
              Terms of Use of the Service located at:{' '}
              <a
                href="www.Orangefigs.com/terms-of-use"
                className={classes.hyperlink}>
                www.Orangefigs.com/terms-of-use
              </a>
              .
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              We reserve the right to make any changes to our privacy policy at
              any time, notice of which may be posted on this privacy page at{' '}
              <a
                href="www.Orangefigs.com/privacy-policy"
                className={classes.hyperlink}>
                www.Orangefigs.com/privacy-policy
              </a>
              . Users must refer back to our privacy policy to read any changes
              in our privacy policy.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Information Collection
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              While using this Service, you may provide us with personally
              identifiable information. This refers to information about you
              that can be used to contact or identify you (“Personal
              Information”). Personal Information that we collect may include,
              but is not limited to, your name, mailing address, phone number,
              and email address. Besides the Personal information, we may also
              collect other information regarding your activity during your use
              of the Service including, but not limited to, session duration,
              time between sessions, searches made during a session, actions
              taken, log in time, log out time, and other behavioral data
              (collectively, “Usage Information”). You can opt out of providing
              this additional information by not using the Service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              When you use Orangefigs site or apps, our servers automatically
              record information that your browser sends whenever you visit our
              Service. This information may include, but is not limited to, the
              type of mobile computing device, general location, cellular
              carrier, your computer’s Internet Protocol address (IP address) or
              other identifier, geo-location, browser type, the web page you
              were visiting before you came to Orangefigs, and in-bound
              tracking, namely, the source (ad, social media channel or blog,
              search engine) which made you aware of the Orangefigs Service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Do Not Track Signals from Web Browsers – The Service is not
              presently configured to support Do Not Track (“DNT”) signals from
              web browsers.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              How We Use The Information Collected
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Personal Information and Usage Information is used to provide and
              improve our website and services; to better understand your needs
              and interests; to personalize your experience; to provide or offer
              updates and product announcements; to assist third parties acting
              on our behalf (described in more detail below), and to provide you
              with further information and offers from us and our partners that
              we believe you may find useful or interesting, including
              newsletters, marketing or promotional materials and other
              information on services and products offered by us or third
              parties that are authorized by us.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              The information collected is also used to monitor and analyze use
              of Orangefigs and our services, for technical administration, to
              increase our site’s functionality and user-friendliness, to better
              tailor it to your needs, to generate and derive useful data and
              information concerning the interests, characteristics and website
              use behavior of our users.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs may on occasion combine information collected with
              additional records (such as information from affiliated companies,
              partners, distributors, or outside vendors, and publicly available
              information, including on social media sites). The combined
              information may be used for purposes such as to market products,
              features, or services that may be of interest to you, or for
              research and analytics.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Service Providers, Business Partners and Others
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs does not control the privacy policies of third parties,
              and you are subject to the privacy policies of those third parties
              where applicable. Orangefigs is not responsible for the privacy or
              security practices of other websites on the Internet, even those
              linked to or from the Orangefigs site. We encourage you to ask
              questions before you disclose your personal information to others.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              We may employ third party service providers to facilitate our
              service, to provide service on our behalf, to perform website
              related services (including but not limited to maintenance
              services, database management, web analytics and improvement of
              Orangefigs features) or to assist us in analyzing how our website
              and service are used and can be improved. These third parties may
              have access to your Personal Information only for purposes of
              performing these tasks on our behalf.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Compliance with Laws and Law Enforcement.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs must cooperate with government and law enforcement
              officials and private parties to enforce and comply with the law.
              In the event of a claim and/or legal process (including but not
              limited to subpoenas), to protect the property and rights of
              Orangefigs or a third party, to protect the safety of the public
              or any person, or to prevent or stop any activity we may consider
              to pose a risk of being illegal, unethical, inappropriate or
              legally actionable, we reserve the right to disclose any
              information about you to government or law enforcement officials
              or private parties as we in our sole discretion find necessary or
              appropriate.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Business Transfers
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              In the event of a bankruptcy, merger, acquisition, reorganization
              or sale of assets, Orangefigs may sell, transfer or otherwise
              share some or all of its assets, and your Personal Information may
              be transferred as part of that transaction. To the maximum extent
              allowable by law, the Privacy Policy will apply to the User
              information as transferred to the successor entity. However, User
              information submitted after a transfer to a successor entity may
              be subject to a new privacy policy adopted by the successor
              entity.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Security
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You are responsible for safeguarding and preventing unauthorized
              access to user information and the password You use to access our
              Service. You agree not to disclose your user information and/or
              password to any third party. You are responsible for any activity
              using your account, whether or not You authorize that activity.
              Please immediately notify Orangefigs of any unauthorized use of
              your account.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs takes the security of your Personal Information
              seriously. We will employ reasonable measures designed to protect
              your information from unauthorized access and unsolicited contact
              from third party groups.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Our Policy Toward Children
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs is not directed to nor is it intended for children
              under 13. We do not knowingly collect personally identifiable
              information (or any information at all) from any persons under 13
              years old. If we become aware that a child has provided us with
              Personal Information, we will delete such information from our
              files. Users of Orangefigs accept responsibility for ensuring that
              children are not accessing or using our Service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              For Users Outside of the India Only
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              The Service is hosted on servers located in India. If you are a
              user accessing the Service from the European Union, Australia,
              Asia, or any other region with laws or regulations governing
              personal data collection, use, and disclosure, that differ from
              India laws, you are transferring your personal data to the India
              which may not have the same data protection laws as such other
              regions. By providing user information through the Service you are
              consenting to the transfer of your information to India for
              processing and maintenance in accordance with this Privacy Policy
              and the Terms of Use. You are also consenting to the application
              of India law in all matters concerning the Service and any
              services offered or provided therefrom.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              For Users Residing in India -- Your India Privacy Rights
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Residents of India can obtain certain information about the types
              of personal information that companies with whom they have an
              established business relationship have shared with third parties
              for direct marketing purposes during the preceding calendar year.
              In particular, the law provides that companies must inform
              consumers about the categories of personal information that have
              been shared with third parties, the names and addresses of those
              third parties, and examples of the types of services or products
              marketed by those third parties. To request a copy of the
              information disclosure provided by Orangefigs pursuant to Civil
              Code, please contact us via the email address stated below. Please
              allow at least 30 days for a response.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              LEGAL DISCLAIMER
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              This Service operates "AS-IS" and "AS-AVAILABLE," without
              warranties of any other kind. We are not responsible for events
              beyond our direct control. This Privacy Policy is governed by the
              Indian laws , excluding conflicts of law principles. Any legal
              actions against us arising out of or related to the Service must
              be commenced in Telangana State , India.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Updates to Privacy Policy
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              If we make any material changes to this Privacy Policy, we will
              post a notice on the website or application notifying users of the
              changes. In some cases, we also may send an email notifying users
              of the changes. You should check this website periodically to see
              if any recent changes to this Privacy Policy have occurred.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Contacting Us
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              This Policy is part of the Terms of Use of the Service and any use
              of the Service is governed by those Terms of Use. If you have any
              questions about this Privacy Policy, the practices of this site,
              or your dealings with this site, please contact us at{' '}
              <a
                href="mailto:hello@Orangefigs.com"
                className={classes.hyperlink}>
                hello@Orangefigs.com
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PolicyDetails;
