import { Card, Typography } from '@material-ui/core';
import React from 'react';
import UserAvatar from '../../../../assets/images/user_avatar.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '328px',
    width: '328px',
    borderRadius: 0,
    position: 'relative',
    padding: '40px 24px',
    margin: '20px 0px',
    '&:hover': {
      cursor: 'pointer',
      '& $image': {
        transform: 'scale(1.1)',
      },
    },
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background:
      'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%)',
    zIndex: 1,
  },
  blue: {
    background: '#366BC4',
  },
  pink: {
    background: '#B42B64',
  },
  avatar: {
    height: '56px',
    width: '48px',
  },
  content: {
    marginTop: '128px',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 24px',
  },
  name: {
    color: '#fff',
  },
  designation: {
    color: '#fff',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
    transition: '0.5s ease',
  },
}));

const TeamMemberCard = ({ color, name, designation, image }) => {
  const classes = useStyles();
  return (
    <Card elevation={0} className={`${classes.card} ${classes[color]}`}>
      {image && <img className={classes.image} src={image} alt="name" />}
      <div className={classes.overlay}>
        <div className={classes.content}>
          {!image && (
            <img
              className={classes.avatar}
              src={UserAvatar}
              alt="user avatar"
            />
          )}

          <Typography
            style={{ marginTop: image ? '117px' : '61px' }}
            className={classes.name}
            variant="h2">
            {name}
          </Typography>
          <Typography className={classes.designation}>{designation}</Typography>
        </div>
      </div>
    </Card>
  );
};

export default TeamMemberCard;
