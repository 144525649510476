import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useEffect } from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CalendarFilter from "../Common/calendarFilter"
import SearchBar from "../Common/searchbar"
import Button from '@material-ui/core/Button';
import { fetchUsers } from '../../../operations/products';
import SubscribeFilter from "./subscribefilter"
import PageSpinner from "../Common/Pagespinner"
import downloadCsv from 'download-csv';
import downloadIcon from '../../../assets/images/adminPanel/download.svg';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    minHeight: "75%",
  },
  
});

let data =[];

export default function UsersTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [UserssortToggle, setUserssortToggle] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loading,setLoading] = React.useState(false);
  const [calendardate, setcalendardate] = React.useState(new Date());
  ///calender dates
  const [calendarToggle, setcalendarToggle] = React.useState(false);//



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setcalendarToggle(false);

    filterUserdata('date');
  }, [calendardate]);

  const filterdates = (type) => {
    setcalendarToggle(false);
    filterUserdata(type);
  };



  const filterUserdata = async (type) => {

    let startdate = new Date();
    let enddate = new Date();


    if (type === 'date') {
      startdate = calendardate;
      enddate = calendardate;
    }

    if (type === 'today') {
      startdate = new Date();
      enddate = new Date();
    }

    if (type === 'week') {
      startdate = moment().subtract(7, 'days'); // 04/05/2021
      enddate = new Date();
    }

    if (type === 'month') {
      startdate = moment().subtract(1, 'months').startOf('month');
      enddate = moment().subtract(1, 'months').endOf('month');
    }
    if (type === 'thismonth') {
      startdate = moment().startOf('month');
      enddate = moment().endOf('month');
    }

    if (type === '30days') {
      startdate = moment().subtract(30, 'days').calendar();
      enddate = moment(new Date());
    }
    if (type === 'quarter') {
      startdate = moment(new Date())
        .subtract(4, 'months')
        .startOf('month');
      enddate = moment(new Date()).subtract(1, 'months').endOf('month');

    }
    if (type === 'year') {
       startdate = moment(new Date()).subtract(1, 'year').startOf('year');
       enddate = moment(new Date()).subtract(1, 'year').endOf('year');
    }

    const start = moment(startdate).startOf('day')._d; // set to 12:00 am today
    const end = moment(enddate).endOf('day')._d;



    const filteredUsers =
    data &&
    data.filter((user) => {
        let updateddate = null;
        const {orderDate} = user;

        if (orderDate !== "-") {
          updateddate = new Date(orderDate.toDate());
        }
        return updateddate >= start && updateddate <= end;
      });


    setPage(0);
    setRows(filteredUsers);

  };

  const changeDate = (e) => {
    setcalendardate(e);
  };


  const sortUsers = async () => {
    const sorted_arr =
      UserssortToggle ? rows.sort((user1, user2) => {
        setUserssortToggle(false);
        
        return (user2.orderDate !== "-" && new Date(user2.orderDate.toDate())) - (user1.orderDate !== "-" && new Date(user1.orderDate.toDate()))
      })
        : rows.sort((user1, user2) => {
          setUserssortToggle(true); //asecending 
          return (user1.orderDate !== "-" && new Date(user1.orderDate.toDate())) - (user2.orderDate !== "-" && new Date(user2.orderDate.toDate()))

        });
    setRows(sorted_arr);
  };



  const fetchUser = async () => {
    data=[];  
    setLoading(true);
    const result = await fetchUsers();   
    const rows = await fetchdata(result);
    setRows(rows);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();

  }, []);


  const fetchdata = async (result) => {


    await result.map((detail) => {
      const orderHistory = detail.orderHistory;
      if (orderHistory) {
        Object.keys(orderHistory).map(function (key) {
          const trackingOrder = orderHistory[key].TrackingID
            ? orderHistory[key].TrackingID
            : null;
          const orderId = orderHistory[key].rzpOrderId
            ? orderHistory[key].rzpOrderId
            : '-';
          const enteredpromocode = orderHistory[key].enteredpromocode
            ? orderHistory[key].enteredpromocode
            : '-';
          const orderDate = orderHistory[key].orderDate
            ? orderHistory[key].orderDate
            : '-';
          const price = orderHistory[key].grandTotal
            ? orderHistory[key].grandTotal
            : '-';

          
          const {
            name,
            phone,
            email,
            id,
            qty,
            shippingAddress,
            firstKidName,
            firstKidDOB,
          } = detail;

          const ShippingAddress = orderHistory[key].shippingAddress?orderHistory[key].shippingAddress:shippingAddress
          const KidName = orderHistory[key].shippingAddress&&orderHistory[key].shippingAddress.firstKidName ? orderHistory[key].shippingAddress.firstKidName : firstKidName;
          const KidDOB = orderHistory[key].shippingAddress&&orderHistory[key].shippingAddress.firstKidDOB ? orderHistory[key].shippingAddress.firstKidDOB : firstKidDOB;
          
          let type_arr = [];
          const subscribed = 'yes';

          //picking products
          if (orderHistory[key].products && orderHistory[key].products.length) {
            type_arr.push('Products');
          }
          //Subscriptions
          if (
            orderHistory[key].subscriptions &&
            orderHistory[key].subscriptions.length
          ) {
            type_arr.push('Subscriptions');
          }
          //Summer camps
          if (
            orderHistory[key].summerCamp &&
            orderHistory[key].summerCamp.length
          ) {
            type_arr.push('Summer Camp');
          }

          //snacks
          if (orderHistory[key].snacks && orderHistory[key].snacks.length) {
            type_arr.push('Snacks');
          }

          data.push({
                name,
                type_arr,
                trackingOrder,
                price,
                id,
                orderDate,
                enteredpromocode,
                orderId,
                phone,
                email,
                ShippingAddress,
                subscribed,
                KidDOB ,KidName
              });

         
        });
      } else {
        const { name, phone, email, price, qty, shippingAddress, firstKidName, firstKidDOB ,id} = detail;
        const enteredpromocode = "N/A"
        const orderId = "N/A"
        const orderDate = "-"
        const subscribed = "no"
        const type ="N/A"

        data.push({
          id,
          name,
          type,         
          price,        
          orderDate,
          enteredpromocode,
          orderId,
          phone,
          email,
          shippingAddress,
          subscribed,
          firstKidName,
          firstKidDOB,
        });
      }
    });

    return data;
  };


  const nodata = () => {

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key="details">
        <TableCell >
          no data Found
    </TableCell>
      </TableRow>

    )
  }

  const [reset, setReset] = React.useState(true);

  useEffect(() => {
    setRows(data);
  }, [reset])

  const resetData = () => {
    setReset(!reset);
  }

  const closeCalendarfilter = () => {
    setcalendarToggle(false);
  }

  const searchUsers = (text) => {
    const searchedUsers = data.filter((User) => {
      let {name,orderId,phone,email} = User;                  
      name = name.toLowerCase();
      const namesearch = name.toLowerCase().search(text.toLowerCase()) > -1;
      const orderIdsearch = orderId.toLowerCase().search(text.toLowerCase()) > -1;
      const phonesearch = phone.toLowerCase().search(text.toLowerCase()) > -1;
      const emailsearch = email.toLowerCase().search(text.toLowerCase()) > -1;

      if(namesearch||orderIdsearch||phonesearch||emailsearch) return true;
      
    });
    setRows(searchedUsers);
  }


  const statusFilterChange = (filterkeys) => {



    let fileteredCustomers = data ;

    if (filterkeys.indexOf("YES") > -1 && filterkeys.indexOf("NO") === -1) {
      fileteredCustomers = data.filter((User) => {
        const { subscribed}= User;
        return subscribed === "yes"
      })
    }
    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") === -1) {
      fileteredCustomers = data.filter((User) => {
        const { subscribed}= User;
        return subscribed === "no"
      });
    }

    if (filterkeys.indexOf("NO") > -1 && filterkeys.indexOf("YES") > -1) {
      fileteredCustomers = data;
    }

    setRows(fileteredCustomers);

  }


  const downloadUserData = async () => {
    const date = moment().format('DD-MM-YYYY');
    const exportFileName = `Purchase User Data ${date}.csv`;
    // downloadCsv(data, columns, exportFileName);
    const tabledata = rows.map((User) => {
      const {
        name,
        price,
        orderDate,
        enteredpromocode,
        orderId,
        phone,
        email,
        ShippingAddress,
        subscribed,
        KidDOB ,KidName,
      } = User;
      const orderDateNew =
        orderDate !== '-'
          ? orderDate
              .toDate()
              .toString()
              .replace('GMT+0530 (India Standard Time)', '')
          : '-';
      const AddressOne =
      ShippingAddress && ShippingAddress.addressOne
          ? ShippingAddress.addressOne
          : '';
      const AddressTwo =
      ShippingAddress && ShippingAddress.addressTwo
          ? ShippingAddress.addressTwo
          : '';
      const Zipcode =
      ShippingAddress && ShippingAddress.zipcode
          ? ShippingAddress.zipcode
          : '';
      const CityName =
      ShippingAddress && ShippingAddress.cityName
          ? ShippingAddress.cityName
          : '';
      const StateName =
      ShippingAddress && ShippingAddress.stateName
          ? ShippingAddress.stateName
          : '';
      var address =
        AddressOne +
        ' | ' +
        AddressTwo +
        ' | ' +
        CityName +
        ' | ' +
        StateName +
        ' | ' +
        Zipcode;
      address = address.replaceAll(',', '-');
      const obj = {
        name,
        phone,
        email,
        address,
        KidName,
        KidDOB,
        enteredpromocode,
        price,
        orderId,
        orderDate: orderDateNew,
      };
      return obj;
    });
    downloadCsv(
      tabledata,
      {
        name: 'Name',
        price: 'Price',
        orderDate: 'OrderDate',
        enteredpromocode: 'Promocode',
        orderId: 'Order ID',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        subscribed: 'Subscribed',
        KidName: 'Kid Name',
        KidDOB: 'Kid DOB',
      },
      exportFileName
    );
  };

  return (
<>
    <div >
     
      <div className="tab">

      <Grid 
        container 
        direction="row" 
        style={{height:"7.5%"}}
        >
        <Grid
          item container
          style={{width:"50%"}}           
          spacing={2}      
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item className="containerHeading">
            Users
          </Grid>
        </Grid>
        <Grid
         item container
         spacing={2}
         style={{width:"51%"}}  
          direction="row"
          justify="flex-end"
          alignItems="center">


          <Grid item > 
              <SearchBar search={searchUsers} style={{ margin: "10px", maxWidth: "90%" }} />
             </Grid>  
            <Grid item >                      
               <Button className="btn1" 
               onClick={resetData}
               startIcon={ <RefreshIcon style={{ color: 'white'}} />}
               >
               REFRESH</Button>                           
            </Grid>
            <Grid item>
                <Button className="btn1 " 
                startIcon={  <img
                  src={downloadIcon}
                  alt="download"
                  style={{ marginRight: '3px' }}
                />}
                onClick={downloadUserData}>
                 DOWNLOAD
                </Button>
              </Grid>
     
      </Grid>
      </Grid>




 
          {calendarToggle && (
            <CalendarFilter filterdates={filterdates} changeDate={changeDate} close={closeCalendarfilter} />
          )}      
      
      
      
        <div>

          <Paper className={classes.root} >
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Customer Details
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Address
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Kid Details
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      <SubscribeFilter statusFilterChange={statusFilterChange} heading={"Subscribed"} />
                    </TableCell>
                 
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }}>
                      Promocode
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      Grand total
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", maxHeight: "20" }}>
                      Order ID
                </TableCell>
                    <TableCell style={{ minWidth: "170", maxWidth: "170", minHeight: "20", maxHeight: "20" }} onClick={sortUsers}>
                      <span>Purchased Time</span> &nbsp;
                 <span><TodayIcon
                        onClick={() => {
                          setcalendarToggle(!calendarToggle);
                        }}></TodayIcon></span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
               
                                 {loading && (
                                  <TableRow style ={{ width:"100%",left:"50vh",height: "50vh"}}>                
                                    <PageSpinner />                                                                                
                                        </TableRow>
                                               )}
                                 
                  {
                    rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((User,index) => {
                      

                        const {name, price, orderDate, enteredpromocode, orderId, phone, email, shippingAddress, subscribed, KidDOB ,KidName} = User;
                        let grandTotal = price;



                        const AddressOne =
                          shippingAddress && shippingAddress.addressOne
                            ? shippingAddress.addressOne + ","
                            : '';
                        const AddressTwo =
                          shippingAddress && shippingAddress.addressTwo
                            ? shippingAddress.addressTwo + ","
                            : '';
                        const Zipcode =
                          shippingAddress && shippingAddress.zipcode
                            ? shippingAddress.zipcode + ","
                            : '';
                        const CityName =
                          shippingAddress && shippingAddress.cityName
                            ? shippingAddress.cityName + ","
                            : '';
                        const StateName =
                          shippingAddress && shippingAddress.stateName
                            ? shippingAddress.stateName + ","
                            : '';

                        const grandtotal = price;

                        const address =
                          AddressOne +
                          AddressTwo +
                          CityName +
                          StateName +
                          Zipcode;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={"details"+index.toString()} >
                            <TableCell key="name" >
                              <p className="tablename">{name}</p>
                              <p>{phone}</p>
                              <p>{email}</p>
                            </TableCell>
                            <TableCell key="address" style={{ maxWidth: "300px" }}> {address ? address : '-'}
                              {/* <p>{AddressOne+","}</p> 
                      <p>{AddressTwo+","}</p> 
                      <p>{CityName+","}{StateName+","}{Zipcode}</p>                               */}
                            </TableCell>
                            <TableCell key="phone" >
                              {KidName ?
                                <div><p>{ KidName + ","}</p>
                                  <p>{KidDOB}</p> </div> : '-'}
                            </TableCell>
                            
                          
                            <TableCell key="subscribed" > {subscribed ? subscribed.toUpperCase() : 'N/A'} </TableCell>

                            <TableCell key="enteredpromocode" > {enteredpromocode ? enteredpromocode : 'N/A'} </TableCell>
                            <TableCell key="grandtotal" > {grandtotal} </TableCell>
                            <TableCell key="orderId" > {orderId} </TableCell>
                            <TableCell key="updatedtime" > {orderDate !== "-"
                              ? orderDate.toDate()
                                .toString()
                                .replace('GMT+0530 (India Standard Time)', '')
                              : '-'} </TableCell>
                          </TableRow>
                        );
                      })
                  }

                </TableBody>
              </Table>
            </TableContainer>
           
          </Paper>
        </div>
      </div>
    </div>
    <Paper
         style={{ position:"fixed",bottom:"0",height:"6%",width:"100%"}}
     >
                <Grid container
                    style={{ height:"100%"}}
          direction="row"
          justify="flex-end"
          alignItems="center">
             <Grid item alignItems="center">
             <TablePagination className="table-fotter"
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
             </Grid>
            
         </Grid>
         
             
     </Paper>
      
 </>
      );
}
