import React from 'react';
import Layout from '../Layout';
import ContactUs from '../ContactUs';
import FAQTabs from './faqTabs';

const CompleteFAQ = () => {
  return (
    <Layout orangeBG={true}>
      <FAQTabs/>
      <ContactUs />
    </Layout>
  );
};

export default CompleteFAQ;
