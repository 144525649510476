import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import {
  ZipcodeVerification,
  AddNewZipCodeAndEmail,
} from '../../operations/common';
import Message from '../Message';
import Alert from '../Alert';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 72,
    width: 'auto',
  },
  zipcodeSection: {
    backgroundColor: 'rgba(250, 74, 56, 0.1)',
    padding: '80px 0',
  },
  textField: {
    margin: '12px 0',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  heading: {
    fontSize: 28,
    lineHeight: '36px',
    opacity: 0.5,
    color: '#000',
    fontWeight: 900,
  },
  info: {
    fontSize: 16,
    lineHeight: '24px',
    opacity: 0.5,
    color: '#000',
    fontWeight: 500,
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '43px 0',
  },
  alert: {
    background: 'transparent',
    color: theme.palette.common.black,
  },
}));

const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ZipcodeVerify = () => {
  const classes = useStyles();

  const [zipcodeServiceable, setZipcodeServiceable] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const getZipcode = async () => {
    const result = await ZipcodeVerification();
    setZipcodeServiceable(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getZipcode();
    }
    fetchData();
  }, []);

  const checkZipcode = (value) => {
    if (value === '') {
      Message.warn('Please enter a Zipcode.');
      return;
    }

    const result = zipcodeServiceable.findIndex((zip) => {
      return zip.Pincode === value;
    });

    if (email.length !== 0) {
      if (!emailRegEx.test(email.toString().toLowerCase())) {
        Message.warn('Please enter a valid email.');
        return;
      }
    }

    if (result === -1) {
      let data = {
        email: email,
        pincode: zipcode,
      };
      AddNewZipCodeAndEmail(data);
    }

    setMessage(result);
    return result;
  };

  const showMessage = () => {
    if (message === -1) {
      return (
        <Alert className={classes.alert} severity="error">
          Thank you for showing interest. Unfortunately, we don’t currently
          deliver at this zipcode. We will make sure to update you at the
          email/phone number provided.
        </Alert>
      );
    } else if (message === '') {
      return (
        <Typography variant={'h2'} className={classes.info}>
          We are currently delivering only to a select few locations. Enter your
          contact information and be the first to receive updates as we expand
          our reach.
        </Typography>
      );
    } else {
      return (
        <Alert className={classes.alert} severity="success">
          Thank you for your interest. We deliver to the zipcode you’ve entered.
          Orders are generally delivered in 5 days of order confirmation. Happy
          ordering.
        </Alert>
      );
    }
  };

  return (
    <div className={classes.zipcodeSection}>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant={'h2'} className={classes.heading}>
              Check your zipcode deliverability here
            </Typography>
          </Grid>
          <Grid container spacing={3} direction={'row'} alignItems={'center'}>
            <Grid item xs={12} lg={3} md={3} sm={3}>
              <TextField
                fullWidth
                label="Your Zipcode *"
                variant="outlined"
                className={classes.textField}
                type="number"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={zipcode}
                onChange={(event) => {
                  let value = event.target.value;
                  if (value.toString().length > 6) return;
                  setZipcode(event.target.value);
                }}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={3} sm={3}>
              <TextField
                fullWidth
                label="Email (optional)"
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setEmail(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={3} sm={3}>
              <MuiThemeProvider theme={buttonFont}>
                <Button
                  fullWidth
                  theme
                  className={classes.btnSubmit}
                  onClick={() => checkZipcode(zipcode)}
                  variant={'contained'}
                  color={'primary'}
                  disableElevation>
                  Submit
                </Button>
              </MuiThemeProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {showMessage()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ZipcodeVerify;
