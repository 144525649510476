/**
 * All route paths in this file
 */

const Paths = {
  CreateAccount: '/createAccount',
  Home: '/',
  Admin : "/admin",
  Adminhome : "/adminhome",
  SummerCamp: '/',
  Subscribe: '/baking-kits-subscriptions',
  Account: '/account',
  ProductListing: '/baking-kits',
  ProductDetails: '/baking-kits/products/:slug',
  ProductDetailsLanding: '/baking-kits/products/:slug/landing',
  ReadyToEat: '/snacks',
  ReadyToEatDetails: '/snacks/products/:slug',
  ReadyToEatDetailsLanding: '/snacks/products/:slug/landing',
  CompleteFAQ: '/faq',
  PrivacyPolicy: '/privacyPolicy',
  AboutUs: '/aboutUs',
  TermsOfUse: '/termsOfUse',
  ReturnPolicy: '/returnPolicy',
  OrderSuccess: '/orderSuccess/:orderId',
};

export { Paths };
