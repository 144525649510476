import reduxLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from '../rootReducer';
import rootSaga from '../rootSaga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage
};

export default function configureStore(history = {}, initialState = {}) {
  const composeEnhancers = composeWithDevTools({});

  const sagaMiddleware = createSagaMiddleware();

  const rootReducer = createRootReducer(history);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const composed =
    process.env.NODE_ENV === 'development'
      ? applyMiddleware(routerMiddleware(history), reduxLogger, sagaMiddleware)
      : applyMiddleware(routerMiddleware(history), sagaMiddleware);

  let store = createStore(persistedReducer, initialState, composeEnhancers(composed));

  let persist = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persist };
}
