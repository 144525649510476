import React from 'react';
import Layout from '../../components/Layout';
import SummerCamp from '../../components/SummerCamp';

const SummerCampHome = () => {
  return (
      <SummerCamp/>
  );
};

export default SummerCampHome;
