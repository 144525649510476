import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepOneIcon from '../../assets/images/step1.png';
import StepTwoIcon from '../../assets/images/step2.png';
import StepThreeIcon from '../../assets/images/step3.png';
import StepFourIcon from '../../assets/images/step4.png';

const useStyles = makeStyles((theme) => ({
  banner: {
    height: 322,
    width: 'auto',
  },
  main: {
    backgroundColor: '#FAF7F3',
    padding: '80px 0',
    borderRadius: '100px 100px 0 0'
  },
  heading: {
    color: colors.common.gray,
    fontSize: 40,
    lineHeight: '56px',
    fontFamily: 'HappySchool',
    marginBottom: 30,
  },
  desc: {
    color: colors.common.gray,
    fontSize: 28,
    lineHeight: '40px',
    fontWeight: 400,
  },
}));

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Container fixed>
        <Grid container spacing={3} style={{ marginBottom: 100 }}>
          <Grid item xs={12} sm={7}>
            <Typography variant={'h2'} className={classes.heading}>
              Choose a subscription
            </Typography>
            <Typography variant={'h2'} className={classes.desc}>
              Joining the gang means you receive weekly baking kits, digital
              recipes on the app &, a social media community of like minded
              young bakers & parents.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img
              src={StepOneIcon}
              alt="Subscription"
              className={classes.banner}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: 100 }}>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img src={StepTwoIcon} alt="kit" className={classes.banner} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant={'h2'} className={classes.heading}>
              Get a kit every week
            </Typography>
            <Typography variant={'h2'} className={classes.desc}>
              Enjoy baking with festive & holiday based themes. 4 boxes a month,
              shipped weekly. You will never receive the same kit twice.
              Guaranteed!
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: 100 }}>
          <Grid item xs={12} sm={7}>
            <Typography variant={'h2'} className={classes.heading}>
              Bake, learn & enjoy
            </Typography>
            <Typography variant={'h2'} className={classes.desc}>
              With every kit, add a new baking skill to your chef’s hat. Follow
              our easy step by step recipes to savor delicious, new treats.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img src={StepThreeIcon} alt="Bake" className={classes.banner} />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: 100 }}>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img src={StepFourIcon} alt="activity" className={classes.banner} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant={'h2'} className={classes.heading}>
              Engage in a fun activity
            </Typography>
            <Typography variant={'h2'} className={classes.desc}>
              Discover a engaging new surprise activity with each kit-
              educational game, colorful lessons, hands-on craft project and
              more.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HowItWorks;
