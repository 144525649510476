import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { CartReducer } from './reducer/cartReducer';
import { GlobalReducer } from './reducer/globalReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    cart: CartReducer,
    global: GlobalReducer,
  });

export default rootReducer;
