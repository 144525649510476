import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
  root: {
    maxWidth: 329,
    borderRadius: '32px 6px 32px 6px',
    textAlign: 'center',
    border: '1px dashed #DBDBDB',
    background: '#FAF7F3',
    padding: 15,
  },
  heading: {
    fontSize: 56,
    fontWeight: 800,
    lineHeight: '64px',
    color: '#535B65',
  },
  title: {
    fontSize: 20,
    lineHeight: '36px',
    color: '#000000',
    opacity: 0.4,
    padding: '52px 35px 40px',
  },
  link: {
    fontSize: 28,
    lineHeight: '36px',
    fontWeight: 500,
  },
  card: {
    display: 'flex',
    position: 'relative',

    '&:hover :nth-child(2)': {
      display: 'none',
    },

    '&:hover :last-child': {
      display: 'block',
    },
  },
  intro: {
    position: 'absolute',
    bottom: '50px',
    left: '32px',
    color: '#fff',
  },
  name: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
  },
  designation: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    display: 'block!important',
  },
  detail: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    paddingTop: 25,
  },
  brief: {
    position: 'absolute',
    color: '#535B65',
    padding: 40,
    width: 350,
    height: 350,
    display: 'none',
    backgroundColor: 'rgba(250, 171,83, 0.6)',
    background:
      'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 100%)',
  },
});

const HeroAboutUs = () => {
  const classes = useStyles();

  return (
    <div style={{ padding: '80px 0' }}>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h2'} className={classes.heading}>
              About Us
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={7}>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '28px',
                marginBottom: 20,
              }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam
            </Typography>
          </Grid> */}
        </Grid>

        {/* <Grid container spacing={4}>
          <Grid item xs={12} sm={4} className={classes.card}>
            <img src="https://www.fillmurray.com/350/350" alt="Team member" />
            <Grid item xs={12} sm={12} className={classes.intro}>
              <Typography variant={'h2'} className={classes.name}>
                Name
              </Typography>
              <Typography variant={'h2'} className={classes.designation}>
                Designation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.brief}>
              <Typography variant={'h2'} className={classes.name}>
                Name
              </Typography>
              <Typography variant={'h2'} className={classes.designation}>
                Designation
              </Typography>
              <Typography variant={'h2'} className={classes.detail}>
                John Turner is a serial entrepreneur, venture capitalist, and
                academic based in Silicon Valley. He co-founded two successful
                startups: bumble (acquired by Google) and Twix (acquired by
                Walmart).
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Container>
    </div>
  );
};

export default HeroAboutUs;
