import React from 'react';
import AdminLogin from '../../components/AdminPanel/AdminLogin';


const Adminlogin = () => {
  return (
      <div>
      <AdminLogin/>
      </div>
  );
};

export default Adminlogin;