import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '40px 0 120px',
    color: '#535B65',
  },
  sectionHeader: {
    opacity: 0.9,
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '36px',
    marginTop: 56,
  },
  sectionPara: {
    opacity: 0.8,
    fontSize: 20,
    lineHeight: '30px',
  },
  hyperlink: {
    color: '#FA4A39',
    fontWeight: 500,
  },
}));

const TermsDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={11}>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Welcome to Orangefigs (“Orangefigs” or “we” or “us”) website
              hosted at{' '}
              <a
                href="https://www.Orangefigs.com"
                className={classes.hyperlink}>
                https://www.Orangefigs.com
              </a>{' '}
              and{' '}
              <a
                href="https://www.Orangefigs.com"
                className={classes.hyperlink}>
                https://www.Orangefigs.com
              </a>{' '}
              (“Website” or “site”), the mobile application (hereinafter “app”
              or “application”), and any other affiliate websites or apps
              related to Orangefigs (together, collectively the “Service”).
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Please review the following terms and conditions concerning your
              use of and access to the Website. By accessing AND/OR using the
              Website, you agree to follow and be bound by these terms and
              conditions (these "Terms"). If you do not agree with these Terms,
              you may not use the service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              NOTICE OF ARBITRATION AGREEMENT AND CLASS ACTION WAIVER: THIS
              AGREEMENT INCLUDES A BINDING ARBITRATION CLAUSE AND A CLASS ACTION
              WAIVER, SET FORTH BELOW, WHICH AFFECT YOUR RIGHTS ABOUT RESOLVING
              ANY DISPUTE WITH THE COMPANY. PLEASE READ IT CAREFULLY.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              GENERAL PROVISIONS
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              By making a purchase from{' '}
              <a href="www.Orangefigs.com" className={classes.hyperlink}>
                www.Orangefigs.com
              </a>{' '}
              and{' '}
              <a href="www.Orangefigs.com" className={classes.hyperlink}>
                www.Orangefigs.com
              </a>
              , users (hereafter “You”, “Your”, “User” or “Users”) understand
              and agree that Orangefigs may share information about you and your
              transaction with other companies for the purpose of processing
              your transaction, shipping your product, and authorizing your
              credit card transaction.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              All user content is the sole responsibility of the user posting
              the information, whether the information is shared publicly or
              privately transmitted. Orangefigs reserves the right to review and
              delete any user content that Orangefigs, in its sole discretion,
              determines to violate the terms of use or is illegal, offensive,
              or violates and/or harms any third parties rights.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Content and Accuracy of Information:</strong> We attempt
              to ensure that information on this Service is complete, accurate
              and current. Despite our efforts, the information on this Service
              may be inaccurate, incomplete or out of date. We make no
              representation as to the completeness, accuracy or currentness of
              any information on this Service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Use of Service Content:</strong> All materials provided by
              the Service, including, but not limited to, information, images,
              graphics, logos, sounds, compilations, content and services
              ("Materials" or “Content”) are protected by and subject to
              copyright, trademark, patent, trade secret and other laws and are
              owned or controlled by Orangefigs, or their respective partners,
              vendors, licensors, agents and/or representatives (whether or not
              such persons are credited as the provider of the Content).
              Orangefigs reserves all rights in and to the Content not expressly
              granted to you hereunder. You shall have no rights to the
              Materials and you shall abide by all additional copyright notices,
              information, or restrictions contained in any Content accessed
              through the Service.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              None of the Content may be modified, copied, stored, printed,
              reproduced, distributed, republished, hosted, performed,
              downloaded, displayed, posted, reposted, transmitted and/or
              otherwise used in any form or by any means, including but not
              limited to electronic, mechanical, recording, or other means,
              without the prior express written permission of Orangefigs. Also,
              you may not "mirror" or “archive” any Content contained on the
              Service on any other server without Orangefigs’ prior express
              written permission.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You may not sublicense, assign or transfer any licenses granted by
              Orangefigs, and any attempt at such sublicense, assignment or
              transfer shall be null and void. You may not otherwise copy,
              distribute, modify, reverse engineer, or create derivative works
              of the Materials or Content.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Except where expressly provided otherwise by Orangefigs, nothing
              on the Service shall be construed to confer any license or
              ownership right in or to the Materials, under any of Orangefigs’
              intellectual property rights, whether by estoppel, implication, or
              otherwise.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Any unauthorized use of any Materials contained on the Service may
              violate copyright laws, trademark laws, the laws of privacy and
              publicity, and/or communications regulations and statutes. It is
              the Users’ obligation to comply with all applicable state, federal
              and international laws. Users are responsible for maintaining the
              confidentiality of their account information and password and for
              restricting access to such information and to their computer.
              Users agree to accept responsibility for all activities that occur
              under your account or password.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Termination:</strong> You or we may suspend or terminate
              your account or your use of this Service at any time, for any
              reason or for no reason. We reserve the right to change, suspend,
              or discontinue all or any aspect of this Service at any time
              without notice.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              SUBMISSIONS
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Except where expressly provided otherwise by Orangefigs, all
              comments, feedback, information and data submitted to Orangefigs
              through, in association with or in regard to the Service
              ("Submissions") shall be considered non-confidential and
              Orangefigs’s property. This may not include copyright ownership of
              images which you may upload, but does include an express license
              to use said images in any method Orangefigs sees fit and make
              compilations and derivative works thereof in all media now known
              or hereafter devised. Except as expressly enumerated in the
              preceding sentence, by providing such Submissions to Orangefigs,
              you agree to assign to Orangefigs, as consideration in exchange
              for the use of the Service, all worldwide rights, title and
              interest in copyrights and other intellectual property rights to
              the Submissions. You represent that you have the right to grant
              Orangefigs these rights. Orangefigs shall be free to use and/or
              disseminate such Submissions on an unrestricted basis for any
              purpose. You acknowledge that you are responsible for the
              Submissions that you provide, and that you, not Orangefigs, have
              full responsibility for the Submissions, including their legality,
              reliability, appropriateness, originality and copyright.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You will not post any Submission that (a) is defamatory, abusive,
              harassing, threatening, or an invasion of a right of privacy of
              another person; (b) bigoted, hateful, or racially or otherwise
              offensive; (c) is violent, vulgar, obscene, pornographic or
              otherwise sexually explicit; or (d) otherwise harms or can
              reasonably be expected to harm any person or entity
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs reserves the right, but disclaims any obligation or
              responsibility, to (a) refuse to post or communicate or remove any
              Submission from any Orangefigs site that violates these Terms of
              Use and (b) identify any user to third parties, and/or disclose to
              third parties any Submission or personally identifiable
              information, when we believe in good faith that such
              identification or disclosure will either (i) facilitate compliance
              with laws, including, for example, compliance with a court order
              or subpoena, or (ii) help to enforce these Terms of Use and/or
              protect the safety or security of any person or property,
              including any Orangefigs site. Moreover, we retain all rights to
              remove Submissions at any time for any reason or no reason
              whatsoever.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              All Submissions must be true, and in accordance with the rights of
              privacy and publicity and all federal, state and international
              law. You may not upload an image or any likeness of another
              without their consent (or the consent of their parent or guardian
              if they are under the age of 18). If you do so, Orangefigs
              reserves the right to cancel or suspend your account. Furthermore,
              Orangefigs reserves the right to cancel or suspend your account,
              if in its sole discretion, it believes you are using Orangefigs
              for improper purposes, or any purpose inconsistent with its
              business.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              INFORMATION FOR USERS
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Account Creation:</strong> You are eligible to use the
              Service if you are the age of 18 or older. You are ineligible to
              use the Service if you have been suspended by Orangefigs or you
              are under the age of 18. By becoming a user of the Service, you
              represent and warrant that you have the right, authority and
              capacity to use the Service and to abide by the Terms of Use. Your
              registration with the Service is for your sole use. You may not
              authorize others to use your account and you may not assign or
              otherwise transfer your account to any other person or entity.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              In order to set up an account on the Service, Users will have to
              provide a User Name, password, active email address, and physical
              mailing address, all of which is subject to Orangefigs{' '}
              <a
                href="www.Orangefigs.com/privacy-policy"
                className={classes.hyperlink}>
                Privacy Policy
              </a>
              . Any submission of false or materially inaccurate information by
              users in creating an account shall constitute a basis for
              Orangefigs to terminate the account and bar such users from making
              further access or use of the Service. You may only create one
              account per person, and any user creating multiple accounts with
              different email addresses may be subject to account termination.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Sharing Information:</strong> Users may share information
              with other users or invited colleagues through the Service
              provided that the users and invited colleagues have an individual
              account with Orangefigs. Unless the user specifies he or she does
              not wish to participate, Orangefigs may track users’ use of the
              Service and suggest actions or activities to users that match the
              individual’s use of the Service and/or browsing and selection
              history. Upon creation of an account, the default will be to allow
              Orangefigs to track your use of the Service. Also, Orangefigs may
              send text messages to User’s mobile numbers to verify a User
              account. To change your account settings, please visit{' '}
              <a href="www.Orangefigs.com" className={classes.hyperlink}>
                www.Orangefigs.com
              </a>
              .
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              LINKS TO THIRD PARTY SITES
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              The website or application may contain links or have references to
              websites controlled by parties other than Orangefigs. Orangefigs
              is not responsible for and does not endorse or accept any
              responsibility for the contents or use of these third party
              websites. Orangefigs is providing these links to you only as a
              convenience, and the inclusion of any link does not imply
              endorsement by Orangefigs of the linked website and/or the content
              and materials found at the linked website, except as specifically
              stated otherwise by Orangefigs. It is your responsibility to take
              precautions to ensure that whatever you select for your use is
              free of viruses or other items of an intrusive nature.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              LIMITATION OF LIABILITY
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              IN NO EVENT SHALL Orangefigs OR ANY THIRD PARTY PROVIDERS BE
              LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY,
              INCIDENTAL, OR CONSEQUENTIAL DAMAGES, OR FOR ANY DAMAGES to your
              computer, telecommunication equipment, or other property and/or
              FOR LOSS OF DATA, CONTENT, IMAGES, REVENUE, PROFITS, USE OR OTHER
              ECONOMIC ADVANTAGE, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH
              THESE TERMS, INCLUDING BUT NOT LIMITED TO THE accessing or USE of,
              OR INABILITY TO USE, THE WEBSITE AND THE SERVICES ASSOCIATED
              THEREWITH including but not limited to the downloading of any
              materials, REGARDLESS OF CAUSE, WHETHER IN AN ACTION IN CONTRACT
              OR NEGLIGENCE OR OTHER TORTIOUS ACTION, EVEN IF THE PARTY FROM
              WHICH DAMAGES ARE BEING SOUGHT OR THE THIRD PARTY PROVIDER HAVE
              BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
              LIMITATION OF LIABILITY SET FORTH IN THIS SECTION SHALL NOT APPLY
              IN THE EVENT OF CUSTOMER'S BREACH, OR RELATED TO ITS INDEMNITY
              OBLIGATIONS. THIS PARAGRAPH SHALL NOT AFFECT THE RIGHTS LISTED
              BELOW IN THE SECTION TITLED “INDEMNITIES”.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              LICENSES FROM Orangefigs
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Users are being granted solely a limited, non-exclusive,
              non-transferrable, license to access the Service and view the
              Content through the Service. Except for the limited license, no
              right, title, interest shall be transferred to you.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              REPRESENTATIONS AND WARRANTIES
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Each User represents and warrants that it has the power and
              authority to enter into these Terms. Orangefigs warrants that it
              will provide the Service in a manner consistent with its business
              practices, as Orangefigs, in its sole and absolute discretion,
              deems fit.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              DISCLAIMER OF WARRANTIES
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              EXCEPT WHERE EXPRESSLY PROVIDED OTHERWISE BY Orangefigs, THE
              MATERIALS ON THE WEBSITE ARE PROVIDED "AS IS," AND ARE FOR USE AS
              CONTRACTED HEREIN. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH
              HEREIN, Orangefigs AND ITS THIRD PARTY PROVIDERS HEREBY DISCLAIM
              ALL EXPRESS OR IMPLIED REPRESENTATIONS, WARRANTIES, GUARANTIES,
              AND CONDITIONS WITH REGARD TO THE WEBSITE, THE MATERIALS, AND THE
              GOODS AND SERVICES ASSOCIATED THEREWITH INCLUDING BUT NOT LIMITED
              TO ANY IMPLIED REPRESENTATIONS, WARRANTIES, GUARANTIES, AND
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NON-INFRINGEMENT, AND QUALITY OF GOODS AND SERVICES
              EXCEPT TO THE EXTENT THAT SUCH DISCLAIMERS ARE HELD TO BE LEGALLY
              INVALID. Orangefigs AND ITS THIRD PARTY PROVIDERS MAKE NO
              REPRESENTATIONS, GUARANTIES OR WARRANTIES REGARDING THE
              RELIABILITY, AVAILABILITY, TIMELINESS, QUALITY, SUITABILITY,
              TRUTH, ACCURACY OR COMPLETENESS OF THE website and the GOODS,
              SERVICES AND/OR MATERIALS ASSOCIATED WITH THE WEBSITE, OR THE
              RESULTS YOU MAY OBTAIN BY accessing or USING THE WEBSITE AND/OR
              THE GOODS, SERVICES AND/OR MATERIALS ASSOCIATED THEREWITH. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, Orangefigs AND ITS THIRD
              PARTY PROVIDERS DO NOT REPRESENT OR WARRANT THAT (A) THE OPERATION
              OR USE OF THE WEBSITE OR MATERIALS WILL BE TIMELY, SECURE,
              UNINTERRUPTED OR ERROR-FREE; (B) THE QUALITY OF ANY PRODUCTS,
              SERVICES, INFORMATION, OR OTHER MATERIAL YOU PURCHASE OR OBTAIN
              THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS; OR (C) THE GOODS,
              SERVICES, MATERIALS, OR THE SYSTEMS THAT MAKE THE SERVICES AND
              WEBSITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
              YOU ACKNOWLEDGE THAT NEITHER Orangefigs NOR ITS THIRD PARTY
              PROVIDERS CONTROL THE TRANSFER OF DATA OVER COMMUNICATIONS
              FACILITIES, INCLUDING THE INTERNET, AND THAT THE WEBSITE AND
              SERVICES AND/OR MATERIALS ASSOCIATED THEREWITH MAY BE SUBJECT TO
              LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF
              SUCH COMMUNICATIONS FACILITIES. Orangefigs IS NOT RESPONSIBLE FOR
              ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH
              PROBLEMS. EXCEPT WHERE EXPRESSLY PROVIDED OTHERWISE BY Orangefigs,
              THE WEBSITE AND THE GOODS, SERVICES AND/OR MATERIALS ASSOCIATED
              THEREWITH ARE PROVIDED TO YOU ON AN "AS IS" BASIS. Orangefigs
              EXPRESSLY DISCLAIMS ANY WARRANTY RELATED TO THE QUALITY OF GOODS
              AND/OR SERVICES AND THE PERSONS OR BUSINESSES REFERENCED ON THE
              WEBSITE. YOU USE THIRD PARTY PROVIDERS AND AFFILIATED SERVICES AT
              YOUR PERIL AND ASSUME ALL RISKS RELATED TO USE OF SAID THIRD PARTY
              PROVIDERS AND SERVICES.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              YOUR RESPONSIBILITIES
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Users will comply with all applicable local, state, national and
              foreign laws, treaties, regulations and conventions in connection
              with your use of the Service, including without limitation those
              related to data privacy, international communications, and the
              exportation of technical or personal data from locations other
              than the location from which Orangefigs controls and operates the
              Service associated therewith.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              NOTICES
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs may give notice by means of a general notice on the
              website, app, or by electronic mail to your e-mail address on
              record in Orangefigs’s account information. All notices shall be
              deemed to have been given four days after mailing or 36 hours
              after sending by email or posting to the Website.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Furthermore, Orangefigs complies with the Digital Millennium
              Copyright Act (“DMCA”). Any notices given pursuant to the DMCA
              shall be given to the Orangefigs designated agent via email at
              hello@Orangefigs.com or via registered mail sent return receipt
              to:{' '}
              <strong>
                DMCA Compliance Agent, Orangefigs, Hyderabad, Telangana State,
                India.
              </strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You may give notice to Orangefigs at any time by letter sent by
              registered mail with return receipt to:{' '}
              <strong>Orangefigs, Hyderabad, Telangana State, India.</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              RESOLUTION OF DISPUTES – MANDATORY ARBITRATION AND CLASS ACTION
              WAIVER
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              We offer the following process to help you resolve a complaint or
              Dispute (as defined below) that you may have with Orangefigs or
              its Third Party Providers in connection with the use of this
              Service. Please read this section carefully. Our Customer Service
              Department, which you can reach at hello@Orangefigs.com can help
              you resolve most of your concerns. If our Customer Service
              Department is unable to resolve your complaint or Dispute, you
              agree to take the following steps to resolve any Dispute you may
              have with us.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Step 1. Notice of Dispute </strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You must first try to resolve any complaint or Dispute with us
              through our Notice of Dispute process. You begin by submitting a
              “Notice of Dispute” with any supporting documents or other
              information by Mail to:
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Orangefigs
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Attention: Notice of Dispute
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Hyderabad, Telangana State, India - 500075
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              A “Notice of Dispute” is a written form in which you provide your
              name, address, contact information, email address, the facts
              regarding your Dispute, and the relief you are requesting from us.
              Once we receive your Notice of Dispute, you and we will attempt to
              resolve any Dispute through informal negotiation within sixty (60)
              days from the date the Notice of Dispute is received by us. If an
              agreement cannot be reached within sixty (60) days of receipt of
              the Notice of Dispute, you or we may initiate an arbitration
              proceeding or small claims action as described below.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You agree that the term “Dispute” in these Terms of Use will have
              the broadest meaning possible. It applies to any dispute, action,
              or other controversy between you and us relating to the Website,
              the Services, the Terms of Use (or any breach thereof) – whether
              in contract, warranty, tort, laws or regulation. The term also
              applies to any dispute over the validity, enforceability, or scope
              of this arbitration provision.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Step 2: Arbitration</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              If your Dispute is not resolved to your satisfaction within 60
              days from when we received your Notice of Dispute, you and we
              agree to arbitrate all Disputes between us. As explained below,
              you and we also may also have the right to bring an individual
              action before a Small Claims Court for certain claims. You agree
              that arbitration or a small claims action will be your sole and
              exclusive remedy to resolve any dispute with us. The Arbitration
              Act applies to this Agreement.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>
                By agreeing to arbitrate, you are giving up the right to
                litigate (or participate in as a party or class member) any and
                all Disputes in a court before a judge or jury (except Small
                Claims Court). Instead, a neutral arbitrator will resolve all
                Disputes.
              </strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Class Action Waiver</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Any proceedings to resolve or litigate any Dispute in any forum
              will be conducted solely on an individual basis. Class
              arbitrations, class actions, private attorney general actions,
              consolidation of your Dispute with other arbitrations, or any
              other proceeding in which either party acts or proposes to act in
              a representative capacity or as a private attorney general are not
              permitted and are waived by you, and an arbitrator will have no
              jurisdiction to hear such claims. If a court or arbitrator finds
              that the class action waiver in this section is unenforceable as
              to all or some parts of a Dispute, then the class action waiver
              will not apply to those parts. Instead, those parts will be
              severed and proceed in a court of law, with the remaining parts
              proceeding in arbitration. If any other provision of this Dispute
              resolution section is found to be illegal or unenforceable, that
              provision will be severed with the remainder of this section
              remaining in full force and effect.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Costs</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              If you initiate an arbitration proceeding with the AAA, we will
              promptly reimburse you for any standard filing fee you may have
              been required to pay by the AAA, once you have notified us in
              writing and provided a copy of the arbitration demand. However, if
              we are the prevailing party in the arbitration, we will be
              entitled to seek and recover our attorneys’ fees and costs,
              subject to the arbitrator’s determination and applicable law.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Small Claims Option </strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You may also litigate any Dispute in Small Claims Court in
              Hyderabad, Telangana. if the Dispute meets all requirements to be
              heard in the small claims court. However, if you initiate a Small
              Claims case, you are responsible for all your court costs.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>Choice of Law and Forum Selection</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You agree that the laws of the State of Telangana government this
              agreement and any claim or Dispute or issues arising from it,
              without regard to India conflict of laws rules. Unless you and we
              agree otherwise, in the event that the Agreement to arbitrate
              above is found not to apply to you or to a particular claim or
              Dispute as a result of a decision by the arbitrator or a court
              order, you agree that any Disputes or claims that you may have
              against us reside in and will be resolved by a state or federal
              court located in the County of India in the State of Telangana and
              you agree and submit to the exercise of personal jurisdiction of
              such courts for the purpose of litigating any such claim or
              action.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionHeader}>
              Governing Law & Exclusive Venue
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Use of the Service and any claim relating to Orangefigs shall be
              governed by the laws of the State of Telangana and litigated in
              the State of Telangana. Any disputes, actions, claims or causes of
              action arising out of or in connection with these Terms of Use or
              the Service shall be subject to the exclusive jurisdiction of the
              state and court located in Hyderabad. The choice of law of an
              exclusive venue in Hyderabad, Telangana, apply to you regardless
              of where you are accessing the Website from even if you are
              accessing the Website from outside of India.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              The Terms of Use (together with the Privacy Policy) represent the
              parties' entire understanding relating to the use of the Website
              and supersedes any prior or contemporaneous, conflicting or
              additional, communications. Orangefigs reserves the right to
              change these Terms of Use or policies relating to the Service at
              any time and from time to time, and such changes will be effective
              upon being posted herein. You should visit this page from time to
              time to review the then current Terms of Use because they are
              binding on you. Certain provisions of these Terms of Use may be
              superseded by expressly designated legal notices or terms located
              on particular pages of the Website, which may be posted from time
              to time. Your continued use of the Service after any such changes
              and/or postings shall constitute your consent to such changes. If
              any provision of these Terms of Use is held by a court of
              competent jurisdiction to be invalid or unenforceable, then such
              provision(s) shall be construed to reflect the intentions of the
              invalid or unenforceable provision(s), with all other provisions
              remaining in full force and effect.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              You may not assign these Terms of Use without the prior written
              approval of Orangefigs. Any purported assignment in violation of
              this section shall be void. Orangefigs reserves the right to use
              third party providers in the provision of the Service and/or
              Materials associated therewith. In the event of any litigation of
              any controversy or dispute arising out of or in connection with
              these Terms of Use, its interpretation, its performance, or the
              like, the prevailing party shall be awarded reasonable attorneys'
              fees and expenses, court costs, and reasonable costs for expert
              and other witnesses attributable to the prosecution or defense of
              that controversy or dispute. Any and all rights not expressly
              granted herein are reserved by Orangefigs.
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              <strong>India ONLY</strong>
            </Typography>
            <Typography
              variant={'body1'}
              paragraph={true}
              className={classes.sectionPara}>
              Unless otherwise specified, the Service is solely for use within
              India. We make no representation that the Service is appropriate
              or available for use in other locations. If you access the Service
              from locations other than India, you do so at your own risk.
              Whether inside or outside India, you are solely responsible for
              compliance with any applicable local laws. If you have any
              questions regarding these terms or wish to contact us for any
              matter: Email:{' '}
              <a
                href="mailto:Hello@Orangefigs.com"
                className={classes.hyperlink}>
                Hello@Orangefigs.com
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TermsDetails;
