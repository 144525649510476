import React from "react"
import MaterialUIPickers from './muicalendar';
import CancelIcon from '@material-ui/icons/Cancel';


const CalendarFilter=(props)=>{

return(
    <div className="floatingfilter">
    <ul>
      <li className="weekoptions">
        <div class="vertical-menu">
          <a href="#"
          onClick={() => {
            props.filterdates('today');
          }}
          >Today</a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('week');
            }}>
            Last Week
          </a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('30days');
            }}>
            Last 30 Days
          </a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('thismonth');
            }}>
            This month
          </a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('month');
            }}>
            Last month
          </a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('quarter');
            }}>
            Last quarter
          </a>
          <a
            href="#"
            onClick={() => {
              props.filterdates('year');
            }}>
            Last Year
          </a>
        </div>
      </li>
      <li>
        <ul>
          <li>
            <MaterialUIPickers
              className="datepicker"
              setdate={props.changeDate}
            />
          </li>
        </ul>
      </li>
      <li
        className="cancelicon"
        onClick={
          props.close
        }>
        <CancelIcon />
      </li>
    </ul>
  </div>


)


}

export default CalendarFilter;

